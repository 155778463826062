import React, { useEffect, useState } from 'react'
import {
	faHome,
	faThermometerThreeQuarters,
	faUmbrellaBeach,
	faUserTimes
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector } from 'react-redux'
import selectors from 'redux/selectors'
import Sickness, { SicknessTypeEnum } from 'types/sickness'
import Vacation, { VacationTypeEnum } from 'types/vacation'
import moment from 'moment'

const UserAvailabilityStatus = (props: { employee_id?: string; today?: boolean }) => {
	const [availabilityType, setAvailabilityType] = useState<string | null>(null)

	const sicknesses: Sickness[] = useSelector(
		props.today ? selectors.getTodaySicknesses : selectors.getSicknesses
	)
	const vacations: Vacation[] = useSelector(
		props.today ? selectors.getTodayVacations : selectors.getVacations
	)

	useEffect(() => {
		if (!props.employee_id) return

		if (sicknesses) {
			const sickness = sicknesses.find(
				(sickness: Sickness) =>
					sickness.employee._id === props.employee_id &&
					moment(new Date()).isBetween(
						sickness.start_date,
						sickness.end_date ?? new Date(),
						'days',
						'[]'
					)
			)

			if (sickness) {
				setAvailabilityType(sickness.type)
			}
		}

		if (vacations) {
			const vacation = vacations.find(
				(vacation: Vacation) =>
					vacation.employee._id === props.employee_id &&
					moment(new Date()).isBetween(
						vacation.start_date,
						vacation.end_date,
						'days',
						'[]'
					)
			)

			if (vacation) {
				setAvailabilityType(VacationTypeEnum.inVacation)
			}
		}
	}, [props.employee_id, sicknesses, vacations])

	switch (availabilityType) {
		case SicknessTypeEnum.worksRemotely:
			return <FontAwesomeIcon className="text-primary mr-1" icon={faHome} />
		case SicknessTypeEnum.doesntWork:
			return <FontAwesomeIcon className="text-danger mr-1" icon={faUserTimes} />

		case SicknessTypeEnum.sick:
			return (
				<FontAwesomeIcon className="text-danger mr-1" icon={faThermometerThreeQuarters} />
			)
		case VacationTypeEnum.inVacation:
			return <FontAwesomeIcon className="text-primary mr-1" icon={faUmbrellaBeach} />

		default:
			return <span></span>
	}
}

export default UserAvailabilityStatus
