import React from 'react'
import SurveyPage from './SurveyPage'

const Survey = () => (
	<div
		style={{
			position: 'absolute',
			width: '100%',
			height: '100%',
			zIndex: '1030',
			backdropFilter: 'blur(6px)'
		}}
	>
		<SurveyPage />
	</div>
)

export default Survey
