import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'
import { Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import selectors from 'redux/selectors'
import { Modal, ModalBody } from 'reactstrap'
import { toastr } from 'react-redux-toastr'
import operations from 'redux/operations'

import { FaStar } from 'react-icons/fa'
import ReactStars from 'react-rating-stars-component'
import { SendAnswersData, SurveyAnswer, SurveyQuestion, SurveyState } from 'types/survey'
import AuthorBadge from 'components/BagesAndLabels/AuthorBadge'

const SurveyPage = () => {
	const [answers, setAnswers] = useState<SurveyAnswer[]>([])
	const [error, setError] = useState<number>(-1)

	const { t } = useTranslation()
	const dispatch = useDispatch()
	const user = useSelector(selectors.getUser)
	const survey: SurveyState = useSelector(selectors.getSurvey)

	useEffect(() => {}, [survey])

	const onAnswersCange = (question: string, value: number) => {
		const answer = answers.find((a) => a.question === question)
		if (answer) answer.value = value
		else setAnswers([...answers, { employee: user.employee._id, question, value }])
		if (error !== -1) setError(-1)
	}

	const send = async () => {
		let error = false

		if (survey.questions.length !== answers.length) {
			survey.questions.forEach((question: SurveyQuestion, idx) => {
				const hasAnswer = answers.find(
					(answer: SurveyAnswer) => answer.question === question._id
				)

				if (!hasAnswer) {
					setError(idx)
				}
			})

			error = true
		}

		if (error) {
			return toastr.warning('', t('SurveyPage_sendAnswersError'))
		}

		const answersData: SendAnswersData = {
			survey_id: survey.id,
			type: survey.type,
			position: survey.position,
			answers: answers
		}

		return dispatch(operations.userOperations.surveys.sendAnswerFunc(answersData))
	}

	return (
		<Modal isOpen={true} backdrop={'static'} fullscreen={'lg'} centered>
			<ModalBody>
				<>
					<div className="mb-2">
						<h1 className="m-0 mr-2 mb-1">{t(`SurveyPage_${survey.type}Header`)}</h1>
						{survey.project && <h3 className="text-primary">{survey.project.name}</h3>}
						{survey.party && <h3 className="text-primary">{survey.party.title}</h3>}
					</div>

					{t('SurveyPage_introduction')}
					<br />
					{survey.questions.map((question, i) => (
						<Question
							key={question._id}
							i={i + 1}
							question={question}
							error={error === i}
							onChange={(v) => onAnswersCange(question._id, v)}
						/>
					))}
					<Button color="success" size="lg" onClick={send}>
						{t('SurveyPage_buttonSend')}
					</Button>
				</>
			</ModalBody>
		</Modal>
	)
}

const Question = (props: {
	question: SurveyQuestion
	i: number
	error: boolean
	onChange: (value: number) => void
}) => {
	const { t } = useTranslation()
	return (
		<div className="my-4 py-4">
			<h4 className={`${props.error ? 'text-danger' : ''}`}>
				{props.i}. {t(props.question.question)}{' '}
			</h4>

			<h6>
				<AuthorBadge
					employee={props.question.evaluated_employee}
					size="full"
					linkOff
					showAvailability
				/>
			</h6>

			<Row>
				<Col>
					<ReactStars
						size={40}
						count={5}
						color="#c0c0c0"
						activeColor="#fe3f01"
						a11y
						emptyIcon={<FaStar className="mx-1" />}
						filledIcon={<FaStar className="mx-1" />}
						onChange={props.onChange}
					/>
				</Col>
			</Row>
			<Row className="d-flex justify-content-space-between">
				<Col className={'col-md-4'}>
					{props.question.min_description && <h5>{t(props.question.min_description)}</h5>}
				</Col>
				<Col className={'col-md-6'}>
					{props.question.max_description && <h5>{t(props.question.max_description)}</h5>}
				</Col>
			</Row>
		</div>
	)
}

export default SurveyPage
