import {
	getAllPartySurveys,
	getAllProjectSurveys,
	getAllWeeklyAndMonthlySurveys,
	getEmployeeProjectSurveys,
	getProjectSurvey
} from 'utils/api.request'
import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { DoneSurveysPayload, PartySurvey, ProjectSurvey, Survey } from 'types/survey'
import { MonthsOptions } from 'components/ProjectsByMonthsSelector'

//Project survey
export const loadProjectSurveyFunc = (project_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.surveys.loadProjectSurveyRequest())
	try {
		const projectSurvey: ProjectSurvey = await getProjectSurvey(project_id)
		return dispatch(actions.dataActions.surveys.loadProjectSurveySuccess(projectSurvey))
	} catch (error: any) {
		console.log(error)
		toastr.error('Error', 'Помилка завантаження оцінок по проєкту')
		return dispatch(actions.dataActions.surveys.loadProjectSurveyError())
	}
}

export const loadProjectSurveysFunc =
	(months: number, monthsOption: MonthsOptions) => async (dispatch) => {
		dispatch(actions.dataActions.surveys.loadProjectSurveysRequest())
		try {
			const projectSurveys: ProjectSurvey[] = await getAllProjectSurveys(months)

			const payload: DoneSurveysPayload = {
				surveys: projectSurveys,
				months: monthsOption
			}

			return dispatch(actions.dataActions.surveys.loadProjectSurveysSuccess(payload))
		} catch (error: any) {
			console.log(error)
			toastr.error('Error', 'Помилка завантаження оцінок по проєктах')
			return dispatch(actions.dataActions.surveys.loadProjectSurveysError())
		}
	}

export const loadEmployeeProjectSurveysFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.surveys.loadEmployeeProjectSurveysRequest())
	try {
		const projectSurveys: ProjectSurvey[] = await getEmployeeProjectSurveys(id)
		return dispatch(
			actions.dataActions.surveys.loadEmployeeProjectSurveysSuccess(projectSurveys)
		)
	} catch (error: any) {
		console.log(error)
		toastr.error('Error', 'Помилка завантаження оцінок по проєктах працівника')
		return dispatch(actions.dataActions.surveys.loadEmployeeProjectSurveysError())
	}
}

export const loadWeeklyMonthlySurveysFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.surveys.loadWeeklyMonthlySurveysRequest())
	try {
		const weeklyMonthlySurveys: Survey[] = await getAllWeeklyAndMonthlySurveys()
		return dispatch(
			actions.dataActions.surveys.loadWeeklyMonthlySurveysSuccess(weeklyMonthlySurveys)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.surveys.loadWeeklyMonthlySurveysError())
	}
}

export const loadPartySurveysFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.surveys.loadPartySurveysRequest())
	try {
		const partySurveys: PartySurvey[] = await getAllPartySurveys()
		return dispatch(actions.dataActions.surveys.loadPartySurveysSuccess(partySurveys))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.surveys.loadPartySurveysError())
	}
}
