import {
	addInvoice,
	deleteInvoice,
	payInvoice,
	getInvoices,
	deleteInvoiceFile,
	downloadInvoiceFile,
	uploadInvoiceFile,
	sendInvoice,
	formatInvoice,
	addProjectsToInvoice
} from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import Invoice, {
	AddProjectsData,
	InvoiceCreationData,
	PayInvoiceData,
	SendInvoiceData
} from 'types/invoice'

export const loadInvoicesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.invoices.loadInvoicesRequest())
	try {
		const loadedInvoices: Invoice[] = await getInvoices()
		return dispatch(actions.dataActions.invoices.loadInvoicesSuccess(loadedInvoices))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.loadInvoicesError())
	}
}

export const addProjectsToInvoiceFunc = (updatedData: AddProjectsData) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.addProjectsToInvoiceRequest())
	try {
		const updatedInvoice: Invoice = await addProjectsToInvoice(updatedData)

		toastr.success('', t('reduxToastrSuccess_invoiceProjectsUpdated'))
		return dispatch(actions.dataActions.invoices.addProjectsToInvoiceSuccess(updatedInvoice))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.addProjectsToInvoiceError())
	}
}

export const createInvoiceFunc = (creationData: InvoiceCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.createInvoiceRequest())
	try {
		const createdInvoice: Invoice = await addInvoice(creationData)

		toastr.success('', t('reduxToastrSuccess_invoiceCreated'))
		return dispatch(actions.dataActions.invoices.createInvoiceSuccess(createdInvoice))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.createInvoiceError())
	}
}

export const formatInvoiceFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.formatInvoiceRequest())
	try {
		const formattedInvoice: Invoice = await formatInvoice(id)
		toastr.success('', t('reduxToastrSuccess_invoiceFormat'))
		return dispatch(actions.dataActions.invoices.formatInvoiceSuccess(formattedInvoice))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.formatInvoiceError())
	}
}

export const sendInvoiceFunc = (sendInvoiceData: SendInvoiceData) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.sendInvoiceRequest())
	try {
		const sendedInvoice: Invoice = await sendInvoice(sendInvoiceData)
		toastr.success('', t('reduxToastrSuccess_invoiceSended'))
		return dispatch(actions.dataActions.invoices.sendInvoiceSuccess(sendedInvoice))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.sendInvoiceError())
	}
}

export const payInvoiceFunc = (payData: PayInvoiceData) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.payInvoiceRequest())
	try {
		const paidInvoice: Invoice = await payInvoice(payData)
		toastr.success('', t('reduxToastrSuccess_invoicePaid'))
		return dispatch(actions.dataActions.invoices.payInvoiceSuccess(paidInvoice))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.payInvoiceError())
	}
}

export const deleteInvoiceFunc = (invoice_id, file_id) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.deleteInvoiceRequest())
	try {
		const deletedInvoice: Invoice = await deleteInvoice(invoice_id, file_id)
		toastr.success('', t('reduxToastrSuccess_invoiceDeleted'))
		return dispatch(actions.dataActions.invoices.deleteInvoiceSuccess(deletedInvoice._id))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.deleteInvoiceError())
	}
}

export const uploadInvoiceFileFunc = (invoice_id: string, file: File) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.uploadInvoiceFileRequest())
	try {
		const updatedInvoice: Invoice = await uploadInvoiceFile(invoice_id, file)
		toastr.success('', t('reduxToastrSuccess_invoiceFileUploaded'))
		return dispatch(actions.dataActions.invoices.uploadInvoiceFileSuccess(updatedInvoice))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.uploadInvoiceFileError())
	}
}

export const downloadInvoiceFileFunc = (file_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.downloadInvoiceFileRequest())
	try {
		await downloadInvoiceFile(file_id)
		toastr.success('', t('reduxToastrSuccess_invoiceFileDownloaded'))
		return dispatch(actions.dataActions.invoices.downloadInvoiceFileSuccess())
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.downloadInvoiceFileError())
	}
}

export const deleteInvoiceFileFunc = (invoice_id: string, file_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.invoices.deleteInvoiceFileRequest())
	try {
		const updatedInvoice: Invoice = await deleteInvoiceFile(invoice_id, file_id)
		toastr.success('', t('reduxToastrSuccess_invoiceFileDeleted'))
		return dispatch(actions.dataActions.invoices.deleteInvoiceFileSuccess(updatedInvoice))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.invoices.deleteInvoiceFileError())
	}
}
