import React from 'react'
import { connect, useSelector } from 'react-redux'
import selectors from 'redux/selectors'
import { StoreType } from 'redux/store'

import Survey from 'pages/other/Survey'
import TimeTracking from 'pages/dashboards/HR/TimeTracking/TimeTracking'
//import EventConfetti from './EventConfetti'

const Wrapper = ({ layout, children }: any) => {
	const user = useSelector(selectors.getUser)
	const isAuthenticated = useSelector(selectors.getIsAuthenticated)
	const todayEntered = useSelector(selectors.getTodayEntered)
	const workWithSystem = useSelector(selectors.getWorkWithSystem)
	const eventsDisabled = useSelector(selectors.getEventsDisabled)

	return (
		<div
			className={'wrapper ' + (layout.isBoxed ? 'wrapper-boxed' : '')}
			style={{ position: 'relative', pointerEvents: eventsDisabled ? 'none' : 'auto' }}
		>
			<>
				{((isAuthenticated && !todayEntered && !workWithSystem) ||
					(isAuthenticated && !workWithSystem)) && <TimeTracking />}

				{isAuthenticated && user.survey.needed && workWithSystem && <Survey />}

				{/* <EventConfetti /> */}

				{children}
			</>
		</div>
	)
}

export default connect((store: StoreType) => ({
	layout: store.layout
}))(Wrapper)
