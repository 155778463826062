import * as types from '../constants'
import InterfaceSettings from 'types/interfaceSettings'

const initialState: InterfaceSettings = {
	projects_tab: {
		active_ideas_bar: {
			is_open: true
		},
		archived_ideas_bar: {
			is_open: false
		},
		concepts_bar: {
			is_open: true
		},
		archive_concepts_bar: {
			is_open: false
		},
		disaproved_concepts_bar: {
			is_open: true
		},
		pinned_concepts_bar: {
			is_open: true
		},
		mvp_table_bar: {
			is_open: true
		},
		released_table_bar: {
			is_open: true
		},
		inTest_table_bar: {
			is_open: true
		},
		tested_table_bar: {
			is_open: true
		}
	},
	employee_finance_tab: {
		employee_finance_bar: {
			is_open: true
		},
		art_and_dev_bar: {
			is_open: true
		},
		projects_in_month_bar: {
			is_open: true
		},
		general_expenses_bar: {
			is_open: true
		}
	},
	sidebar: { isOpen: true, isSticky: true },
	confetti: { run: false, type: 'default', text: null }
}

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.UPLOAD_INTERFACE_SETTINGS:
			return { ...state, ...actions.payload }
		case types.SIDEBAR_VISIBILITY_TOGGLE:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					isOpen: !state.sidebar.isOpen
				}
			}
		case types.SIDEBAR_VISIBILITY_SHOW:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					isOpen: true
				}
			}
		case types.SIDEBAR_VISIBILITY_HIDE:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					isOpen: false
				}
			}

		case types.SIDEBAR_STICKY_TOGGLE:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					isSticky: !state.sidebar.isSticky
				}
			}
		case types.SIDEBAR_STICKY_ENABLE:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					isSticky: true
				}
			}
		case types.LAYOUT_BOXED_ENABLE:
		case types.LAYOUT_BOXED_TOGGLE:
		case types.SIDEBAR_STICKY_DISABLE:
			return {
				...state,
				sidebar: {
					...state.sidebar,
					isSticky: false
				}
			}
		case types.UPDATE_INTERFACE_SETTINGS:
			const updatedInterfaceSettings = {
				...state,
				[actions.payload.tab]: {
					...state[actions.payload.tab],
					[actions.payload.bar]: {
						is_open: !actions.payload.isOpen
					}
				}
			}

			localStorage.setItem('interface_settings', JSON.stringify(updatedInterfaceSettings))

			return updatedInterfaceSettings

		case types.ENABLE_CONFETTI:
			return { ...state, confetti: { run: true, type: 'default', text: actions.payload } }
		case types.DISABLE_CONFETTI:
			return { ...state, confetti: { run: false, type: 'default', text: null } }
		default:
			return state
	}
}
