import React, { useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { NavLink, withRouter } from 'react-router-dom'

import { Badge, Collapse } from 'reactstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'

import routes from '../routes/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { RolesEnum } from 'types/roles'
import { UserState } from 'redux/reducers/userReducer'

import logo from '../images/asya_logo_blue.png'
import { transliterate } from 'transliteration'
import selectors from 'redux/selectors'

const initOpenRoutes = (location: any) => {
	/* Open collapse element that matches current url */
	const pathName = location.pathname

	let _routes = {}

	routes.forEach((route: any, index: number) => {
		const isActive = pathName.indexOf(route.path) === 0
		const isOpen = route.open
		const isHome = route.containsHome && pathName === '/' ? true : false

		_routes = Object.assign({}, _routes, {
			[index]: isActive || isOpen || isHome
		})
	})

	return _routes
}

const SidebarCategory = withRouter(
	({ name, badgeColor, badgeText, icon: Icon, isOpen, children, onClick, location, to }: any) => {
		const { t } = useTranslation()
		const getSidebarItemClass = (path: any) => {
			return location.pathname.indexOf(path) !== -1 ||
				(location.pathname === '/' && path === '/dashboard')
				? 'active'
				: ''
		}

		return (
			<li className={'sidebar-item ' + getSidebarItemClass(to)}>
				<span
					data-toggle="collapse"
					className={'sidebar-link ' + (!isOpen ? 'collapsed' : '')}
					onClick={onClick}
					aria-expanded={isOpen ? 'true' : 'false'}
				>
					<Icon size={18} className="align-middle mr-3" />
					<span className="align-middle">{t(name)}</span>
					{badgeColor && badgeText ? (
						<Badge color={badgeColor} size={18} className="sidebar-badge">
							{badgeText}
						</Badge>
					) : null}
				</span>
				<Collapse isOpen={isOpen}>
					<ul id="item" className={'sidebar-dropdown list-unstyled'}>
						{children}
					</ul>
				</Collapse>
			</li>
		)
	}
)

const SidebarItem = withRouter(({ name, badgeColor, badgeText, icon: Icon, location, to }: any) => {
	const { t } = useTranslation()

	const getSidebarItemClass = (path: any) => {
		return location.pathname === path ? 'active' : ''
	}

	return (
		<li className={'sidebar-item ' + getSidebarItemClass(to)}>
			<NavLink to={to} className="sidebar-link" activeClassName="active">
				{Icon ? <Icon size={18} className="align-middle mr-3" /> : null}
				{t(name)}
				{badgeColor && badgeText ? (
					<Badge color={badgeColor} size={18} className="sidebar-badge">
						{badgeText}
					</Badge>
				) : null}
			</NavLink>
		</li>
	)
})

const Sidebar = (props: { location; sidebar; user: UserState }) => {
	const [openRoutes, setOpenRoutes] = useState<any>(() => initOpenRoutes(props.location))
	const { t, i18n } = useTranslation()
	const version: string = useSelector(selectors.getUser).version

	const toggle = (index: number) => {
		// Collapse all elements
		Object.keys(openRoutes).forEach(
			(item) =>
				openRoutes[index] ||
				setOpenRoutes((openRoutes: any) => Object.assign({}, openRoutes, { [item]: false }))
		)

		// Toggle selected element
		setOpenRoutes((openRoutes: any) =>
			Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
		)
	}

	return (
		<nav
			className={
				'sidebar' +
				(!props.sidebar.isOpen ? ' toggled' : '') +
				(props.sidebar.isSticky ? ' sidebar-sticky' : '')
			}
		>
			<div className="sidebar-content">
				<PerfectScrollbar>
					<a className="sidebar-brand" href="/dashboard/default">
						<img src={logo} alt="Logo" width="100px" />
					</a>

					<span style={{ display: 'none' }}>ASYA v {version}</span>

					<ul className="sidebar-nav">
						{routes.map((category: any, index: number) => {
							let hasRequiredParentRole =
								!category.requiredRoles ||
								category.requiredRoles?.some((r: RolesEnum) =>
									props.user.employee.user_roles?.includes(r)
								) ||
								props.user.employee.user_roles?.includes(RolesEnum.admin)

							if (
								category.requiredRoles?.includes(RolesEnum.founder) &&
								!props.user.employee.user_roles?.includes(RolesEnum.founder)
							) {
								hasRequiredParentRole = false
							}

							var isAuth = true

							if (category.noRender) return null

							return (
								<React.Fragment key={index}>
									{category.header ? (
										<li className="sidebar-header">{category.header}</li>
									) : null}

									{(!category.isPrivate || isAuth) &&
										hasRequiredParentRole &&
										(category.children ? (
											<SidebarCategory
												name={category.name}
												badgeColor={category.badgeColor}
												badgeText={category.badgeText}
												icon={category.icon}
												to={category.path}
												isOpen={openRoutes[index]}
												onClick={() => toggle(index)}
											>
												{category.children.map(
													(route: any, index: number) => {
														let hasRequiredRole =
															!route.requiredRoles ||
															route.requiredRoles?.some(
																(r: RolesEnum) =>
																	props.user.employee.user_roles.includes(
																		r
																	)
															) ||
															props.user.employee.user_roles?.includes(
																RolesEnum.admin
															)

														if (
															route.requiredRoles?.includes(
																RolesEnum.founder
															) &&
															!props.user.employee.user_roles?.includes(
																RolesEnum.founder
															)
														) {
															hasRequiredRole = false
														}

														if (!hasRequiredRole) return null

														return (
															<SidebarItem
																key={index}
																name={route.name}
																to={route.path}
																badgeColor={route.badgeColor}
																badgeText={route.badgeText}
															/>
														)
													}
												)}
											</SidebarCategory>
										) : (
											<SidebarItem
												name={category.name}
												to={category.path}
												icon={category.icon}
												badgeColor={category.badgeColor}
												badgeText={category.badgeText}
											/>
										))}
								</React.Fragment>
							)
						})}

						<div className="sidebar-bottom d-none d-lg-block">
							<div className="media">
								<FontAwesomeIcon
									className="h1 text-light rounded-circle mr-3"
									icon={faUserCircle}
								/>
								<div className="media-body">
									<div>
										<a
											href="/dashboard/default"
											style={{ textDecoration: 'none' }}
										>
											<h5 className="mb-1">
												{i18n.language === 'en'
													? transliterate(
															props.user.employee.first_name +
																' ' +
																props.user.employee.last_name,
															{
																replace: [
																	['Г', 'H'],
																	['г', 'h']
																]
															}
													  )
													: props.user.employee.first_name +
													  ' ' +
													  props.user.employee.last_name}
											</h5>
											<h5 className="text-muted m-0">
												{t('sideMenu_profile')}
											</h5>
										</a>
									</div>
								</div>
							</div>
						</div>
					</ul>
				</PerfectScrollbar>
			</div>
		</nav>
	)
}

export default withRouter(
	connect((store: any) => ({
		sidebar: store.interface_settings.sidebar,
		layout: store.layout,
		user: store.user
	}))(Sidebar)
)
