import { addCheckin, addEndDayCheckin } from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import WorkCheckin from 'types/workCheckin'
import { t } from 'i18next'

export const addCheckinFunc = () => async (dispatch) => {
	dispatch(actions.userActions.addWorkCheckinRequest())
	try {
		const addedWorkChekin: WorkCheckin = await addCheckin()
		return dispatch(actions.userActions.addWorkCheckinSuccess(addedWorkChekin))
	} catch (error: any) {
		toastr.error('Error', error.response.data.error)
		return dispatch(actions.userActions.addWorkCheckinError())
	}
}

export const addEndDayCheckinFunc = (id: string) => async (dispatch) => {
	dispatch(actions.userActions.addEndDayWorkCheckinRequest())
	try {
		const addedEndDayWorkChekin: WorkCheckin = await addEndDayCheckin(id)
		toastr.success('', t('reduxToastrSuccess_endDayCheckin'))
		return dispatch(actions.userActions.addEndDayWorkCheckinSuccess(addedEndDayWorkChekin))
	} catch (error: any) {
		toastr.error('Error', error.response.data.error)
		return dispatch(actions.userActions.addEndDayWorkCheckinError())
	}
}
