import { updateProjectSumm, getFinanceByMonth, recalculateFinance } from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import Project, { UpdatedProjectSummData } from 'types/project'
import FinanceCollection from 'types/finance'

export const loadFinanceCollection = (month: string) => async (dispatch) => {
	dispatch(actions.dataActions.finance.getFinanceCollectionRequest())
	try {
		const financeCollection: FinanceCollection = await getFinanceByMonth(month)
		dispatch(actions.dataActions.finance.getFinanceCollectionSuccess(financeCollection))
	} catch (error: any) {
		//const message = t('reduxToastrError_loadFinanceCollection')
		toastr.error('Error', error.response.data.message)
		dispatch(actions.dataActions.finance.getFinanceCollectionError())
	}
}

export const updateStoreProjectsSumm =
	(newProjectData: UpdatedProjectSummData, name: string) => async (dispatch) => {
		dispatch(actions.dataActions.finance.updateProjectSummRequest())

		try {
			const updatedProject: Project = await updateProjectSumm(newProjectData)
			const message = t('reduxToastrSuccess_updateStoreProjectsSumm', { name: name })
			toastr.success('', message)
			return dispatch(actions.dataActions.finance.updateProjectSummSuccess(updatedProject))
		} catch (error) {
			const message = t('reduxToastrError_updateStoreProjectsSumm', { name: name })
			toastr.error('Error', message)
			return dispatch(actions.dataActions.finance.updateProjectSummError())
		}
	}

export const recalculateFinanceCollections = () => async (dispatch) => {
	dispatch(actions.dataActions.finance.recalculateFinanceRequest())
	try {
		const financeCollections: FinanceCollection = await recalculateFinance()
		toastr.warning(
			'',
			'Результат перерахунку буде доступний приблизно через 3-5 хвилин після перезавантаження сторінки'
		)
		dispatch(actions.dataActions.finance.recalculateFinanceSuccess(financeCollections))
	} catch (error: any) {
		console.log(error)
		toastr.error('Error', 'Помилка у перерахунку окупності')
		dispatch(actions.dataActions.finance.recalculateFinanceError())
	}
}
