import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import Sickness from 'types/sickness'

export const loadSicknessesRequest = createAction(types.LOAD_SICKNESSES_REQUEST)
export const loadSicknessesSuccess = createAction<Sickness[]>(types.LOAD_SICKNESSES_SUCCESS)
export const loadSicknessesError = createAction(types.LOAD_SICKNESSES_ERROR)

export const loadTodaySicknessesRequest = createAction(types.LOAD_TODAY_SICKNESSES_REQUEST)
export const loadTodaySicknessesSuccess = createAction<Sickness[]>(
	types.LOAD_TODAY_SICKNESSES_SUCCESS
)
export const loadTodaySicknessesError = createAction(types.LOAD_TODAY_SICKNESSES_ERROR)

export const loadEmployeeSicknessesRequest = createAction(types.LOAD_EMPLOYEE_SICKNESSES_REQUEST)
export const loadEmployeeSicknessesSuccess = createAction<Sickness[]>(
	types.LOAD_EMPLOYEE_SICKNESSES_SUCCESS
)
export const loadEmployeeSicknessesError = createAction(types.LOAD_EMPLOYEE_SICKNESSES_ERROR)

export const createSicknessRequest = createAction(types.CREATE_SICKNESS_REQUEST)
export const createSicknessSuccess = createAction<Sickness>(types.CREATE_SICKNESS_SUCCESS)
export const createSicknessError = createAction(types.CREATE_SICKNESS_ERROR)

export const updateSicknessRequest = createAction(types.UPDATE_SICKNESS_REQUEST)
export const updateSicknessSuccess = createAction<Sickness>(types.UPDATE_SICKNESS_SUCCESS)
export const updateSicknessesError = createAction(types.UPDATE_SICKNESS_ERROR)

export const uploadSicknessFileRequest = createAction(types.UPLOAD_SICKNESS_FILE_REQUEST)
export const uploadSicknessFileSuccess = createAction<Sickness>(types.UPLOAD_SICKNESS_FILE_SUCCESS)
export const uploadSicknessFileError = createAction(types.UPLOAD_SICKNESS_FILE_ERROR)

export const deleteSicknessFileRequest = createAction(types.DELETE_SICKNESS_FILE_REQUEST)
export const deleteSicknessFileSuccess = createAction<Sickness>(types.DELETE_SICKNESS_FILE_SUCCESS)
export const deleteSicknessFileError = createAction(types.DELETE_SICKNESS_FILE_ERROR)

export const deleteSicknessRequest = createAction(types.DELETE_SICKNESS_REQUEST)
export const deleteSicknessSuccess = createAction<Sickness>(types.DELETE_SICKNESS_SUCCESS)
export const deleteSicknessError = createAction(types.DELETE_SICKNESS_ERROR)
