import {
	artSetup,
	takeConcept,
	uploadConceptArt,
	unSetArt,
	deleteConceptArtFiles,
	artDone
} from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import ProjectArtInfo, { ArtDoneUpatingData, ArtSetupData } from 'types/project_art_info'

export const takeConceptArtFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.conceptArts.takeConceptArtRequest())
	try {
		const conceptArt: ProjectArtInfo = await takeConcept(id)
		toastr.success('', t('reduxToastrSuccess_conceptArtTaken'))
		return dispatch(actions.dataActions.conceptArts.takeConceptArtSuccess(conceptArt))
	} catch (error: any) {
		toastr.error('Error', error.response?.data.message)
		console.log(error)
		return dispatch(actions.dataActions.conceptArts.takeConceptArtError())
	}
}

export const artSetupProjectFunc = (setupData: ArtSetupData) => async (dispatch) => {
	dispatch(actions.dataActions.conceptArts.setupConceptArtRequest())
	try {
		const artInfo: ProjectArtInfo = await artSetup(setupData)

		toastr.success('', t('reduxToastrSuccess_artSetupCompleted'))
		return dispatch(actions.dataActions.conceptArts.setupConceptArtSuccess(artInfo))
	} catch (error: any) {
		console.log(error)
		toastr.error('Error', error.response?.data.message)
		console.log(error)
		return dispatch(actions.dataActions.conceptArts.setupConceptArtError)
	}
}

export const unSetConceptArtFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.conceptArts.unSetConceptArtRequest())
	try {
		const unsetedArt: ProjectArtInfo = await unSetArt(id)

		toastr.success('', t('reduxToastrSuccess_unSetConceptArt'))
		return dispatch(actions.dataActions.conceptArts.unSetConceptArtSuccess(unsetedArt))
	} catch (error: any) {
		toastr.error('Error', error.response?.data?.message)
		console.log(error)
		return dispatch(actions.dataActions.conceptArts.unSetConceptArtError())
	}
}

export const uploadConceptArtFunc = (uploadData) => async (dispatch) => {
	dispatch(actions.dataActions.conceptArts.uploadConceptArtRequest())
	try {
		const { art_info_id, files } = uploadData
		const uploadedConceptArt = await uploadConceptArt(art_info_id, files)

		toastr.success('', t('reduxToastrSuccess_conceptArtUploaded'))
		return dispatch(actions.dataActions.conceptArts.uploadConceptArtSuccess(uploadedConceptArt))
	} catch (error: any) {
		toastr.error('', 'Error in file loading')
		console.log(error)
		return dispatch(actions.dataActions.conceptArts.uploadConceptArtError())
	}
}

export const doneConceptArtFunc = (data: ArtDoneUpatingData) => async (dispatch) => {
	dispatch(actions.dataActions.conceptArts.doneConceptArtRequest())
	try {
		const doneConceptArt: ProjectArtInfo = await artDone(data)

		toastr.success('', t('reduxToastrSuccess_conceptArtDone'))
		return dispatch(actions.dataActions.conceptArts.doneConceptArtSuccess(doneConceptArt))
	} catch (error: any) {
		toastr.error('Error', error.response?.data.message)
		console.log(error)
		return dispatch(actions.dataActions.conceptArts.doneConceptArtError())
	}
}

export const deleteConceptArtFilesFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.conceptArts.deleteConceptArtFilesRequest())
	try {
		const updatedConceptArt = await deleteConceptArtFiles(id)

		toastr.success('', t('reduxToastrSuccess_deleteConceptArt'))
		return dispatch(
			actions.dataActions.conceptArts.deleteConceptArtFilesSuccess(updatedConceptArt)
		)
	} catch (error: any) {
		toastr.error('Error', error.response?.data.message)
		console.log(error)
		return dispatch(actions.dataActions.conceptArts.deleteConceptArtFilesError())
	}
}
