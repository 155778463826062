import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	addNewHiringHistoryItem,
	deleteHiringHistoryItem,
	editHiringHistoryItemComment,
	uploadHiringHistoryItemFile
} from 'utils/api.request'
import {
	HiringHistoryItemCreationData,
	HiringHistoryItemDeletingData,
	HiringHistoryItemEditCommentData,
	HiringHistoryItemFileData,
	HiringItem
} from 'types/recruiting/hiring.item'

//hiring_item
export const addNewHiringHistoryItemFunc =
	(creationData: HiringHistoryItemCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.hiringItems.addNewHiringHistoryItemRequest())
		try {
			const updatedHiringItem: HiringItem = await addNewHiringHistoryItem(creationData)

			toastr.success(
				'',
				t(
					creationData.comment_as_stage
						? 'reduxToastrSuccess_addNewHiringHistoryItemComment'
						: 'reduxToastrSuccess_addNewHiringHistoryItemStage'
				)
			)
			return dispatch(
				actions.dataActions.hiringItems.addNewHiringHistoryItemSuccess(updatedHiringItem)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.hiringItems.addNewHiringHistoryItemError())
		}
	}

export const editHiringHistoryItemCommentFunc =
	(editData: HiringHistoryItemEditCommentData) => async (dispatch) => {
		dispatch(actions.dataActions.hiringItems.editHiringHistoryItemCommentRequest())
		try {
			const updatedHiringItem: HiringItem = await editHiringHistoryItemComment(editData)

			toastr.success('', t('reduxToastrSuccess_editHiringHistoryItemCommentStage'))
			return dispatch(
				actions.dataActions.hiringItems.editHiringHistoryItemCommentSuccess(
					updatedHiringItem
				)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.hiringItems.editHiringHistoryItemCommentError())
		}
	}

export const uploadHiringHistoryItemFileFunc =
	(fileData: HiringHistoryItemFileData) => async (dispatch) => {
		dispatch(actions.dataActions.hiringItems.uploadHiringHistoryItemFileRequest())
		try {
			const updatedHiringItem: HiringItem = await uploadHiringHistoryItemFile(fileData)

			toastr.success('', t('reduxToastrSuccess_uploadHiringHistoryItemFileStage'))
			return dispatch(
				actions.dataActions.hiringItems.uploadHiringHistoryItemFileSuccess(
					updatedHiringItem
				)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.hiringItems.uploadHiringHistoryItemFileError())
		}
	}

export const deleteHiringHistoryItemFunc =
	(deletingData: HiringHistoryItemDeletingData, comment?: boolean) => async (dispatch) => {
		dispatch(actions.dataActions.hiringItems.deleteHiringHistoryItemRequest())
		try {
			const updatedHiringItem: HiringItem = await deleteHiringHistoryItem(deletingData)

			toastr.success(
				'',
				t(
					comment
						? 'reduxToastrSuccess_deleteHiringHistoryItemComment'
						: 'reduxToastrSuccess_deleteHiringHistoryItemStage'
				)
			)

			return dispatch(
				actions.dataActions.hiringItems.deleteHiringHistoryItemSuccess(updatedHiringItem)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.hiringItems.deleteHiringHistoryItemError())
		}
	}
