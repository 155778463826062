import dataActions from './dataActions/index'
import * as userActions from './userActions'
import * as interfaceSettingsActions from './interfaceSettingsActions'

const actions = {
	dataActions,
	userActions,
	interfaceSettingsActions
}

export default actions
