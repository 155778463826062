import * as types from '../constants'
import { createAction } from '@reduxjs/toolkit'
import { InterfaceState } from 'components/UniversalTable'
import InterfaceSettings from 'types/interfaceSettings'

export const uploadInterfaceSettings = createAction<InterfaceSettings>(
	types.UPLOAD_INTERFACE_SETTINGS
)
export const updateInterfaceSettings = createAction<InterfaceState>(types.UPDATE_INTERFACE_SETTINGS)

export const toggleSidebar = createAction(types.SIDEBAR_VISIBILITY_TOGGLE)
export const showSidebar = createAction(types.SIDEBAR_VISIBILITY_SHOW)
export const hideSidebar = createAction(types.SIDEBAR_VISIBILITY_HIDE)
export const toggleStickySidebar = createAction(types.SIDEBAR_STICKY_TOGGLE)
export const enableStickySidebar = createAction(types.SIDEBAR_STICKY_ENABLE)
export const disableStickySidebar = createAction(types.SIDEBAR_STICKY_DISABLE)

export const enableConfetti = createAction<string>(types.ENABLE_CONFETTI)
export const disableConfetti = createAction(types.DISABLE_CONFETTI)
