import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import { Stage, StageCreationData, UpdateStagesIndexesData } from 'types/recruiting/stage'
import { archiveStage, createStage, loadStages, updateStagesIndexes } from 'utils/api.request'

export const loadStagesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.stages.loadStagesRequest())
	try {
		const allStages: Stage[] = await loadStages()

		return dispatch(actions.dataActions.stages.loadStagesSuccess(allStages))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.stages.loadStagesError())
	}
}

export const createStageFunc = (creationData: StageCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.stages.createStageRequest())
	try {
		const newStage: Stage = await createStage(creationData)

		toastr.success('', t('reduxToastrSuccess_createStage'))
		return dispatch(actions.dataActions.stages.createStageSuccess(newStage))
	} catch (error: any) {
		console.log(error)
		toastr.error('Error', error.response.data.message[0])
		return dispatch(actions.dataActions.stages.createStageError())
	}
}

export const updateStagesIndexesFunc =
	(updateData: UpdateStagesIndexesData) => async (dispatch) => {
		dispatch(actions.dataActions.stages.updateStagesIndexesRequest())

		try {
			const updatedStage: Stage = await updateStagesIndexes(updateData)

			toastr.success('', t('reduxToastrSuccess_updateStagesIndexes'))
			return dispatch(actions.dataActions.stages.updateStagesIndexesSuccess(updatedStage))
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.stages.updateStagesIndexesError())
		}
	}

export const archiveStageFunc = (stage_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.stages.archiveStageRequest())
	try {
		const deletedStage: Stage = await archiveStage(stage_id)

		toastr.success('', t('reduxToastrSuccess_deleteStage'))
		return dispatch(actions.dataActions.stages.archiveStageSuccess(deletedStage))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.stages.archiveStageError())
	}
}
