import { MonthsOptions } from 'components/ProjectsByMonthsSelector'
import { ShortEmployee } from './employee'
import { PartyMember } from './party'
import { PositionsEnum } from './positions'
import Project, { ProjectRolesEnum } from './project'

export type SurveyState = {
	needed: boolean
	type: SurveyTypeEnum
	id: string
	position: ProjectRolesEnum
	questions: SurveyQuestion[]
	project?: { _id: string; name: string }
	party?: { _id: string; title: string }
}

export interface NoSurvey {
	needed: false
}

export enum SurveyTypeEnum {
	weekly = 'weekly',
	monthly = 'monthly',
	projects = 'project',
	party = 'party'
}

export enum ProjectSurveyRolesEnum {
	producer = 'producer',
	co_lead = 'co_lead',
	art_lead = 'art_lead',
	developer = 'developer',
	artist = 'artist',
	mentor = 'mentor',
	art_mentor = 'art_mentor'
	/* concept_artist = 'concept_artist',
	qa = 'qa',
	level_designer = 'level_designer',
	economy_designer = 'economy_designer',
	sound_designer = 'sound_designer' */
}

export interface SurveyQuestion {
	_id: string
	question: string
	min_description: string
	max_description: string
	surveyed_position?: ProjectRolesEnum
	evaluated_position?: ProjectRolesEnum
	evaluated_employee?: ShortEmployee
	answer_value?: number
}

export type SurveyAnswer = {
	employee: ShortEmployee
	question: string
	value: number
}

export interface ProjectSurveyAnswer {
	readonly question: string
	readonly value: number
}

export interface TeamMemberSurvey {
	_id: string
	employee: ShortEmployee
	questions: SurveyQuestion[]
	done: boolean
}

export interface ProjectSurvey {
	_id: string
	type: SurveyTypeEnum
	start_date: Date
	project: Project
	project_name: string
	producer: TeamMemberSurvey[]
	co_lead: TeamMemberSurvey[]
	art_lead: TeamMemberSurvey[]
	developer: TeamMemberSurvey[]
	artist: TeamMemberSurvey[]
	mentor: TeamMemberSurvey[]
	art_mentor: TeamMemberSurvey[]
}

export interface PartySurvey {
	_id: string
	type: SurveyTypeEnum
	start_date: Date
	party: string
	members: PartyMember[]
	questions: SurveyQuestion[]
	answers: SurveyAnswer[]
}

export type Survey = {
	_id: string
	type: SurveyTypeEnum
	start_date: Date
	number: number
	surveyed_positions: PositionsEnum[]
	questions: SurveyQuestion[]
	answers: SurveyAnswer[]
}

export type ScoreInfo = {
	employee: ShortEmployee
	score: number
}

export type Scores = {
	[key in ProjectRolesEnum]: {
		avarage_by_role: number
		by_employee: ScoreInfo[]
	}
}

export interface SendAnswersData {
	survey_id: string
	type: SurveyTypeEnum
	position: ProjectRolesEnum
	answers: SurveyAnswer[]
}

export interface DoneSurveysPayload {
	surveys: ProjectSurvey[]
	months: MonthsOptions
}
