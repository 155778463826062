import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import { KPICardType } from 'types/kpiCard'

export const loadKPIRequest = createAction(types.LOAD_KPI_REQUEST)
export const loadKPISuccess = createAction<KPICardType[]>(types.LOAD_KPI_SUCCESS)
export const loadKPIError = createAction(types.LOAD_KPI_ERROR)

export const loadEmployeeKPIRequest = createAction(types.LOAD_EMPLOYEE_KPI_REQUEST)
export const loadEmployeeKPISuccess = createAction<KPICardType[]>(types.LOAD_EMPLOYEE_KPI_SUCCESS)
export const loadEmployeeKPIError = createAction(types.LOAD_EMPLOYEE_KPI_ERROR)

export const loadTodayDedlinedKPIRequest = createAction(types.LOAD_DEDLINED_KPI_REQUEST)
export const loadTodayDedlinedKPISuccess = createAction<KPICardType[]>(
	types.LOAD_DEDLINED_KPI_SUCCESS
)
export const loadTodayDedlinedKPIError = createAction(types.LOAD_DEDLINED_KPI_ERROR)

export const createKPIRequest = createAction(types.CREATE_KPI_REQUEST)
export const createKPISuccess = createAction<KPICardType>(types.CREATE_KPI_SUCCESS)
export const createKPIError = createAction(types.CREATE_KPI_ERROR)

export const checkKPIRequest = createAction(types.CHECK_KPI_REQUEST)
export const checkKPISuccess = createAction<KPICardType>(types.CHECK_KPI_SUCCESS)
export const checkKPIError = createAction(types.CHECK_KPI_ERROR)

export const doneKPIRequest = createAction(types.DONE_KPI_REQUEST)
export const doneKPISuccess = createAction<KPICardType>(types.DONE_KPI_SUCCESS)
export const doneKPIError = createAction(types.DONE_KPI_ERROR)

export const deleteKPIRequest = createAction(types.DELETE_KPI_REQUEST)
export const deleteKPISuccess = createAction<KPICardType>(types.DELETE_KPI_SUCCESS)
export const deleteKPIError = createAction(types.DELETE_KPI_ERROR)
