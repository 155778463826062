import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import operations from 'redux/operations'

import {
	Button,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	FormGroup,
	Input,
	FormText
} from 'reactstrap'

import { Col } from 'react-bootstrap'

import 'moment/locale/ru'

import Loader from 'components/Loader'
import PositionWrapper from 'components/PositionWrapper'

import { RiImageAddFill } from 'react-icons/ri'

import { PositionsEnum } from 'types/positions'
import ProjectArtInfo from 'types/project_art_info'

interface AddConceptArtModalProps {
	artInfo: ProjectArtInfo
	color?: string
	small?: boolean
}

const AddConceptArtModal = (props: AddConceptArtModalProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [loading, setLoading] = useState<boolean>(false)
	const [files, setFiles] = useState<any[]>([])

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const upload = async () => {
		if (!files.length) return

		setLoading(true)
		const uploadData = {
			art_info_id: props.artInfo._id,
			files: files
		}

		dispatch(operations.dataOperations.conceptArts.uploadConceptArtFunc(uploadData))

		setLoading(false)
		setIsOpen(false)
	}

	const toggle = () => {
		setIsOpen(!isOpen)
	}

	const onFilesChanged = (e) => {
		setFiles(e.target.files)
	}

	if (props.artInfo.not_needed) return null

	return (
		<>
			<PositionWrapper
				requared_positions={[
					PositionsEnum.concept_artist,
					PositionsEnum.technical_art_director
				]}
			>
				<Button
					color={props.color ?? 'primary'}
					className={`shadow-sm ml-2 ${props.small ? '' : 'mb-1'}`}
					onClick={toggle}
					outline
				>
					{props.small ? (
						<RiImageAddFill />
					) : (
						<>
							<RiImageAddFill /> {t('AddConceptArtModal_buttonUpload')}
						</>
					)}
				</Button>
			</PositionWrapper>
			<Modal isOpen={isOpen} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>{t('AddConceptArtModal_header')}</ModalHeader>

				{loading ? (
					<Loader size="sm" />
				) : (
					<ModalBody className="m-1">
						<Col md={6}>
							<FormGroup>
								<Input
									type="file"
									name="file"
									accept=".jpg, .jpeg, .png"
									multiple
									onChange={onFilesChanged}
								/>
								<FormText color="muted">
									{t('AddConceptArtModal_fileFormats')}
									<br />
									{t('AddConceptArtModal_fileSize')}
								</FormText>
							</FormGroup>
						</Col>
					</ModalBody>
				)}

				<ModalFooter>
					<Button onClick={toggle} disabled={loading}>
						{t('AddConceptArtModal_buttonCancel')}
					</Button>{' '}
					<Button color="primary" onClick={upload} disabled={loading}>
						{t('AddConceptArtModal_buttonConfirm')}
					</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

export default AddConceptArtModal
