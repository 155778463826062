import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import { Funnel, FunnelCreationData, FunnelUpdatingData } from 'types/recruiting/funnel'
import { archiveFunnel, createFunnel, editFunnel, loadFunnels } from 'utils/api.request'

export const loadFunnelsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.funnels.loadFunnelsRequest())
	try {
		const allFunnels: Funnel[] = await loadFunnels()

		return dispatch(actions.dataActions.funnels.loadFunnelsSuccess(allFunnels))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.funnels.loadFunnelsError())
	}
}

export const createFunnelFunc = (creationData: FunnelCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.funnels.createFunnelRequest())
	try {
		const newFunnel: Funnel = await createFunnel(creationData)

		toastr.success('', t('reduxToastrSuccess_createFunnel'))
		return dispatch(actions.dataActions.funnels.createFunnelSuccess(newFunnel))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.funnels.createFunnelError())
	}
}

export const updateFunnelFunc = (updatingData: FunnelUpdatingData) => async (dispatch) => {
	dispatch(actions.dataActions.funnels.updateFunnelRequest())
	try {
		const updatedFunnel: Funnel = await editFunnel(updatingData)

		toastr.success('', t('reduxToastrSuccess_updateFunnel'))
		return dispatch(actions.dataActions.funnels.updateFunnelSuccess(updatedFunnel))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.funnels.updateFunnelError())
	}
}

export const archiveFunnelFunc = (funnel_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.funnels.archiveFunnelRequest())
	try {
		const deletedFunnel: Funnel = await archiveFunnel(funnel_id)

		toastr.success('', t('reduxToastrSuccess_archiveFunnel'))
		return dispatch(actions.dataActions.funnels.archiveFunnelSuccess(deletedFunnel))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.funnels.archiveFunnelError())
	}
}
