import { useEffect, useState } from 'react'
import { RolesEnum } from 'types/roles'
import { connect } from 'react-redux'
import { StoreType } from 'redux/store'
import { UserState } from 'redux/reducers/userReducer'
import { PositionsEnum } from 'types/positions'

const PositionWrapper = (props: {
	user: UserState
	children: any
	requared_positions?: PositionsEnum[]
}) => {
	const [userPosition, setUserPosition] = useState<PositionsEnum | null>(null)

	useEffect(() => {
		setUserPosition(props.user.employee.position)
	}, [props.user])

	if (!props.user) return null

	if (
		(userPosition && props.requared_positions?.includes(userPosition)) ||
		props.user.employee.user_roles?.includes(RolesEnum.admin)
	)
		return props.children

	return null
}

export default connect((store: StoreType) => ({
	user: store.user
}))(PositionWrapper)
