import { createTrainingProjects, endTrainingProjects, getTrainingProjects } from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import TrainingProject, {
	TrainingProjectCreationData,
	TrainingProjectEndData
} from 'types/trraining.project'

export const loadTrainingProjectsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.trainingProjects.loadTrainingProjectsRequest())
	try {
		const loadedTrainingProjects: TrainingProject[] = await getTrainingProjects()
		return dispatch(
			actions.dataActions.trainingProjects.loadTrainingProjectsSuccess(loadedTrainingProjects)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.trainingProjects.loadTrainingProjectsError())
	}
}

export const createTrainingProjectFunc =
	(creationData: TrainingProjectCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.trainingProjects.createTrainingProjectRequest())
		try {
			const createdTrainingProject = await createTrainingProjects(creationData)
			toastr.success('', t('reduxToastrSuccess_trainingProjectCreated'))
			return dispatch(
				actions.dataActions.trainingProjects.createTrainingProjectSuccess(
					createdTrainingProject
				)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.trainingProjects.createTrainingProjectError())
		}
	}

export const endTrainingProjectFunc = (endData: TrainingProjectEndData) => async (dispatch) => {
	dispatch(actions.dataActions.trainingProjects.endTrainingProjectRequest())
	try {
		const endedTrainingProject = await endTrainingProjects(endData)
		toastr.success('', t('reduxToastrSuccess_trainingProjectEnded'))
		return dispatch(
			actions.dataActions.trainingProjects.endTrainingProjectSuccess(endedTrainingProject)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.trainingProjects.endTrainingProjectError())
	}
}
