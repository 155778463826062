import React from 'react'
import { useTranslation } from 'react-i18next'
import { ModalBody } from 'reactstrap'

const FalseDevice = () => {
	const { t } = useTranslation()

	return (
		<ModalBody
			className="text-center"
			style={{
				height: '30em',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<h1 className="font-weight-bold">{t('FalseDevice_header')}</h1>
			<p className="h1">{t('FalseDevice_notAllowed')}</p>
			<p className="h2 font-weight-normal mt-3 mb-4">{t('FalseDevice_message')}</p>
		</ModalBody>
	)
}

export default FalseDevice
