import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { HiringItem } from 'types/recruiting/hiring.item'

export const addNewHiringHistoryItemRequest = createAction(
	types.ADD_NEW_HIRING_HISTORY_ITEM_REQUEST
)
export const addNewHiringHistoryItemSuccess = createAction<HiringItem>(
	types.ADD_NEW_HIRING_HISTORY_ITEM_SUCCESS
)
export const addNewHiringHistoryItemError = createAction(types.ADD_NEW_HIRING_HISTORY_ITEM_ERROR)

export const editHiringHistoryItemCommentRequest = createAction(
	types.EDIT_HIRING_HISTORY_ITEM_COMMENT_REQUEST
)
export const editHiringHistoryItemCommentSuccess = createAction<HiringItem>(
	types.EDIT_HIRING_HISTORY_ITEM_COMMENT_SUCCESS
)
export const editHiringHistoryItemCommentError = createAction(
	types.EDIT_HIRING_HISTORY_ITEM_COMMENT_ERROR
)

export const uploadHiringHistoryItemFileRequest = createAction(
	types.UPLOAD_HIRING_HISTORY_ITEM_FILE_REQUEST
)
export const uploadHiringHistoryItemFileSuccess = createAction<HiringItem>(
	types.UPLOAD_HIRING_HISTORY_ITEM_FILE_SUCCESS
)
export const uploadHiringHistoryItemFileError = createAction(
	types.UPLOAD_HIRING_HISTORY_ITEM_FILE_ERROR
)

export const deleteHiringHistoryItemRequest = createAction(types.DELETE_HIRING_HISTORY_ITEM_REQUEST)
export const deleteHiringHistoryItemSuccess = createAction<HiringItem>(
	types.DELETE_HIRING_HISTORY_ITEM_SUCCESS
)
export const deleteHiringHistoryItemError = createAction(types.DELETE_HIRING_HISTORY_ITEM_ERROR)
