import axios from 'axios'

const api = (download?: boolean) => {
	const token = localStorage.getItem('access_token')

	return axios.create({
		baseURL: `${window.location.protocol}//${process.env.REACT_APP_API_URL}`,
		responseType: download ? 'blob' : 'json',
		headers: {
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Expose-Headers': '*',
			Authorization: `Bearer ${token}`,
			'Content-Type': download ? '' : 'application/json',
			Accept: download ? 'application/octet-stream' : 'application/json'
		}
	})
}

export default api
