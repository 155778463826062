import React from 'react'
import { Settings } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { UncontrolledTooltip } from 'reactstrap'
import RoleWrapper from './RoleWrapper'
import { RolesEnum } from 'types/roles'

export default function AdminPanel() {
	const { t } = useTranslation()

	return (
		<RoleWrapper requared_role={[RolesEnum.admin, RolesEnum.recruiting]}>
			<div className="nav-item mr-2 pt-2">
				<UncontrolledTooltip placement="left" target={'TooltipAdminPanelButton'}>
					{t('AdminPanel_textTooltip')}
				</UncontrolledTooltip>
				<Link to="/system_settings">
					<Settings id="TooltipAdminPanelButton" />
				</Link>
			</div>
		</RoleWrapper>
	)
}
