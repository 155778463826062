import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, CardBody, CardHeader, UncontrolledCarousel } from 'reactstrap'
import { Row, Col } from 'react-bootstrap'
import ProjectArtInfo, { ConceptArtFile } from 'types/project_art_info'
import AddConceptArtModal from '../../Modals/AddConceptArtModal'
import DeleteConceptArtModal from '../../Modals/DeleteConceptArtModal'
//import TakeConceptModal from '../../Modals/TakeConceptModal'

export const GOOGLE_DRIVE_URL = (id?) => {
	if (!id) return null
	return `https://drive.google.com/uc?export=view&id=${id}`
}

const ConceptArtCard = (props: { art_info: ProjectArtInfo; title: string }) => {
	const { t } = useTranslation()

	const count = (): number =>
		props.art_info?.concept_art?.length + props.art_info?.concept_art_links?.length

	const noArt = !props.art_info || count() === 0

	return (
		<Card className="flex-fill" style={{ minHeight: '307px' }}>
			<CardHeader className="card-title pb-0">
				<Row>
					<Col>{t('ConceptArtCard_header')}</Col>
					{!noArt && (
						<Col className="text-right">
							<AddConceptArtModal artInfo={props.art_info} small />
							<DeleteConceptArtModal projectArtInfo={props.art_info} />
						</Col>
					)}
				</Row>
			</CardHeader>
			<CardBody className="d-flex">
				{noArt ? (
					<div className="h-100 w-100 text-center">
						<div
							style={{
								margin: 0,
								position: 'absolute',
								top: '50%',
								left: '50%',
								marginRight: '-50%',
								transform: 'translate(-50%, -50%)'
							}}
						>
							{props.art_info.concept_art_taked && (
								<AddConceptArtModal artInfo={props.art_info} />
							)}
							{/* {props.art_info.concept_art_taked ? (
								<AddConceptArtModal artInfo={props.art_info} />
							) : (
								<TakeConceptModal
									projectArtInfo={props.art_info}
									name={props.title}
								/>
							)} */}
						</div>
					</div>
				) : (
					<UncontrolledCarousel
						items={[
							...props.art_info?.concept_art_links?.map((filelink, i) => {
								return {
									src: filelink,
									key: i.toString(),
									caption: ''
								}
							}),
							...props.art_info?.concept_art?.map((file: ConceptArtFile, i) => {
								return {
									src: GOOGLE_DRIVE_URL(file.file_id),
									key: i.toString(),
									caption: ''
								}
							})
						]}
						indicators={count() > 1}
						controls={count() > 1}
					/>
				)}
			</CardBody>
		</Card>
	)
}

export default ConceptArtCard
