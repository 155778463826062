import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Application } from 'types/recruiting/application'

export const loadApplicationsRequest = createAction(types.LOAD_APPLICATIONS_REQUEST)
export const loadApplicationsSuccess = createAction<Application[]>(types.LOAD_APPLICATIONS_SUCCESS)
export const loadApplicationsError = createAction(types.LOAD_APPLICATIONS_ERROR)

export const createApplicationRequest = createAction(types.CREATE_APPLICATION_REQUEST)
export const createApplicationSuccess = createAction<Application>(types.CREATE_APPLICATION_SUCCESS)
export const createApplicationError = createAction(types.CREATE_APPLICATION_ERROR)

export const archiveApplicationRequest = createAction(types.ARCHIVE_APPLICATION_REQUEST)
export const archiveApplicationSuccess = createAction<Application>(
	types.ARCHIVE_APPLICATION_SUCCESS
)
export const archiveApplicationError = createAction(types.ARCHIVE_APPLICATION_ERROR)
