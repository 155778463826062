import {
	getAllCheckins,
	getEmployeeCheckins,
	getMonthCheckins,
	updateCheckin
} from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import WorkCheckin from 'types/workCheckin'

export const loadWorkCheckinsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.checkins.loadWorkCheckinsRequest())
	try {
		const workChekins: WorkCheckin[] = await getAllCheckins()
		dispatch(actions.dataActions.checkins.loadWorkCheckinsSuccess(workChekins))
		return workChekins
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		console.log(error)
		return dispatch(actions.dataActions.checkins.loadWorkCheckinsError())
	}
}

export const loadMonthCheckinsFunc = (month: Date) => async (dispatch) => {
	dispatch(actions.dataActions.checkins.loadMonthCheckinsRequest())
	try {
		const workChekins: WorkCheckin[] = await getMonthCheckins(month)
		dispatch(actions.dataActions.checkins.loadMonthCheckinsSuccess(workChekins))
		return workChekins
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		console.log(error)
		return dispatch(actions.dataActions.checkins.loadMonthCheckinsError())
	}
}

export const loadEmployeeWorkCheckinsFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.checkins.loadEmployeeWorkCheckinsRequest())
	try {
		const workChekins: WorkCheckin[] = await getEmployeeCheckins(id)
		dispatch(actions.dataActions.checkins.loadEmployeeWorkCheckinsSuccess(workChekins))
		return workChekins
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		console.log(error)
		return dispatch(actions.dataActions.checkins.loadEmployeeWorkCheckinsError())
	}
}

export const updateWorkCheckinFunc = (updatedData) => async (dispatch) => {
	dispatch(actions.dataActions.checkins.updateWorkCheckiRequest())
	try {
		const { id, comment } = updatedData
		const updatedCheckin: WorkCheckin = await updateCheckin(id, comment)
		return dispatch(actions.dataActions.checkins.updateWorkCheckiSuccess(updatedCheckin))
	} catch (error: any) {
		toastr.error('Error', error.response.data.error)
		console.log(error)
		return dispatch(actions.dataActions.checkins.updateWorkCheckiError())
	}
}
