import { PositionsEnum } from 'types/positions'
import { RolesEnum } from 'types/roles'

export const getIndexAtId = (id: string) => {
	return parseInt(id.slice(-1), 16) % 10
}

export const rainbowColorNameAtId = (id: string) => {
	var index = getIndexAtId(id)
	return rainbowColorNames(index)
}

export const rainbowColorNameAtPosition = (position: PositionsEnum, roles: RolesEnum[]) => {
	const index = rainbowColorNameAtPositionIndex(position, roles)
	return rainbowColorNames(index)
}

export const rainbowHexColorNameAtPosition = (
	position: PositionsEnum,
	roles: RolesEnum[],
	alpha?: number
) => {
	const index = rainbowColorNameAtPositionIndex(position, roles)
	return rainbowColors(index, alpha ?? 1)
}

export const rainbowColorNameAtPositionIndex = (
	position: PositionsEnum,
	roles: RolesEnum[]
): number => {
	switch (position) {
		case PositionsEnum.ceo:
		case PositionsEnum.cto:
		case PositionsEnum.first_game_producer:
		case PositionsEnum.second_game_producer:
		case PositionsEnum.associate_producer:
			return 6

		case PositionsEnum.lead_developer:
		case PositionsEnum.technical_art_director:
			return 7

		case PositionsEnum.concept_artist:
		case PositionsEnum.artist:
			return roles.includes(RolesEnum.art_mentor) ? 0 : 5

		case PositionsEnum.unity_developer:
			return roles.includes(RolesEnum.mentor) ? 0 : 1

		case PositionsEnum.qa:
		case PositionsEnum.level_designer:
		case PositionsEnum.animator:
		case PositionsEnum.economy_designer:
		case PositionsEnum.game_designer:
		case PositionsEnum.sound_designer:
		case PositionsEnum.motion_designer:
			return 3

		case PositionsEnum.hr:
		case PositionsEnum.lead_recruiter:
		case PositionsEnum.recruiter:
		case PositionsEnum.business_development_manager:
		case PositionsEnum.community_manager:
		case PositionsEnum.office_manager:
		case PositionsEnum.secretary:
		case PositionsEnum.masseur:
		case PositionsEnum.cleaner:
			return 2

		default:
			return 4
	}
}

export const rainbowColorNames = (index: number) => {
	const colors = [
		'red',
		'orange',
		'yellow',
		'light-green',
		'green',
		'light-blue',
		'blue',
		'purple'
	]
	return colors[index % colors.length]
}

export const rainbowColors = (index: number, alpha: number) => {
	const colors = [
		'#FF5630',
		'#FF8B00',
		'#FFC400',
		'#36B37E',
		'#00875A',
		'#00B8D9',
		'#0052CC',
		'#5243AA'
	]
	return colors[index % colors.length] + Math.round(alpha * 255).toString(16)
}

export const rainbowColorsBG = (index: number, alpha: number) => {
	const colors = [
		'#FFEBE9',
		'#FFF2E6',
		'#FFF8E6',
		'#EAF6F0',
		'#E7F1ED',
		'#E8F6FA',
		'#E5ECF9',
		'#ECEAF5'
	]
	return colors[index % colors.length] + Math.round(alpha * 255).toString(16)
}

export const producerColors = (index: number) => {
	const colors = ['#E4F8E2', '#E4F8E2']
	return colors[index]
}

export const platformColors = (index: number, alpha: number) => {
	const indexes = [5, 3]
	return rainbowColors(indexes[index] ?? index, alpha)
}

export const colourStyles = (error?: boolean) => {
	return {
		control: (base, state) => ({
			...base,
			borderColor: error ? 'red' : 'hsl(0, 0%, 80%)'
		}),
		option: (styles, { data, isFocused }) => {
			return {
				...styles,
				color: rainbowColors(data.index, 1),
				backgroundColor: isFocused ? rainbowColors(data.index, 0.1) : null,
				':active': {
					...styles[':active'],
					backgroundColor: rainbowColors(data.index, 0.3)
				}
			}
		},
		multiValue: (styles, { data }) => {
			return {
				...styles,
				backgroundColor: rainbowColors(data.index, 0.1)
			}
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: rainbowColors(data.index, 1)
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: rainbowColors(data.index, 1),
			':hover': {
				backgroundColor: rainbowColors(data.index, 1),
				color: 'white'
			}
		})
	}
}

export const platformStyles = {
	option: (styles, { data, isFocused }) => {
		return {
			...styles,
			color: platformColors(data.index, 1),
			backgroundColor: isFocused ? platformColors(data.index, 0.1) : null,
			':active': {
				...styles[':active'],
				backgroundColor: platformColors(data.index, 0.3)
			}
		}
	},
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: platformColors(data.index, 0.1)
		}
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: platformColors(data.index, 1)
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: platformColors(data.index, 1),
		':hover': {
			backgroundColor: platformColors(data.index, 1),
			color: 'white'
		}
	})
}

export const publishersStyles = {
	option: (styles, { data, isFocused }) => {
		return {
			...styles,
			color: platformColors(data.index, 1),
			backgroundColor: isFocused ? platformColors(data.index, 0.1) : null,
			':active': {
				...styles[':active'],
				backgroundColor: platformColors(data.index, 0.3)
			}
		}
	},
	multiValue: (styles, { data }) => {
		return {
			...styles,
			backgroundColor: platformColors(data.index, 0.1)
		}
	},
	multiValueLabel: (styles, { data }) => ({
		...styles,
		color: platformColors(data.index, 1)
	}),
	multiValueRemove: (styles, { data }) => ({
		...styles,
		color: platformColors(data.index, 1),
		':hover': {
			backgroundColor: platformColors(data.index, 1),
			color: 'white'
		}
	}),
	placeholder: (styles, { data }) => ({
		...styles
	})
}
