import React, { useState } from 'react'
import { Button, Input, ModalBody, ModalHeader } from 'reactstrap'
import { Row, Col } from 'react-bootstrap'
import { toastr } from 'react-redux-toastr'
import { useDispatch } from 'react-redux'
import operations from 'redux/operations'
import { useTranslation } from 'react-i18next'

const firstExpression = {
	firstNumber: Math.floor(Math.random() * 10),
	secondNumber: Math.floor(Math.random() * 10)
}

const secondExpression = {
	firstNumber: Math.floor(Math.random() * 10),
	secondNumber: Math.floor(Math.random() * 10)
}

const thirdExpression = {
	firstNumber: Math.floor(Math.random() * 10),
	secondNumber: Math.floor(Math.random() * 10),
	thirdNumber: Math.floor(Math.random() * 10)
}

const CountTestPage = (props: { onSuccessCounting }) => {
	const [firstCount, setFirstCount] = useState<number>(0)
	const [secondCount, setSecondCount] = useState<number>(0)
	const [thirdCount, setThirdCount] = useState<number>(0)

	const { t } = useTranslation()

	const dispatch = useDispatch()

	const resultChecker = async () => {
		if (
			firstCount === firstExpression.firstNumber + firstExpression.secondNumber &&
			secondCount === secondExpression.firstNumber * secondExpression.secondNumber &&
			thirdCount ===
				thirdExpression.firstNumber +
					thirdExpression.secondNumber +
					thirdExpression.thirdNumber
		) {
			await dispatch(operations.userOperations.checkin.addCheckinFunc())
			return props.onSuccessCounting()
		}
		toastr.error('Error', 'Wrong answer')
		return
	}

	const onEnter = (e) => {
		if (e.key === 'Enter') {
			resultChecker()
		}
	}

	return (
		<div
			style={{
				height: '30em',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				textAlign: 'center'
			}}
			onKeyDown={onEnter}
		>
			<ModalHeader tag="h2">{t('CountTestPage_header')} </ModalHeader>
			<ModalBody>
				<Row>
					<Col sm={8}>
						<p
							className="h1"
							style={{
								fontSize: '4em',
								margin: 0
							}}
						>
							{`${firstExpression.firstNumber} + ${firstExpression.secondNumber} =`}
						</p>
					</Col>
					<Col sm={4} className="text-left">
						<Input
							className="text-center"
							name={'first expression'}
							type={'number'}
							bsSize="lg"
							style={{
								display: 'inline-block',
								width: '6em',
								height: '3.5em',
								marginRight: 3
							}}
							onChange={(e) => setFirstCount(Number(e.target.value))}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={8}>
						<p
							className="h1"
							style={{
								fontSize: '4em',
								margin: 0
							}}
						>{`${secondExpression.firstNumber} * ${secondExpression.secondNumber} =`}</p>
					</Col>
					<Col sm={4} className="text-left">
						<Input
							className="text-center"
							name={'second expression'}
							type={'number'}
							bsSize="lg"
							style={{
								display: 'inline-block',
								width: '6em',
								height: '3.5em',
								marginRight: 3
							}}
							onChange={(e) => setSecondCount(Number(e.target.value))}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={8}>
						<p
							className="h1 "
							style={{
								fontSize: '4em',
								margin: 0
							}}
						>{`${thirdExpression.firstNumber} + ${thirdExpression.secondNumber} + ${thirdExpression.thirdNumber} =`}</p>
					</Col>
					<Col sm={4} className="text-left">
						<Input
							className="text-center"
							name={'third expression'}
							type={'number'}
							bsSize="lg"
							style={{
								display: 'inline-block',
								width: '6em',
								height: '3.5em',
								marginRight: 3
							}}
							onChange={(e) => setThirdCount(Number(e.target.value))}
						/>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col>
						<Button
							color="primary"
							style={{ width: '10em', height: '3em', fontSize: '1.25em' }}
							size="lg"
							onClick={resultChecker}
						>
							{t('CountTestPage_button')}
						</Button>
					</Col>
				</Row>
			</ModalBody>
		</div>
	)
}

export default CountTestPage
