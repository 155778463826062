import React from 'react'
import { Container, Spinner } from 'reactstrap'

const Loader = (props: { size?: 'sm' | 'lg' }) => (
	<Container
		fluid
		className={`${
			props.size === 'sm' ? 'm-2' : 'vh-50'
		} d-flex justify-content-center align-items-center`}
	>
		<Spinner color="primary" type="grow" />
	</Container>
)

export default Loader
