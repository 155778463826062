import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import { Balances, DividendsInfo } from 'types/financeOperation'
import FinanceOperation from 'types/financeOperation'
import { BalanceInfo, CalcBalances } from 'types/balance'
import { Founder } from 'types/founder'

export const loadFinanceOperationsRequest = createAction(
	types.LOAD_FINANCE_OPERATIONS_BY_MONTH_REQUEST
)
export const loadFinanceOperationsSuccess = createAction<Balances>(
	types.LOAD_FINANCE_OPERATIONS_BY_MONTH_SUCCESS
)
export const loadFinanceOperationsError = createAction(types.LOAD_FINANCE_OPERATIONS_BY_MONTH_ERROR)

export const loadFinanceOperationsByYearRequest = createAction(
	types.LOAD_FINANCE_OPERATIONS_BY_YEAR_REQUEST
)
export const loadFinanceOperationsByYearSuccess = createAction<FinanceOperation[]>(
	types.LOAD_FINANCE_OPERATIONS_BY_YEAR_SUCCESS
)
export const loadFinanceOperationsByYearError = createAction(
	types.LOAD_FINANCE_OPERATIONS_BY_YEAR_ERROR
)

export const createFinanceOperationRequest = createAction(types.CREATE_FINANCE_OPERATION_REQUEST)
export const createFinanceOperationSuccess = createAction<FinanceOperation>(
	types.CREATE_FINANCE_OPERATION_SUCCESS
)
export const createFinanceOperationError = createAction(types.CREATE_FINANCE_OPERATION_ERROR)

export const approveFinanceOperationRequest = createAction(types.APPROVE_FINANCE_OPERATION_REQUEST)
export const approveFinanceOperationSuccess = createAction<FinanceOperation>(
	types.APPROVE_FINANCE_OPERATION_SUCCESS
)
export const approveFinanceOperationError = createAction(types.APPROVE_FINANCE_OPERATION_ERROR)

export const rejectFinanceOperationRequest = createAction(types.REJECT_FINANCE_OPERATION_REQUEST)
export const rejectFinanceOperationSuccess = createAction<FinanceOperation>(
	types.REJECT_FINANCE_OPERATION_SUCCESS
)
export const rejectFinanceOperationError = createAction(types.REJECT_FINANCE_OPERATION_ERROR)

export const checkFinanceOperationRequest = createAction(types.CHECK_FINANCE_OPERATION_REQUEST)
export const checkFinanceOperationSuccess = createAction<FinanceOperation>(
	types.CHECK_FINANCE_OPERATION_SUCCESS
)
export const checkFinanceOperationError = createAction(types.CHECK_FINANCE_OPERATION_ERROR)

export const deleteFinanceOperationsRequest = createAction(types.DELETE_FINANCE_OPERATION_REQUEST)
export const deleteFinanceOperationsSuccess = createAction<FinanceOperation>(
	types.DELETE_FINANCE_OPERATION_SUCCESS
)
export const deleteFinanceOperationsError = createAction(types.DELETE_FINANCE_OPERATION_ERROR)

//expense file
export const uploadExpenseFileRequest = createAction(types.UPLOAD_EXPENSE_FILE_REQUEST)
export const uploadExpenseFileSuccess = createAction<FinanceOperation>(
	types.UPLOAD_EXPENSE_FILE_SUCCESS
)
export const uploadExpenseFileError = createAction(types.UPLOAD_EXPENSE_FILE_ERROR)

export const downloadExpenseFileRequest = createAction(types.DOWNLOAD_EXPENSE_FILE_REQUEST)
export const downloadExpenseFileSuccess = createAction(types.DOWNLOAD_EXPENSE_FILE_SUCCESS)
export const downloadExpenseFileError = createAction(types.DOWNLOAD_EXPENSE_FILE_ERROR)

export const deleteExpenseFileRequest = createAction(types.DELETE_EXPENSE_FILE_REQUEST)
export const deleteExpenseFileSuccess = createAction<FinanceOperation>(
	types.DELETE_EXPENSE_FILE_SUCCESS
)
export const deleteExpenseFileError = createAction(types.DELETE_EXPENSE_FILE_ERROR)

//balance
export const loadBalanceByMonthRequest = createAction(types.LOAD_BALANCE_BY_MONTH_REQUEST)
export const loadBalanceByMonthSuccess = createAction<BalanceInfo>(
	types.LOAD_BALANCE_BY_MONTH_SUCCESS
)
export const loadBalanceByMonthError = createAction(types.LOAD_BALANCE_BY_MONTH_ERROR)

export const loadCalcBalancesRequest = createAction(types.LOAD_CALC_BALANCES_REQUEST)
export const loadCalcBalancesSuccess = createAction<CalcBalances>(types.LOAD_CALC_BALANCES_SUCCESS)
export const loadCalcBalancesError = createAction(types.LOAD_CALC_BALANCES_ERROR)

export const createInterBalanceTransactionRequest = createAction(
	types.CREATE_INTER_BALANCE_TRANSACTION_REQUEST
)
export const createInterBalanceTransactionSuccess = createAction<BalanceInfo>(
	types.CREATE_INTER_BALANCE_TRANSACTION_SUCCESS
)
export const createInterBalanceTransactionError = createAction(
	types.CREATE_INTER_BALANCE_TRANSACTION_ERROR
)

//dividends
export const loadDividendsRequest = createAction(types.LOAD_DIVIDENDS_REQUEST)
export const loadDividendsSuccess = createAction<DividendsInfo>(types.LOAD_DIVIDENDS_SUCCESS)
export const loadDividendsError = createAction(types.LOAD_DIVIDENDS_ERROR)

export const addDividendsRequest = createAction(types.WITHDRAW_DIVIDENDS_REQUEST)
export const addDividendsSuccess = createAction<DividendsInfo>(types.WITHDRAW_DIVIDENDS_SUCCESS)
export const addDividendsError = createAction(types.WITHDRAW_DIVIDENDS_ERROR)

export const distributeFoundersDividendsRequest = createAction(
	types.DISTRIBUTE_FOUNDERS_DIVIDENDS_REQUEST
)
export const distributeFoundersDividendsSuccess = createAction<Founder[]>(
	types.DISTRIBUTE_FOUNDERS_DIVIDENDS_SUCCESS
)
export const distributeFoundersDividendsError = createAction(
	types.DISTRIBUTE_FOUNDERS_DIVIDENDS_ERROR
)
