import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Vacancy } from 'types/recruiting/vacancy'

export const loadVacanciesRequest = createAction(types.LOAD_VACANCIES_REQUEST)
export const loadVacanciesSuccess = createAction<Vacancy[]>(types.LOAD_VACANCIES_SUCCESS)
export const loadVacanciesError = createAction(types.LOAD_VACANCIES_ERROR)

export const createVacancyRequest = createAction(types.CREATE_VACANCY_REQUEST)
export const createVacancySuccess = createAction<Vacancy>(types.CREATE_VACANCY_SUCCESS)
export const createVacancyError = createAction(types.CREATE_VACANCY_ERROR)

export const uploadVacancyFileRequest = createAction(types.UPLOAD_VACANCY_FILE_REQUEST)
export const uploadVacancyFileSuccess = createAction<Vacancy>(types.UPLOAD_VACANCY_FILE_SUCCESS)
export const uploadVacancyFileError = createAction(types.UPLOAD_VACANCY_FILE_ERROR)

export const archiveVacancyRequest = createAction(types.ARCHIVE_VACANCY_REQUEST)
export const archiveVacancySuccess = createAction<Vacancy>(types.ARCHIVE_VACANCY_SUCCESS)
export const archiveVacancyError = createAction(types.ARCHIVE_VACANCY_ERROR)
