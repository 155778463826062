import React from 'react'
import { connect, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toggleSidebar } from '../redux/actions/interfaceSettingsActions'
import {
	Navbar,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Collapse,
	Nav,
	Form,
	Input
} from 'reactstrap'

import { clearUser } from 'redux/actions/userActions'
import { StoreType } from 'redux/store'
import { UserState } from 'redux/reducers/userReducer'
import { setFilterText } from 'redux/actions/filterActions'
import NavbarLanguages from './NavbarLanguages'
import { transliterate } from 'transliteration'
import selectors from 'redux/selectors'
import Employee from 'types/employee'
import avatar from '../assets/img/avatars/pngwing.png'
import { GOOGLE_DRIVE_URL } from 'pages/dashboards/Projects/ProjectPage/Cards/ConceptArtCard'
import AdminPanel from './AdminPanel'

const NavbarComponent = (props: { dispatch; user: UserState }) => {
	const employee: Employee = useSelector(selectors.getUser).employee

	const signout = () => {
		props.dispatch(clearUser())
	}

	const { t, i18n } = useTranslation()

	return (
		<Navbar color="white" light expand>
			<span
				className="sidebar-toggle d-flex mr-2"
				onClick={() => props.dispatch(toggleSidebar())}
			>
				<i className="hamburger align-self-center" />
			</span>

			<Form inline>
				<Input
					type="text"
					placeholder={t('main_search')}
					aria-label="Search"
					className="form-control-no-border mr-sm-2"
					onChange={(e) => props.dispatch(setFilterText(e.target.value))}
				/>
			</Form>

			<Collapse navbar>
				<Nav className="ml-auto" navbar>
					<NavbarLanguages />
					<AdminPanel />
					<UncontrolledDropdown nav inNavbar>
						<div className="d-flex">
							<img
								src={GOOGLE_DRIVE_URL(employee.photo_id) ?? avatar}
								width="36"
								height="36"
								className="rounded-circle me-2"
								alt="Chris Wood"
							/>
							<DropdownToggle nav caret>
								<span className="text-dark">
									{i18n.language === 'en'
										? transliterate(
												props.user.employee.first_name +
													' ' +
													props.user.employee.last_name,
												{
													replace: [
														['Г', 'H'],
														['г', 'h']
													]
												}
										  )
										: props.user.employee.first_name +
										  ' ' +
										  props.user.employee.last_name}
								</span>
							</DropdownToggle>
						</div>
						<DropdownMenu right>
							<DropdownItem onClick={signout}>{t('exit_button')}</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
			</Collapse>
		</Navbar>
	)
}

export default connect((store: StoreType) => ({
	app: store.app,
	user: store.user
}))(NavbarComponent)
