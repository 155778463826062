import React from 'react'

import Wrapper from '../components/Wrapper'
import Sidebar from '../components/Sidebar'
import Main from '../components/Main'
import Navbar from '../components/Navbar'
import Content from '../components/Content'

const Dashboard = ({ children }: any) => {
	return (
		<Wrapper>
			<Sidebar />
			<Main>
				<Navbar />
				<Content>{children}</Content>
			</Main>
		</Wrapper>
	)
}

export default Dashboard
