import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import operations from 'redux/operations'

import { FaTrash } from 'react-icons/fa'

import { PositionsEnum } from 'types/positions'
import ProjectArtInfo from 'types/project_art_info'

import ModalView from 'components/ModalView'
import PositionWrapper from 'components/PositionWrapper'

const DeleteConceptArtModal = (props: { projectArtInfo: ProjectArtInfo }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const deleteArt = () => {
		dispatch(
			operations.dataOperations.conceptArts.deleteConceptArtFilesFunc(
				props.projectArtInfo._id
			)
		)
		return true
	}

	if (!props.projectArtInfo || props.projectArtInfo.concept_art.length === 0) {
		return null
	}

	return (
		<PositionWrapper requared_positions={[PositionsEnum.technical_art_director]}>
			<ModalView
				title="DeleteConceptArtModal_title"
				buttonText="DeleteConceptArtModal_buttonText"
				buttonColor="danger"
				buttonSmall
				buttonIcon={FaTrash}
				buttonOutline
				onAccept={deleteArt}
				acceptButtonText="DeleteConceptArtModal_acceptButtonText"
				acceptButtonColor="danger"
				rejectButtonText="DeleteConceptArtModal_rejectButtonText"
				children={<>{t('DeleteConceptArtModal_question')}</>}
			/>
		</PositionWrapper>
	)
}

export default DeleteConceptArtModal
