import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	checkKPICard,
	createKPICard,
	deleteKPICard,
	doneKPICard,
	loadAllKPICards,
	loadEmployeeKPICards,
	loadTodayDeadlinedKPICards
} from 'utils/api.request'
import { ConditionUpdateData, KPICardType, KPICreationData } from 'types/kpiCard'

export const loadAllKPIFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.kpi.loadKPIRequest())
	try {
		const allKPI: KPICardType[] = await loadAllKPICards()

		return dispatch(actions.dataActions.kpi.loadKPISuccess(allKPI))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.kpi.loadKPIError())
	}
}

export const loadEmployeeKPIFunc = (employee_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.kpi.loadEmployeeKPIRequest())
	try {
		const employeeKPI: KPICardType[] = await loadEmployeeKPICards(employee_id)

		return dispatch(actions.dataActions.kpi.loadEmployeeKPISuccess(employeeKPI))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.kpi.loadEmployeeKPIError())
	}
}

export const loadTodayDedlinedKPIFunc = (day: string) => async (dispatch) => {
	dispatch(actions.dataActions.kpi.loadTodayDedlinedKPIRequest())
	try {
		const todayDedlinedKPI: KPICardType[] = await loadTodayDeadlinedKPICards(day)

		return dispatch(actions.dataActions.kpi.loadTodayDedlinedKPISuccess(todayDedlinedKPI))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.kpi.loadTodayDedlinedKPIError())
	}
}

export const createKPIFunc = (creationData: KPICreationData) => async (dispatch) => {
	dispatch(actions.dataActions.kpi.createKPIRequest())
	try {
		const createdKPI: KPICardType = await createKPICard(creationData)

		toastr.success('', t('reduxToastrSuccess_createKPI'))
		return dispatch(actions.dataActions.kpi.createKPISuccess(createdKPI))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.kpi.createKPIError())
	}
}

export const checkKPIFunc = (updateData: ConditionUpdateData) => async (dispatch) => {
	dispatch(actions.dataActions.kpi.checkKPIRequest())
	try {
		const createdKPI: KPICardType = await checkKPICard(updateData)

		toastr.success('', t('reduxToastrSuccess_checkKPI'))
		return dispatch(actions.dataActions.kpi.checkKPISuccess(createdKPI))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.kpi.checkKPIError())
	}
}

export const doneKPIFunc = (updateData: ConditionUpdateData) => async (dispatch) => {
	dispatch(actions.dataActions.kpi.doneKPIRequest())
	try {
		const createdKPI: KPICardType = await doneKPICard(updateData)

		toastr.success('', t('reduxToastrSuccess_doneKPI'))
		return dispatch(actions.dataActions.kpi.doneKPISuccess(createdKPI))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.kpi.doneKPIError())
	}
}

export const deleteKPIFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.kpi.deleteKPIRequest())
	try {
		const createdKPI: KPICardType = await deleteKPICard(id)

		toastr.success('', t('reduxToastrSuccess_deleteKPI'))
		return dispatch(actions.dataActions.kpi.deleteKPISuccess(createdKPI))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.kpi.deleteKPIError())
	}
}
