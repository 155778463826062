import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import { archiveVacancy, createVacancy, loadVacancies, uploadVacancyFile } from 'utils/api.request'
import {
	Vacancy,
	VacancyCreationData,
	VacancyFileData,
	VacancyFileDataBeforeCreation
} from 'types/recruiting/vacancy'

export const loadVacanciesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.loadVacanciesRequest())
	try {
		const allVacancies: Vacancy[] = await loadVacancies()

		return dispatch(actions.dataActions.vacancies.loadVacanciesSuccess(allVacancies))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.loadVacanciesError())
	}
}

export const createVacancyFunc =
	(creationData: VacancyCreationData, file?: VacancyFileDataBeforeCreation) =>
	async (dispatch) => {
		dispatch(actions.dataActions.vacancies.createVacancyRequest())
		try {
			let newVacancy: Vacancy = await createVacancy(creationData)

			if (file) {
				const fileData: VacancyFileData = {
					...file,
					vacancy_id: newVacancy._id
				}

				newVacancy = await uploadVacancyFile(fileData)
			}

			toastr.success('', t('reduxToastrSuccess_createVacancy'))
			return dispatch(actions.dataActions.vacancies.createVacancySuccess(newVacancy))
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.vacancies.createVacancyError())
		}
	}

export const uploadVacancyFileFunc = (file: VacancyFileData) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.uploadVacancyFileRequest())
	try {
		let updatedVacancy: Vacancy = await uploadVacancyFile(file)

		//toastr.success('', t('reduxToastrSuccess_uploadVacancyFile'))
		return dispatch(actions.dataActions.vacancies.uploadVacancyFileSuccess(updatedVacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.uploadVacancyFileError())
	}
}

export const archiveVacancyFunc = (candidate_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacancies.archiveVacancyRequest())
	try {
		const archivedVacancy: Vacancy = await archiveVacancy(candidate_id)

		toastr.success('', t('reduxToastrSuccess_archiveVacancy'))
		return dispatch(actions.dataActions.vacancies.archiveVacancySuccess(archivedVacancy))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacancies.archiveVacancyError())
	}
}
