import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Funnel } from 'types/recruiting/funnel'

export const loadFunnelsRequest = createAction(types.LOAD_FUNNELS_REQUEST)
export const loadFunnelsSuccess = createAction<Funnel[]>(types.LOAD_FUNNELS_SUCCESS)
export const loadFunnelsError = createAction(types.LOAD_FUNNELS_ERROR)

export const createFunnelRequest = createAction(types.CREATE_FUNNEL_REQUEST)
export const createFunnelSuccess = createAction<Funnel>(types.CREATE_FUNNEL_SUCCESS)
export const createFunnelError = createAction(types.CREATE_FUNNEL_ERROR)

export const updateFunnelRequest = createAction(types.UPDATE_FUNNEL_REQUEST)
export const updateFunnelSuccess = createAction<Funnel>(types.UPDATE_FUNNEL_SUCCESS)
export const updateFunnelError = createAction(types.UPDATE_FUNNEL_ERROR)

export const archiveFunnelRequest = createAction(types.ARCHIVE_FUNNEL_REQUEST)
export const archiveFunnelSuccess = createAction<Funnel>(types.ARCHIVE_FUNNEL_SUCCESS)
export const archiveFunnelError = createAction(types.ARCHIVE_FUNNEL_ERROR)
