import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	Party,
	PartyCreationData,
	PartyMemberStatusEnum,
	PartyUpdatingData,
	UpdateMemberStatusDto
} from 'types/party'
import {
	confirmParty,
	createParty,
	deleteParty,
	doneParty,
	loadMemberParties,
	loadParties,
	updateParty,
	updatePartyMemberStatus
} from 'utils/api.request'

export const loadPartiesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.parties.loadPartiesRequest())
	try {
		const loadedParties: Party[] = await loadParties()

		return dispatch(actions.dataActions.parties.loadPartiesSuccess(loadedParties))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.parties.loadPartiesError())
	}
}

export const loadMemberPartiesFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.parties.loadMemberPartiesRequest())
	try {
		const loadedParties: Party[] = await loadMemberParties(id)

		return dispatch(actions.dataActions.parties.loadMemberPartiesSuccess(loadedParties))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.parties.loadMemberPartiesError())
	}
}

export const createPartyFunc = (creationData: PartyCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.parties.createPartyRequest())
	try {
		const createdParty: Party = await createParty(creationData)

		toastr.success('', t('reduxToastrSuccess_createParty'))
		return dispatch(actions.dataActions.parties.createPartySuccess(createdParty))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.parties.createPartyError())
	}
}

export const confirmPartyFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.parties.confirmPartyRequest())
	try {
		const confirmedParty: Party = await confirmParty(id)

		toastr.success('', t('reduxToastrSuccess_confirmParty'))
		return dispatch(actions.dataActions.parties.confirmPartySuccess(confirmedParty))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.parties.confirmPartyError())
	}
}

export const donePartyFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.parties.donePartyRequest())
	try {
		const confirmedParty: Party = await doneParty(id)

		toastr.success('', t('reduxToastrSuccess_doneParty'))
		return dispatch(actions.dataActions.parties.donePartySuccess(confirmedParty))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.parties.donePartyError())
	}
}

export const updatePartyFunc = (updatingData: PartyUpdatingData) => async (dispatch) => {
	dispatch(actions.dataActions.parties.updatePartyRequest())
	try {
		const updatedParty: Party = await updateParty(updatingData)

		toastr.success('', t('reduxToastrSuccess_updateParty'))
		return dispatch(actions.dataActions.parties.updatePartySuccess(updatedParty))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.parties.updatePartyError())
	}
}

export const sendInvitationAnswerFunc =
	(updatingData: UpdateMemberStatusDto) => async (dispatch) => {
		dispatch(actions.dataActions.parties.sendInvitationAnswerRequest())
		try {
			const updatedParty: Party = await updatePartyMemberStatus(updatingData)
			toastr.success(
				'',
				t(
					updatingData.status === PartyMemberStatusEnum.agreed
						? 'reduxToastrSuccess_agreedPartyMember'
						: 'reduxToastrSuccess_disagreedPartyMember'
				)
			)
			return dispatch(actions.dataActions.parties.sendInvitationAnswerSuccess(updatedParty))
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.parties.sendInvitationAnswerError())
		}
	}

export const deletePartyFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.parties.deletePartyRequest())
	try {
		const deletedParty: Party = await deleteParty(id)

		toastr.success('', t('reduxToastrSuccess_deleteParty'))
		return dispatch(actions.dataActions.parties.deletePartySuccess(deletedParty))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.parties.deletePartyError())
	}
}
