import {
	forceCalculateCompanyStat,
	forceCalculateEmployeeStat,
	getCompanyStat,
	getEmployeesStatistic,
	getEmployeeStatistic
} from 'utils/api.request'
import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { CompanyStatistics, EmployeeStatisticsByYear } from 'types/statistics'

export const loadEmployeesStatisticByYearFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.statistics.loadEmployeesYearStatRequest())
	try {
		const employeeYearStat: EmployeeStatisticsByYear[] = await getEmployeesStatistic()
		return dispatch(
			actions.dataActions.statistics.loadEmployeesYearStatSuccess(employeeYearStat)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.statistics.loadEmployeesYearStatError())
	}
}

export const loadEmployeeStatisticByYearFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.statistics.loadEmployeeYearStatRequest())
	try {
		const employeeYearStat: EmployeeStatisticsByYear[] = await getEmployeeStatistic(id)
		return dispatch(
			actions.dataActions.statistics.loadEmployeeYearStatSuccess(employeeYearStat)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.statistics.loadEmployeeYearStatError())
	}
}

export const loadCompanyStatisticFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.statistics.loadCompanyStatRequest())
	try {
		const companyStatistic: CompanyStatistics[] = await getCompanyStat()
		return dispatch(actions.dataActions.statistics.loadCompanyStatSuccess(companyStatistic))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.statistics.loadCompanyStatError())
	}
}

export const forceCalculateCompanyStatisticFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.statistics.forceCompanyStatRequest())
	try {
		const companyStatistic: boolean = await forceCalculateCompanyStat()
		return dispatch(actions.dataActions.statistics.forceCompanyStatSuccess(companyStatistic))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.statistics.forceCompanyStatError())
	}
}

export const forceCalculateEmployeeStatisticFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.statistics.forceEmployeeStatRequest())
	try {
		const employeeStatistic: EmployeeStatisticsByYear[] = await forceCalculateEmployeeStat()
		toastr.warning(
			'',
			'Результат перерахунку буде доступний через 3-5 хвилин після перезавантаження сторінки'
		)
		return dispatch(actions.dataActions.statistics.forceEmployeeStatSuccess(employeeStatistic))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.statistics.forceEmployeeStatError())
	}
}
