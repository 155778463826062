import {
	archivePublisher,
	createPublisher,
	getPublisher,
	getPublishers,
	getShortPublishers
} from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import Publisher, { PublisherCreationData } from 'types/publisher'

export const loadPublishersFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.publishers.loadPublishersRequest())
	try {
		const loadedPublishers: Publisher[] = await getPublishers()
		return dispatch(actions.dataActions.publishers.loadPublishersSuccess(loadedPublishers))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.publishers.loadPublishersError())
	}
}

export const getPublisherFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.publishers.getPublisherRequest())
	try {
		const loadedPublisher: Publisher = await getPublisher(id)
		return dispatch(actions.dataActions.publishers.getPublisherSuccess(loadedPublisher))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.publishers.getPublisherError())
	}
}

export const loadShortPublishersFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.publishers.loadShortPublishersRequest())
	try {
		const loadedPublishers: Publisher[] = await getShortPublishers()
		return dispatch(actions.dataActions.publishers.loadShortPublishersSuccess(loadedPublishers))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.publishers.loadShortPublishersError())
	}
}

export const addPublisherFunc = (creationData: PublisherCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.publishers.addPublisherRequest())
	try {
		const addedPublisher: Publisher = await createPublisher(creationData)
		toastr.success('', t('reduxToastrSuccess_publisherCreated', { name: addedPublisher.name }))
		return dispatch(actions.dataActions.publishers.addPublisherSuccess(addedPublisher))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.publishers.addPublisherError())
	}
}

export const archivePublisherFunc = (publisher_id) => async (dispatch) => {
	dispatch(actions.dataActions.publishers.archivePublisherRequest())
	try {
		const archivedPublisher: Publisher = await archivePublisher(publisher_id)

		toastr.success('', t('reduxToastrSuccess_publisherArchived'))

		return dispatch(actions.dataActions.publishers.archivePublisherSuccess(archivedPublisher))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.publishers.archivePublisherError())
	}
}
