import { combineReducers } from 'redux'
import recruiting from './recruiting'
import finances from './finances'
import by_collections from './byCollections'

export default combineReducers({
	by_collections,
	finances,
	recruiting
})
