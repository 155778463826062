import React, { Component } from 'react'
import Loader from './Loader'

export default function asyncComponent(importComponent: any) {
	class AsyncComponent extends Component<any, any> {
		constructor(props: any) {
			super(props)

			this.state = {
				component: null
			}
		}

		async componentDidMount() {
			const { default: component } = await importComponent()

			this.setState({
				component: component
			})
		}

		render() {
			const { component: C } = this.state

			if (!C) {
				return <Loader />
			}

			return <C {...this.props} />
		}
	}

	return AsyncComponent
}
