import { combineReducers } from 'redux'

import interface_settings from './interfaceSettingsReducer'
import layout from './layoutReducer'
import theme from './themeReducer'
import data from './data'
import user from './userReducer'
import filter from './filterReducer'

import { reducer as toastr } from 'react-redux-toastr'

export default combineReducers({
	interface_settings,
	layout,
	theme,
	data,
	user,
	filter,
	toastr
})
