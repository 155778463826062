import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { dashboard as dashboardRoutes, RouteItem } from './index'
import { Helmet } from 'react-helmet'
import selectors from 'redux/selectors'

import DashboardLayout from '../layouts/Dashboard'

import Loader from 'components/Loader'
import ScrollToTop from '../components/ScrollToTop'

import { RolesEnum } from 'types/roles'

import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import asyncComponent from '../components/Async'

const SignIn = asyncComponent(() => import('../pages/auth/SignIn'))

const ChildRoutes = () => {
	const { t, i18n } = useTranslation()
	const user_roles = useSelector(selectors.getUserRoles)

	return (
		<Switch>
			<Suspense fallback={<Loader />}>
				{dashboardRoutes.map((routeItem: RouteItem, index) => {
					let hasRequiredRole =
						!routeItem.requiredRoles ||
						routeItem.requiredRoles?.some((r: RolesEnum) => user_roles?.includes(r)) ||
						user_roles?.includes(RolesEnum.admin)

					if (
						routeItem.requiredRoles?.includes(RolesEnum.founder) &&
						!user_roles.includes(RolesEnum.founder)
					) {
						hasRequiredRole = false
					}

					if (!hasRequiredRole) return null /* (
							<Redirect
								key={index}
								to={{
									pathname: '/dashboard/default'
								}}
							/>
						) */

					return routeItem.children ? (
						// Route item with children
						routeItem.children.map((childrenRouteItem: RouteItem, index) => {
							let hasChildredRequiredRole =
								!childrenRouteItem.requiredRoles ||
								childrenRouteItem.requiredRoles?.some((r: RolesEnum) =>
									user_roles?.includes(r)
								) ||
								user_roles?.includes(RolesEnum.admin)

							if (
								childrenRouteItem.requiredRoles?.includes(RolesEnum.founder) &&
								!user_roles.includes(RolesEnum.founder)
							) {
								hasChildredRequiredRole = false
							}

							if (!hasChildredRequiredRole) return null /* (
									<Redirect
										key={index}
										to={{
											pathname: '/dashboard/default'
										}}
									/>
								) */

							return (
								<Route
									key={index}
									path={childrenRouteItem.path}
									exact
									render={(props) => (
										<DashboardLayout>
											<ScrollToTop>
												<Helmet>
													<title>
														{i18n.exists(childrenRouteItem.name)
															? t(childrenRouteItem.name)
															: childrenRouteItem.name}
													</title>
												</Helmet>
												<childrenRouteItem.component {...props} />
											</ScrollToTop>
										</DashboardLayout>
									)}
								/>
							)
						})
					) : (
						// Route item without children
						<Route
							key={index}
							path={routeItem.path}
							exact
							render={(props) => (
								<DashboardLayout>
									<ScrollToTop>
										<Helmet>
											<title>
												{i18n.exists(routeItem.name)
													? t(routeItem.name)
													: routeItem.name}
											</title>
										</Helmet>
										<routeItem.component {...props} />
									</ScrollToTop>
								</DashboardLayout>
							)}
						/>
					)
				})}
			</Suspense>
		</Switch>
	)
}

const Routes = () => {
	useSelector(selectors.getIsAuthenticated)
	const isAuthenticated = !!localStorage.getItem('access_token')

	return (
		<Router>
			<Suspense fallback={<Loader />}>
				<Switch>
					<PublicRoute path="/auth/sign-in" isAuthenticated={isAuthenticated}>
						<SignIn />
					</PublicRoute>

					<PrivateRoute path="/" isAuthenticated={isAuthenticated}>
						<ChildRoutes />
					</PrivateRoute>
				</Switch>
			</Suspense>
		</Router>
	)
}

export default Routes
