import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'

import Routes from './routes/Routes'

import { Helmet } from 'react-helmet'
import operations from 'redux/operations'

const App = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(operations.userOperations.auth.fetchUserFunc())
	}, [dispatch])

	return (
		<>
			<Helmet>
				<title>{t('ASYA')}</title>
			</Helmet>

			<Routes />

			<ReduxToastr
				timeOut={5000}
				newestOnTop={true}
				position="top-right"
				transitionIn="fadeIn"
				transitionOut="fadeOut"
				progressBar
				closeOnToastrClick
			/>
		</>
	)
}

export default App
