import { ShortEmployee } from './employee'

export enum IdeaStatusEnum {
	active = 'active',
	used = 'used',
	archived = 'archived'
}

export interface IdeaCreationData {
	date: Date
	is_idea: boolean
	producer: string
	authors: string[]
	title?: string
	description?: string
	status?: string
}

export interface IdeaAuthor {
	_id: string
	employee: ShortEmployee
}

export default interface Idea {
	_id: string
	date: Date
	is_idea: boolean
	producer: ShortEmployee
	authors: IdeaAuthor[]
	title?: string
	description?: string
	status?: IdeaStatusEnum
	end_date?: Date
}
