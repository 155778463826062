import async from '../components/Async'

import {
	Users as UsersIcon,
	PieChart as PieChartIcon,
	Inbox as InboxIcon,
	Book as BookIcon,
	Calendar as CalendarIcon,
	Home as HomeIcon,
	Coffee as CoffeeIcon,
	//Clipboard as ClipboardIcon,
	DollarSign as DollarSignIcon,
	Icon,
	UserCheck
} from 'react-feather'

// Misc
import Blank from '../pages/misc/Blank'
import { RolesEnum } from 'types/roles'

// Auth
const SignIn = async(() => import('../pages/auth/SignIn'))
const SignUp = async(() => import('../pages/auth/SignUp'))
const ResetPassword = async(() => import('../pages/auth/ResetPassword'))
const Page404 = async(() => import('../pages/auth/Page404'))
const Page500 = async(() => import('../pages/auth/Page500'))

// Dashboards
const RedirectComponent = async(() => import('./Redirect'))
const MyPage = async(() => import('../pages/dashboards/HR/Employees/MyPage'))
const PublisherPage = async(() => import('../pages/other/Publishers/PublisherPage'))
const EmployeePage = async(() => import('../pages/dashboards/HR/Employees/EmployeePage'))
const SurveyPage = async(() => import('../pages/other/Survey'))
const ConceptPage = async(() => import('../pages/dashboards/Concepts/ConceptPage'))
const ProjectPage = async(() => import('../pages/dashboards/Projects/ProjectPage'))
const SurveyGraphs = async(() => import('../pages/other/SurveyTable/SurveyGraphs'))

const Projects = async(() => import('../pages/dashboards/Projects'))
const GanttChart = async(() => import('../pages/dashboards/GanttChart'))
const EmployeesStatistic = async(() => import('../pages/dashboards/Statistic/EmployeesStatistic'))
const Developers = async(() => import('../pages/dashboards/ArtistsAndDevelopers/Developers'))
const Artists = async(() => import('../pages/dashboards/ArtistsAndDevelopers/Artists'))
const OtherPositions = async(
	() => import('../pages/dashboards/ArtistsAndDevelopers/OtherPositions')
)
const Ideation = async(() => import('../pages/dashboards/Ideation'))
const CompanyStatistics = async(() => import('../pages/dashboards/Statistic/CompanyStatistic'))

const StartDayTimeTracking = async(() => import('pages/dashboards/HR/TimeTracking/TimeTracking'))
const Publishers = async(() => import('../pages/other/Publishers'))
//const SurveyTable = async(() => import('../pages/other/SurveyTable'))

//Recruiting
//const Recruitment = async(() => import('../pages/dashboards/Recruitment'))
const Candidates = async(() => import('../pages/dashboards/Recruitment/Candidates'))
const Vacancies = async(() => import('../pages/dashboards/Recruitment/Vacancies'))

const Employees = async(() => import('../pages/dashboards/HR/Employees'))
const Vacation = async(() => import('../pages/dashboards/HR/Vacation'))
const Sicknesses = async(() => import('../pages/dashboards/HR/Sicknesses'))
const Parties = async(() => import('../pages/dashboards/HR/Parties'))
const TimeTrackingPage = async(() => import('../pages/dashboards/HR/TimeTracking'))
const Todolists = async(() => import('../pages/dashboards/HR/Todolists'))
const KPI = async(() => import('../pages/dashboards/HR/KPI'))

const Balance = async(() => import('../pages/dashboards/Finance/Balance'))
const Invoices = async(() => import('../pages/dashboards/Finance/Invoices'))
const Expenses = async(() => import('../pages/dashboards/Finance/Expenses'))
const Payback = async(() => import('../pages/dashboards/Finance/Payback'))
const Dividends = async(() => import('../pages/dashboards/Finance/Dividends'))
const Salaries = async(() => import('../pages/dashboards/Finance/Salaries'))

const SystemSettings = async(() => import('../pages/dashboards/SystemSettings'))

export type RouteItem = {
	path: string
	name: string
	icon?: Icon
	containsHome?: boolean
	component?: any
	isPrivate?: boolean
	badgeColor?: string
	badgeText?: string
	requiredRoles?: RolesEnum[]
	children?: RouteItem[]
	noRender?: boolean
}

// Routes
const routes: RouteItem[] = [
	{
		path: '/',
		name: 'redirect',
		icon: HomeIcon,
		containsHome: false,
		component: RedirectComponent,
		noRender: true
	},
	{
		path: '/dashboard/default',
		name: 'sideMenu_mainLabel',
		icon: HomeIcon,
		containsHome: true,
		component: MyPage
	},
	{
		path: '/dashboard/projects',
		name: 'sideMenu_projectsLabel',
		icon: InboxIcon,
		containsHome: true,
		component: Projects,
		requiredRoles: [RolesEnum.base]
	},
	{
		path: '/dashboard/ganttChart',
		name: 'sideMenu_gantChart',
		icon: CalendarIcon,
		containsHome: true,
		component: GanttChart,
		requiredRoles: [RolesEnum.management, RolesEnum.qa, RolesEnum.hr, RolesEnum.gantt_chart]
	},
	{
		path: '/dashboard/recruiting',
		name: 'sideMenu_recruiting',
		icon: UserCheck,
		containsHome: true,
		requiredRoles: [RolesEnum.hr, RolesEnum.recruiting, RolesEnum.management],
		children: [
			{
				path: '/dashboard/recruiting',
				name: 'sideMenu_candidates',
				component: Candidates,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr, RolesEnum.recruiting, RolesEnum.management]
			},
			{
				path: '/dashboard/recruiting/vacancies',
				name: 'sideMenu_vacancies',
				component: Vacancies,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr, RolesEnum.recruiting, RolesEnum.management]
			}
		]
	},
	{
		path: '/other/HR',
		name: 'sideMenu_HR',
		icon: UsersIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [RolesEnum.hr, RolesEnum.management, RolesEnum.co_lead],
		children: [
			{
				path: '/other/HR',
				name: 'sideMenu_employees',
				component: Employees,
				isPrivate: true,
				requiredRoles: [RolesEnum.project_manager, RolesEnum.hr, RolesEnum.employees]
			},
			{
				path: '/other/HR/vacations',
				name: 'sideMenu_vacations',
				component: Vacation,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: '/other/HR/sicknesses',
				name: 'sideMenu_sicknesses',
				component: Sicknesses,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: '/other/HR/timeTracking',
				name: 'sideMenu_latenesses',
				component: TimeTrackingPage,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: '/other/HR/parties',
				name: 'sideMenu_parties',
				component: Parties,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: '/other/HR/todolists',
				name: 'sideMenu_todolists',
				component: Todolists,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr, RolesEnum.management, RolesEnum.co_lead]
			},
			{
				path: '/other/HR/KPI',
				name: 'sideMenu_kpi',
				component: KPI,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin]
			}
		]
	},
	{
		path: '/other/statistic',
		name: 'sideMenu_statistics',
		icon: PieChartIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.ratings],
		children: [
			{
				path: '/other/statistic/company',
				name: 'sideMenu_companyStatistic',
				component: CompanyStatistics,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin]
			},
			{
				path: '/other/statistic/employees',
				name: 'sideMenu_employeesStatistic',
				component: EmployeesStatistic,
				isPrivate: true,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.ratings]
			}
		]
	},
	{
		path: '/other/developers',
		name: 'sideMenu_artAndDev',
		icon: CoffeeIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [
			RolesEnum.tech_lead,
			RolesEnum.project_manager,
			RolesEnum.hr,
			RolesEnum.developers
		],
		children: [
			{
				path: '/other/developers',
				name: 'sideMenu_developers',
				component: Developers,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.tech_lead,
					RolesEnum.project_manager,
					RolesEnum.hr,
					RolesEnum.developers
				]
			},
			{
				path: '/other/developers/artists',
				name: 'sideMenu_artists',
				component: Artists,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.tech_lead,
					RolesEnum.project_manager,
					RolesEnum.hr,
					RolesEnum.developers
				]
			},
			{
				path: '/other/developers/other_positions',
				name: 'sideMenu_otherPositions',
				component: OtherPositions,
				isPrivate: true,
				requiredRoles: [RolesEnum.hr]
			},
			{
				path: '/other/developers/ideation',
				name: 'sideMenu_ideation',
				component: Ideation,
				isPrivate: true,
				requiredRoles: [
					RolesEnum.tech_lead,
					RolesEnum.project_manager,
					RolesEnum.hr,
					RolesEnum.developers
				]
			}
		]
	},
	{
		path: '/other/publishers',
		name: 'sideMenu_publishers',
		icon: BookIcon,
		component: Publishers,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [RolesEnum.project_manager]
	},
	/* {
		path: '/other/survey',
		name: 'sideMenu_surveys',
		icon: ClipboardIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.survey],
		children: [
			{
				path: '/other/survey',
				name: 'sideMenu_allSurveys',
				component: SurveyTable,
				isPrivate: true,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.survey]
			},
			{
				path: '/other/survey/company',
				name: 'sideMenu_companyStatistics',
				component: SurveyGraphs,
				isPrivate: true,
				requiredRoles: [RolesEnum.management, RolesEnum.hr, RolesEnum.survey]
			}
		]
	}, */
	{
		path: '/other/finances',
		name: 'sideMenu_finances',
		icon: DollarSignIcon,
		containsHome: true,
		isPrivate: true,
		requiredRoles: [RolesEnum.admin, RolesEnum.finances, RolesEnum.hr, RolesEnum.expenses],
		children: [
			{
				path: '/other/finances/invoices',
				name: 'sideMenu_invoices',
				component: Invoices,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin, RolesEnum.project_manager, RolesEnum.invoices]
			},
			{
				path: '/other/finances',
				name: 'sideMenu_balance',
				component: Balance,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin]
			},
			{
				path: '/other/finances/expenses',
				name: 'sideMenu_expenses',
				component: Expenses,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin, RolesEnum.hr, RolesEnum.expenses]
			},

			{
				path: '/other/finances/salaries',
				name: 'sideMenu_salaries',
				component: Salaries,
				isPrivate: true,
				requiredRoles: [RolesEnum.salary, RolesEnum.hr]
			},
			{
				path: '/other/finances/profitability',
				name: 'sideMenu_payback',
				component: Payback,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin, RolesEnum.finances]
			},
			{
				path: '/other/finances/dividends',
				name: 'sideMenu_dividends',
				component: Dividends,
				isPrivate: true,
				requiredRoles: [RolesEnum.admin, RolesEnum.founder]
			}
		]
	}
]

const authRoutes: RouteItem = {
	path: '/auth',
	name: 'Auth',
	icon: UsersIcon,
	badgeColor: 'secondary',
	badgeText: 'Special',
	children: [
		{
			path: '/auth/sign-in',
			name: 'Sign In',
			component: SignIn
		},
		{
			path: '/auth/sign-up',
			name: 'Sign Up',
			component: SignUp
		},
		{
			path: '/auth/reset-password',
			name: 'Reset Password',
			component: ResetPassword
		},
		{
			path: '/auth/404',
			name: '404 Page',
			component: Page404
		},
		{
			path: '/auth/500',
			name: '500 Page',
			component: Page500
		}
	]
}

// This route is not visisble in the sidebar
const privateRoutes: RouteItem = {
	path: '/private',
	name: 'Private',
	children: [
		{
			path: '/private/blank',
			name: 'privateRoutes_blankPage',
			component: Blank
		},
		{
			path: '/concept/:id',
			name: 'privateRoutes_сonceptPage',
			component: ConceptPage,
			isPrivate: true
		},
		{
			path: '/project/:id',
			name: 'privateRoutes_project Page',
			component: ProjectPage,
			isPrivate: true
		},
		{
			path: '/employee/:id',
			name: 'privateRoutes_еmployeePage',
			component: EmployeePage,
			isPrivate: true
		},
		{
			path: '/publisher/:id',
			name: 'privateRoutes_publisherPage',
			component: PublisherPage,
			isPrivate: true
		},
		{
			path: '/checkin',
			name: 'Checkin',
			component: StartDayTimeTracking,
			isPrivate: true
		},
		{
			path: '/survey',
			name: 'privateRoutes_survey',
			component: SurveyPage,
			isPrivate: true
		},
		{
			path: '/system_settings',
			name: 'privateRoutes_adminPanel',
			component: SystemSettings,
			isPrivate: true
		},
		{
			path: '/other/survey/:id',
			name: 'privateRoutes_surveyGraphs',
			component: SurveyGraphs,
			isPrivate: true
		}
	]
}

// Dashboard specific routes
export const dashboard = [...routes, privateRoutes]

// Auth specific routes
export const page = [authRoutes]

// All routes
export default routes
