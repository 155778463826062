import React from 'react'
import operations from 'redux/operations'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'
import { Spinner } from 'reactstrap'
import { Calendar } from 'react-feather'
import selectors from 'redux/selectors'

export enum MonthsOptions {
	twoMonths = 'twoMonths',
	threeMonths = 'threeMonths',
	sixMonths = 'sixMonths',
	oneYear = 'oneYear',
	allTime = 'allTime'
}

export enum Tables {
	in_testing = 'in_testing',
	tested = 'tested',
	surveys = 'surveys'
}

function ProjectsByMonthsSelector(props: { table: Tables }) {
	const months = {
		in_testing: useSelector(selectors.getInTestingProjectsMonth),
		tested: useSelector(selectors.getTestedProjectsMonth),
		surveys: useSelector(selectors.getProjectSurveysMonth)
	}

	const loadings = {
		in_testing: useSelector(selectors.getInTestingProjectsLoading),
		tested: useSelector(selectors.getTestedProjectsLoading),
		surveys: useSelector(selectors.getProjectSurveysLoading)
	}

	const { t } = useTranslation()
	const dispatch = useDispatch()

	const loader = (months: number, monthsOption: MonthsOptions) => {
		switch (props.table) {
			case Tables.in_testing:
				return dispatch(
					operations.dataOperations.projects.getInTestingProjectsFunc(
						months,
						monthsOption
					)
				)
			case Tables.tested:
				return dispatch(
					operations.dataOperations.projects.getTestedProjectsFunc(months, monthsOption)
				)
			case Tables.surveys:
				return dispatch(
					operations.dataOperations.surveys.loadProjectSurveysFunc(months, monthsOption)
				)

			default:
				return
		}
	}

	return loadings[props.table] ? (
		<Spinner color="primary" type="grow" />
	) : (
		<Dropdown className="d-inline me-2">
			<Dropdown.Toggle variant="light" className="bg-white shadow-sm">
				<Calendar className="feather align-middle pr-1" />
				{t(`ProjectsByMonthsSelector_${months[props.table]}`)}
			</Dropdown.Toggle>
			<Dropdown.Menu>
				<Dropdown.Item onClick={() => loader(2, MonthsOptions.twoMonths)}>
					{t('ProjectsByMonthsSelector_twoMonths')}
				</Dropdown.Item>
				<Dropdown.Item onClick={() => loader(3, MonthsOptions.threeMonths)}>
					{t('ProjectsByMonthsSelector_threeMonths')}
				</Dropdown.Item>
				<Dropdown.Item onClick={() => loader(6, MonthsOptions.sixMonths)}>
					{t('ProjectsByMonthsSelector_sixMonths')}
				</Dropdown.Item>
				<Dropdown.Item onClick={() => loader(12, MonthsOptions.oneYear)}>
					{t('ProjectsByMonthsSelector_oneYear')}
				</Dropdown.Item>
				<Dropdown.Item onClick={() => loader(24, MonthsOptions.allTime)}>
					{t('ProjectsByMonthsSelector_allTime')}
				</Dropdown.Item>
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default ProjectsByMonthsSelector
