import * as types from '../constants'

export type FilterState = {
	text: string
	enabled: boolean
	byPublishers: any[]
	employee: string
}

const initialState = {
	text: '',
	enabled: false,
	byPublishers: [],
	employee: ''
}

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.SET_FILTER_TEXT:
			return {
				...state,
				text: actions.payload.toLowerCase(),
				enabled: true
			}

		case types.SET_FILTER_BY_PUBLISHERS:
			return {
				...state,
				byPublishers: actions.payload,
				enabled: true
			}

		case types.CLEAR_FILTER:
			return {
				...state,
				text: '',
				enabled: false
			}

		default:
			return state
	}
}
