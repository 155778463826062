import React, { useEffect, useState } from 'react'
import DeviceDetector from 'device-detector-js'

import CountTestPage from './Modals/CountTestPage'
import FalseDevice from './Modals/FalseDevicePage'
import { Modal } from 'reactstrap'
import ContinueWorkPage from './Modals/ContinueWorkPage'
import actions from 'redux/actions'
import { useDispatch } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import { useTranslation } from 'react-i18next'

const TimeTracking = () => {
	const [devicePermission, setDevicePermission] = useState<boolean | null>(null)
	const [openTestPage, setOpenTestPage] = useState<boolean>(true)
	const [openContinue, setOpenContinue] = useState<boolean>(false)

	const { t } = useTranslation()
	const deviceDetector = new DeviceDetector()
	const device = deviceDetector.parse(navigator.userAgent)

	const dispatch = useDispatch()

	useEffect(() => {
		if (
			device.os?.name &&
			[
				'Windows',
				'Linux',
				'Mac',
				'Kubuntu',
				'GNU/Linux',
				'Lubuntu',
				'Debian',
				'Fedora',
				'Chrome OS',
				'Mint',
				'Xubuntu',
				'Ubuntu'
			].includes(device.os?.name) &&
			device.device?.type === 'desktop'
		) {
			setDevicePermission(true)
		} else {
			setDevicePermission(false)
			setOpenTestPage(true)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onSuccessCounting = () => {
		setOpenTestPage(false)
		setOpenContinue(true)
	}

	const onContinue = () => {
		dispatch(actions.userActions.workWithSystem())
		toastr.success('Success', t('reduxToastrSuccess_greetings'))
		setOpenContinue(false)
	}

	return (
		<div className="bluredContainer">
			<Modal isOpen={true} backdrop={'static'} toggle={onSuccessCounting} centered>
				{devicePermission === false && <FalseDevice />}
				{devicePermission && openTestPage && (
					<CountTestPage onSuccessCounting={onSuccessCounting} />
				)}
				{openContinue && <ContinueWorkPage onContinue={onContinue} />}
			</Modal>
		</div>
	)
}

export default TimeTracking
