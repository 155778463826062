import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap'
import { IconType } from 'react-icons/lib'
import { RolesEnum } from 'types/roles'
import RoleWrapper from './RoleWrapper'

interface ModalViewProps {
	className?: string

	title: any
	titleExtendet?: any
	children?: any
	size?: 'sm' | 'md' | 'lg' | 'xl'
	noLeftMargin?: boolean

	requaredRoles?: RolesEnum[]

	buttonText: string
	tooltipText?: string
	id?: string
	numberButtonSize?: number
	buttonSize?: 'sm' | 'md' | 'lg'
	buttonIcon?: IconType
	buttonColor?: 'primary' | 'success' | 'warning' | 'danger' | string
	buttonOutline?: boolean
	buttonSmall?: boolean
	buttonAsText?: boolean

	acceptButtonText?: string
	acceptButtonColor?: 'primary' | 'success' | 'warning' | 'danger' | string
	onAccept?: any
	deleteModal?: boolean
	clearFunction?: any

	rejectButtonText?: string
	onReject?: any
	rejectButtonColor?: 'primary' | 'success' | 'warning' | 'danger' | string

	disabled?: boolean
}

const ModalView = (props: ModalViewProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)

	const toggle = (e) => {
		e.persist()
		e.preventDefault()

		setIsOpen(!isOpen)
	}

	const { t, i18n } = useTranslation()

	const onAccept = async (e) => {
		if (!props.onAccept) {
			toggle(e)

			return
		}

		e.persist()
		e.preventDefault()

		const success = await props.onAccept()

		if (props.deleteModal) {
			return
		}

		if (success) {
			toggle(e)
		}
	}

	const onReject = async (e) => {
		if (!props.onReject) {
			toggle(e)

			return
		}

		e.persist()
		e.preventDefault()

		const success = await props.onReject()

		if (props.deleteModal) {
			return
		}

		if (success) {
			toggle(e)
		}
	}

	const modalView = (
		<>
			{props.tooltipText && (
				<UncontrolledTooltip
					placement="right"
					target={'Tooltip' + props.tooltipText + props.id ?? '0'}
				>
					{t(props.tooltipText)}
				</UncontrolledTooltip>
			)}

			{props.buttonAsText ? (
				<a
					href="#/"
					className={`m-1 mb-2 text-${props.buttonColor ?? 'primary'} ${props.className}`}
					id={'Tooltip' + props.tooltipText + props.id ?? '0'}
					onClick={(e) => toggle(e)}
				>
					{props.buttonIcon && (
						<props.buttonIcon
							className={props.buttonSmall ? '' : 'mr-2'}
							size={props.numberButtonSize}
						/>
					)}
					{!props.buttonSmall && t(props.buttonText)}
				</a>
			) : (
				<Button
					id={'Tooltip' + props.tooltipText + props.id ?? '0'}
					color={props.buttonColor ?? 'primary'}
					disabled={props.disabled}
					className={`shadow-sm mb-0 ${props.noLeftMargin ? '' : 'ml-2'} ${
						props.className
					}`}
					onClick={toggle}
					outline={props.buttonOutline}
					size={props.buttonSize}
				>
					{props.buttonIcon && (
						<props.buttonIcon
							className={props.buttonSmall ? '' : 'mr-2'}
							size={props.numberButtonSize}
						/>
					)}
					{!props.buttonSmall && t(props.buttonText)}
				</Button>
			)}

			<Modal
				isOpen={isOpen}
				toggle={toggle}
				centered
				size={props.size}
				onClosed={props.clearFunction}
			>
				<ModalHeader toggle={toggle}>
					{i18n.exists(props.title) ? t(props.title) : props.title}
					{props.titleExtendet}
				</ModalHeader>

				{props.children && <ModalBody className="m-1">{props.children}</ModalBody>}

				<ModalFooter>
					{props.rejectButtonText && (
						<Button
							color={`${props.rejectButtonColor ?? 'secondary'}`}
							onClick={onReject}
						>
							{t(props.rejectButtonText)}
						</Button>
					)}{' '}
					{props.acceptButtonText && (
						<Button
							color={`${props.acceptButtonColor ?? 'primary'}`}
							onClick={onAccept}
						>
							{t(props.acceptButtonText)}
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</>
	)

	if (props.requaredRoles)
		return <RoleWrapper requared_role={props.requaredRoles}>{modalView}</RoleWrapper>

	return <>{modalView}</>
}

export default ModalView
