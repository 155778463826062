import { createAction } from '@reduxjs/toolkit'
import TrainingProject from 'types/trraining.project'
import * as types from '../../constants'

export const loadTrainingProjectsRequest = createAction(types.LOAD_TRAINING_PROJECTS_REQUEST)
export const loadTrainingProjectsSuccess = createAction<TrainingProject[]>(
	types.LOAD_TRAINING_PROJECTS_SUCCESS
)
export const loadTrainingProjectsError = createAction(types.LOAD_TRAINING_PROJECTS_ERROR)

export const createTrainingProjectRequest = createAction(types.CREATE_TRAINING_PROJECT_REQUEST)
export const createTrainingProjectSuccess = createAction<TrainingProject>(
	types.CREATE_TRAINING_PROJECT_SUCCESS
)
export const createTrainingProjectError = createAction(types.CREATE_TRAINING_PROJECT_ERROR)

export const endTrainingProjectRequest = createAction(types.END_TRAINING_PROJECT_REQUEST)
export const endTrainingProjectSuccess = createAction<TrainingProject>(
	types.END_TRAINING_PROJECT_SUCCESS
)
export const endTrainingProjectError = createAction(types.END_TRAINING_PROJECT_ERROR)
