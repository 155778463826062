import { createAction } from '@reduxjs/toolkit'
import { CompanyStatistics, EmployeeStatisticsByYear } from 'types/statistics'
import * as types from '../../constants'

export const loadEmployeesYearStatRequest = createAction(
	types.LOAD_EMPLOYEES_YEAR_STATISTIC_REQUEST
)
export const loadEmployeesYearStatSuccess = createAction<EmployeeStatisticsByYear[]>(
	types.LOAD_EMPLOYEES_YEAR_STATISTIC_SUCCESS
)
export const loadEmployeesYearStatError = createAction(types.LOAD_EMPLOYEES_YEAR_STATISTIC_ERROR)

export const loadEmployeeYearStatRequest = createAction(types.LOAD_EMPLOYEE_YEAR_STATISTIC_REQUEST)
export const loadEmployeeYearStatSuccess = createAction<EmployeeStatisticsByYear[]>(
	types.LOAD_EMPLOYEE_YEAR_STATISTIC_SUCCESS
)
export const loadEmployeeYearStatError = createAction(types.LOAD_EMPLOYEE_YEAR_STATISTIC_ERROR)

export const loadCompanyStatRequest = createAction(types.LOAD_COMPANY_STAT_REQUEST)
export const loadCompanyStatSuccess = createAction<CompanyStatistics[]>(
	types.LOAD_COMPANY_STAT_SUCCESS
)
export const loadCompanyStatError = createAction(types.LOAD_COMPANY_STAT_ERROR)

export const forceCompanyStatRequest = createAction(types.FORCE_COMPANY_STAT_REQUEST)
export const forceCompanyStatSuccess = createAction<boolean>(types.FORCE_COMPANY_STAT_SUCCESS)
export const forceCompanyStatError = createAction(types.FORCE_EMPLOYEE_STAT_ERROR)

export const forceEmployeeStatRequest = createAction(types.FORCE_EMPLOYEE_STAT_REQUEST)
export const forceEmployeeStatSuccess = createAction<EmployeeStatisticsByYear[]>(
	types.FORCE_COMPANY_STAT_SUCCESS
)
export const forceEmployeeStatError = createAction(types.FORCE_EMPLOYEE_STAT_ERROR)
