import Concept, { ConceptCreationData, PinnedConceptData } from 'types/concept'
import Employee, {
	EmployeeArchiveData,
	EmployeeCreationData,
	EmployeeUpdatingData,
	PromotionUpdatingData
} from 'types/employee'

import Invoice, {
	AddProjectsData,
	InvoiceCreationData,
	PayInvoiceData,
	SendInvoiceData,
	UpdateInvoiceData
} from 'types/invoice'
import FinanceOperation, {
	Balances,
	DividendsInfo,
	FinanceOperationCheckData,
	FinanceOperationCreationData
} from 'types/financeOperation'

import Project, {
	PauseProjectData,
	ProjectCount,
	ProjectCreationData,
	ProjectResultData,
	ProjectUpdatingData,
	ProjectWithIteration,
	ReleasedProject,
	TechSetupData,
	UpdatedProjectSummData
} from 'types/project'
import ProjectArtInfo, { ArtDoneUpatingData, ArtSetupData } from 'types/project_art_info'
import Promotion, {
	AgreePromotionData,
	PromotionCreatingData,
	RejectPromotionData
} from 'types/promotion'
import Publisher, { PublisherCreationData } from 'types/publisher'
import Sickness, {
	SicknessCreationData,
	SicknessFileDeleteDto,
	SicknessUpdateData
} from 'types/sickness'
import { CompanyStatistics, EmployeeStatisticsByYear } from 'types/statistics'
import {
	NoSurvey,
	PartySurvey,
	ProjectSurvey,
	SendAnswersData,
	Survey,
	SurveyState
} from 'types/survey'
import TrainingProject, {
	TrainingProjectCreationData,
	TrainingProjectEndData
} from 'types/trraining.project'
import Vacation, {
	VacationCreationData,
	VacationLocedPeriodCreationData,
	VacationRejectingData,
	VacationUpdatingData
} from 'types/vacation'
import VacationLockedPeriods from 'types/vacation.locked.periods'
import WorkCheckin, { MonthEmployeeCheckinInfo } from 'types/workCheckin'
import api from './api'
import Visits, { ConceptVisitCreationData, ProjectVisitCreationData } from 'types/visitCheck'
import { BalanceInfo, CalcBalances, InterBalanceTransactionCreationData } from 'types/balance'
import Idea, { IdeaCreationData } from 'types/idea'
import { Party, PartyCreationData, PartyUpdatingData, UpdateMemberStatusDto } from 'types/party'
import { FetchUserStatus, LoginUserStatus } from 'types/user'
import FinanceCollection from 'types/finance'
import Todolist, { AddTodoData, UpdateTodoStatusData } from 'types/todolist'
import {
	AddSalaryChanges,
	CheckSalaryData,
	RemoveSalaryChanges,
	SalariesTable,
	SalaryPayUpdatingData,
	SalaryTableDollarRateData,
	SalaryTableWithdrawData,
	UpdateSalaryTableStatusData
} from 'types/salary'
import { SystemSettings } from 'types/systemSettings'
import { ConditionUpdateData, KPICardType, KPICreationData } from 'types/kpiCard'
import { DistributionData, Founder, WithdrawDividendsData } from 'types/founder'
import { Stage, StageCreationData, UpdateStagesIndexesData } from 'types/recruiting/stage'
import { Funnel, FunnelCreationData, FunnelUpdatingData } from 'types/recruiting/funnel'
import { Vacancy, VacancyFileData } from 'types/recruiting/vacancy'
import {
	Candidate,
	CandidateCreationData,
	CandidateUpdatingData,
	TakeOnVacancyCreationData
} from 'types/recruiting/candidate'
import { Application, ApplicationCreationData } from 'types/recruiting/application'
import {
	HiringHistoryItemCreationData,
	HiringHistoryItemDeletingData,
	HiringHistoryItemEditCommentData,
	HiringHistoryItemFileData,
	HiringItem
} from 'types/recruiting/hiring.item'
import { Department, DepartmentCreationData, DepartmentEditData } from 'types/recruiting/department'

const fileDownload = require('js-file-download')

// Server asya
const get = (url: string, params: any = null, timeout: any = null): Promise<any> =>
	api()
		.get(url, { params, timeout })
		.then((res) => res.data)

const getDownload = (url: string, params: any = null): Promise<any> =>
	api(true)
		.get(url, { params })
		.then((res) => {
			const contentDisposition = res.headers['content-disposition']
			const filenameRegex = /filename="?(.*?)"?(?:;|$)/
			const matches = contentDisposition.match(filenameRegex)
			const fileName = matches && matches[1] ? matches[1] : 'downloaded_file'

			fileDownload(res.data, fileName)
		})

const post = (url: string, data?: any, config?: any): Promise<any> =>
	api()
		.post(url, data, config)
		.then((res) => res.data)

const put = (url: string, data?: any): Promise<any> =>
	api()
		.put(url, data)
		.then((res) => res.data)

const remove = (url: string, data?: any): Promise<any> =>
	api()
		.delete(url, { data })
		.then((res) => res.data)
//# Auth

export const login = async (username: string, password: string): Promise<LoginUserStatus> =>
	post('/auth/login', { username, password })

export const fetchCurrentUser = async (): Promise<FetchUserStatus> => get('/auth/fetch')

//# Publishers

export const getPublisher = async (id: string): Promise<Publisher> => get(`/publishers/${id}`)

export const getPublishers = async (): Promise<Publisher[]> => get('/publishers/all')

export const getShortPublishers = async (): Promise<Publisher[]> => get('/publishers/all_short')

export const createPublisher = async (creationData: PublisherCreationData): Promise<Publisher> =>
	post('/publishers/create', creationData)

export const archivePublisher = async (publisher_id: string): Promise<Publisher> =>
	put(`/publishers/${publisher_id}/archive`)

//# Employees
export const getEmployee = async (id: string): Promise<Employee> => get(`/employees/${id}`)

export const getEmployees = async (): Promise<Employee[]> => get('/employees/all')

export const getShortEmployees = async (): Promise<Employee[]> => get('/employees/all_short')

export const registerEmployee = async (creationData: EmployeeCreationData): Promise<Employee> =>
	post('/employees/register', creationData)

export const archiveEmployee = async (archiveData: EmployeeArchiveData): Promise<Employee> =>
	put('/employees/archive', archiveData)

export const sendEmployeeInfo = async (updatingData: EmployeeUpdatingData): Promise<Employee> =>
	put('/employees/add_info', updatingData)

export const updatePromotionInfo = async (updatingData: PromotionUpdatingData): Promise<Employee> =>
	put('/employees/update_employee_info', updatingData)

export const uploadProfilePhoto = async (file: File): Promise<Employee> => {
	let fd = new FormData()
	const fileName = file.name
	fd.append('file', file, fileName)
	return post('/employees/upload_profile_photo', fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}

export const deleteProfilePhoto = async (id: string): Promise<Employee> =>
	remove(`/employees/${id}/delete_profile_photo`)

//# Projects
export const getProject = async (id: string): Promise<Project> => get(`/projects/${id}`)

export const getMainProjects = async (): Promise<Project[]> => get('/projects/all_main')

export const getAllProjects = async (): Promise<Project[]> => get('/projects/all_projects')

export const getGantChartProjects = async (): Promise<Project[]> => get('/projects/all_gant_chart')

export const getShortProjects = async (): Promise<Project[]> => get('/projects/all_short_projects')

export const getPublisherProjects = async (id: string): Promise<Project[]> =>
	get(`/projects/${id}/publisher_projects`)

export const getEmployeeProjects = async (id: string): Promise<Project[]> =>
	get(`/projects/${id}/all`)

export const getInTestingProjects = async (months: number): Promise<Project[]> =>
	get(`/projects/${months}/in_testing`)

export const getTestedProjects = async (months: number): Promise<Project[]> =>
	get(`/projects/${months}/tested`)

export const getDevelopersProjectCount = async (): Promise<ProjectCount[]> =>
	get('/projects/developers_project_count')

export const getArtistsProjectCount = async (): Promise<ProjectCount[]> =>
	get('/projects/artists_project_count')

export const techSetup = async (techSetupData: TechSetupData): Promise<Project> =>
	put('/projects/tech_setup', techSetupData)

export const addGdd = async (id: string, link: string): Promise<Project> =>
	put('/projects/add_gdd', { id, link })

export const qualityApprove = async (id: string): Promise<Project> =>
	put(`/projects/${id}/quality_approve`)

export const mvpDone = async (id: string, date: Date): Promise<Project> =>
	put('/projects/mvp_done', { id, date })

export const releaseProject = async (id: string, date: Date): Promise<ReleasedProject> =>
	post('/projects/release', { id, date })

export const sendResults = async (resultsData: ProjectResultData): Promise<Project> =>
	post('/projects/send_results', resultsData)

export const createProject = async (
	creationData: ProjectCreationData
): Promise<ProjectWithIteration> => post('/projects/create', creationData)

export const updateProject = async (updatingData: ProjectUpdatingData): Promise<Project> =>
	put('/projects/update', updatingData)

export const updateProjectSumm = async (updateData: UpdatedProjectSummData): Promise<Project> =>
	put('projects/update/summ', updateData).then((data) => data)

export const pauseBurnRateProject = async (data: PauseProjectData): Promise<Project> =>
	put('/projects/pause', data)

export const continueBurnRateProject = async (id: string, date: Date): Promise<Project> =>
	put('/projects/continue', { id, date })

export const deleteProject = async (id: string): Promise<Project> =>
	remove(`/projects/delete/${id}`)

//Visits
export const loadVisits = async (): Promise<Visits[]> => get('/visits/get_all_visits')

export const createProjectVisitCheck = async (
	creationData: ProjectVisitCreationData
): Promise<Visits> => put('/visits/add_project_checkpoint', creationData)

export const createConceptVisitCheck = async (
	creationData: ConceptVisitCreationData
): Promise<Visits> => put('/visits/add_concept_checkpoint', creationData)

//# Projects art

export const artSetup = async (artSetupData: ArtSetupData): Promise<ProjectArtInfo> =>
	put('/projects_art/art_setup', artSetupData)

export const unSetArt = async (id: string): Promise<ProjectArtInfo> =>
	put(`/projects_art/${id}/art_unset`)

export const takeConcept = async (art_info_id: string): Promise<ProjectArtInfo> =>
	put(`/projects_art/${art_info_id}/take_concept`)

export const uploadConceptArt = async (
	art_info_id: string,
	files: File[]
): Promise<ProjectArtInfo> => {
	let fd = new FormData()
	for (let i = 0; i < files.length; i += 1) {
		const fileName = files[i].name

		fd.append('file', files[i], fileName)
	}

	return post(`/projects_art/${art_info_id}/upload_concept_art`, fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}

export const artDone = async (data: ArtDoneUpatingData): Promise<ProjectArtInfo> =>
	put('/projects_art/art_done', data)

export const deleteConceptArtFiles = async (id: string): Promise<ProjectArtInfo> =>
	put(`/projects_art/${id}/remove_concept_art_files`)

//# Concepts

export const getConcept = async (id: string): Promise<Concept> => get(`/concepts/${id}`)

export const getConcepts = async (): Promise<Concept[]> => get('/concepts/all')

export const getAllArchivedConcepts = async (): Promise<Concept[]> => get('/concepts/all_archived')

export const getPinnedConcepts = async (): Promise<Concept[]> => get('/concepts/pinned')

export const getEmployeeConcepts = async (id: string): Promise<Concept[]> =>
	get(`/concepts/${id}/all`)

export const addConcept = async (creationData: ConceptCreationData): Promise<Concept> =>
	post('/concepts/add', creationData)

export const archiveConcept = async (id: string): Promise<Concept> =>
	post('/concepts/archive', { id })

export const unarchiveConcept = async (id: string): Promise<Concept> =>
	post('/concepts/unarchive', { id })

export const approveConcept = async (id: string): Promise<Concept> =>
	post('/concepts/approve', { id })

export const rejectConcept = async (id: string): Promise<Concept> =>
	post('/concepts/reject', { id })

export const pinConcept = async (pinnedData: PinnedConceptData): Promise<Project> =>
	put('/concepts/pin', pinnedData)

//# Company statistics
export const getCompanyStat = async (): Promise<CompanyStatistics[]> =>
	get('/company_statistics/get_all')

export const forceCalculateCompanyStat = async (): Promise<boolean> =>
	get('/company_statistics/force_calculate')

//# Employee statistic
export const getEmployeesStatistic = async (): Promise<EmployeeStatisticsByYear[]> =>
	get(`/employee_statistic/by_all_employees`)

export const getEmployeeStatistic = async (id: string): Promise<EmployeeStatisticsByYear[]> =>
	get(`/employee_statistic/${id}/by_employee`)

export const forceCalculateEmployeeStat = async (): Promise<EmployeeStatisticsByYear[]> =>
	get('/employee_statistic/force_calculate', null, 300000)

//# Survey
export const getCurrentSurvey = async (): Promise<SurveyState | NoSurvey> => {
	return get('/survey/current')
}

export const sendAnswers = async (
	sendData: SendAnswersData
): Promise<Survey | PartySurvey | ProjectSurvey> => post('/survey/answers', sendData)

export const getAllWeeklyAndMonthlySurveys = async (): Promise<Survey[]> =>
	get('/survey/all_weekly_and_monthly')

export const getAllProjectSurveys = async (months: number): Promise<ProjectSurvey[]> =>
	get(`/survey/${months}/all_project_surveys`)

export const getEmployeeProjectSurveys = async (id: string): Promise<ProjectSurvey[]> =>
	get(`/survey/${id}/employee_project_surveys`)

export const getAllPartySurveys = async (): Promise<PartySurvey[]> =>
	get('/survey/all_party_surveys')

export const getProjectSurvey = async (project_id: string): Promise<ProjectSurvey> =>
	get(`/survey/${project_id}/project_survey`)

//# Invoices
export const getInvoices = async (): Promise<Invoice[]> => get('/invoices/all')

export const addInvoice = async (creationData: InvoiceCreationData): Promise<Invoice> =>
	post('/invoices/add', creationData)

export const addProjectsToInvoice = async (updatedData: AddProjectsData): Promise<Invoice> =>
	post('/invoices/add_projects', updatedData)

export const formatInvoice = async (id: string): Promise<Invoice> => put(`/invoices/${id}/format`)

export const payInvoice = async (payData: PayInvoiceData): Promise<Invoice> =>
	put('/invoices/pay', payData)

export const sendInvoice = async (sendInvoiceData: SendInvoiceData): Promise<Invoice> =>
	put(`/invoices/send`, sendInvoiceData)

export const updateInvoice = async (updateData: UpdateInvoiceData): Promise<Invoice> =>
	put(`/invoices/update`, updateData)

export const deleteInvoice = async (invoice_id: string, file_id: string): Promise<Invoice> =>
	remove(`/invoices/delete_invoice`, { invoice_id, file_id })

export const updateInvoiceFile = async (invoice_id: string, file: File) => {
	const params = new URLSearchParams()
	params.append('invoice_id', invoice_id)
	params.append('file', file as any)
	params.append('file_name', file.name)

	post(`/invoices/upload_files`, params, {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Accept: 'application/json'
		}
	})
}

export const uploadInvoiceFile = async (invoice_id: string, file: File): Promise<Invoice> => {
	let fd = new FormData()
	const fileName = file.name
	fd.append('file', file, fileName)

	return post(`/invoices/upload_invoice/${invoice_id}`, fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}

export const downloadInvoiceFile = async (file_id: string) => {
	return getDownload(`/invoices/download/${file_id}`)
}

export const deleteInvoiceFile = async (invoice_id: string, file_id?: string) => {
	return remove('/invoices/delete_invoice_file', { invoice_id, file_id })
}

//# Promotions

export const getAllPromotions = async (): Promise<Promotion[]> => get(`/promotion/all`)

export const getEmployeePromotions = async (id: string): Promise<Promotion[]> =>
	get(`/promotion/${id}/employee_promotions`)

export const getPromotionsAtId = async (id: string): Promise<Promotion> => get(`/promotion/${id}`)

export const deletePropmotionAtId = async (id: string): Promise<Promotion> =>
	remove(`/promotion/${id}`)

export const addPropmotion = async (creatingData: PromotionCreatingData): Promise<Promotion> =>
	post('/promotion/create', creatingData)

export const repeatPromotionRequest = async (id: string): Promise<Promotion> =>
	put(`/promotion/${id}/repeat_request`)

export const agreePromotionRequest = async (
	agreePromotionData: AgreePromotionData
): Promise<Promotion> => put('/promotion/agree_promotion', agreePromotionData)

export const rejectPromotionRequest = async (rejectData: RejectPromotionData): Promise<Promotion> =>
	put('/promotion/reject_promotion', rejectData)

//# Finance
export const getFinanceByMonth = async (month: string): Promise<FinanceCollection> =>
	get(`/finance/allByMonth/${month}`, null, 300000)

export const recalculateFinance = async (): Promise<FinanceCollection> =>
	get('/finance/force_calculate_finance', null, 1200000)

//#Finance/finance_operations
export const getFinanceOperationsByMonth = async (month: Date): Promise<Balances> =>
	get(`finance/finance_operations/${month}`)

export const getFinanceOperationsByYear = async (year: Date): Promise<FinanceOperation[]> =>
	get(`finance/${year}/allOperationByYear`)

export const createFinanceOperations = async (
	creationData: FinanceOperationCreationData
): Promise<FinanceOperation> => post('finance/finance_operation/create', creationData)

export const approveFinanceOperation = async (id: string): Promise<FinanceOperation> =>
	put(`finance/finance_operation/approve/${id}`)

export const rejectFinanceOperation = async (id: string): Promise<FinanceOperation> =>
	put(`finance/finance_operation/reject/${id}`)

export const checkFinanceOperation = async (
	checkData: FinanceOperationCheckData
): Promise<FinanceOperation> => put('finance/finance_operation/check', checkData)

export const deleteFinanceOperation = async (id: string): Promise<FinanceOperation> =>
	remove(`finance/finance_operation/delete/${id}`)

//expense file
export const uploadExpenseFile = async (
	expense_id: string,
	file: File
): Promise<FinanceOperation> => {
	let fd = new FormData()
	const fileName = file.name
	fd.append('file', file, fileName)
	return post(`/finance/upload_expense_file/${expense_id}`, fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}

export const downloadExpenseFile = async (file_id: string) => {
	return getDownload(`/finance/download_expense_file/${file_id}`)
}

export const deleteExpenseFile = async (
	expense_id: string,
	file_id: string
): Promise<FinanceOperation> => {
	return remove('/finance/delete_expense_file', { expense_id, file_id })
}

//Balance
export const getBalanceByMonth = async (month: string): Promise<BalanceInfo> =>
	get(`finance/balance/${month}`)

export const getCalcBalances = async (): Promise<CalcBalances> => get(`finance/calc_balances`)

export const createInterBalanceTransaction = async (
	creationData: InterBalanceTransactionCreationData
): Promise<BalanceInfo> => post('finance/inter_balance_finance_operation/create', creationData)
//Dividends

export const getDividends = async (): Promise<DividendsInfo> => get('finance/dividends')

export const distibuteFoundersDividends = async (
	distributionData: DistributionData
): Promise<Founder[]> => put('finance/distribute_dividends', distributionData)

export const withdrawDividends = async (
	withdrawData: WithdrawDividendsData
): Promise<DividendsInfo> => post('finance/withdraw_dividends', withdrawData)

//Salaries
export const getSalariesTableByMonth = async (month: string): Promise<SalariesTable> =>
	get(`finance/${month}/salaries_table`)

export const addBonusesToSalariesTable = async (
	creationData: AddSalaryChanges
): Promise<SalariesTable> => put('finance/add_bonuses', creationData)

export const addFinesToSalariesTable = async (
	creationData: AddSalaryChanges
): Promise<SalariesTable> => put('finance/add_fines', creationData)

export const removeBonusFromSalaryTable = async (
	removeData: RemoveSalaryChanges
): Promise<SalariesTable> => remove('finance/remove_bonus', removeData)

export const removeFineFromSalariesTable = async (
	removeData: RemoveSalaryChanges
): Promise<SalariesTable> => remove('finance/remove_fine', removeData)

export const updateSalaryVariables = async (
	updatingData: SalaryPayUpdatingData
): Promise<SalariesTable> => put('finance/update_salary_variables', updatingData)

export const checkEmployeeSalary = async (checkData: CheckSalaryData): Promise<SalariesTable> =>
	put('finance/check_salary', checkData)

export const updateSalaryTableStatus = async (
	updatedData: UpdateSalaryTableStatusData
): Promise<SalariesTable> => put('finance/update_status', updatedData)

export const setSalariesTableDollarRate = async (
	updatingData: SalaryTableDollarRateData
): Promise<SalariesTable> => put('finance/set_dollar_rate', updatingData)

export const withdrawSalariesTableSumms = async (
	wthdrawData: SalaryTableWithdrawData
): Promise<SalariesTable> => put('finance/withdraw_salary', wthdrawData)

//# Training Project
export const getTrainingProjects = async (): Promise<TrainingProject[]> =>
	get(`/training_project/all`)

export const createTrainingProjects = async (
	creationData: TrainingProjectCreationData
): Promise<TrainingProject> => post(`/training_project/create`, creationData)

export const endTrainingProjects = async (
	endData: TrainingProjectEndData
): Promise<TrainingProject> => post(`/training_project/end`, endData)

//# Vacations

export const getAllVacations = async (): Promise<Vacation[]> => get(`/vacations/all`)

export const getEmployeeVacations = async (id: string): Promise<Vacation[]> =>
	get(`/vacations/${id}/by_employee`)

export const getTwoMonthVacations = async (): Promise<Vacation[]> =>
	get('/vacations/two_month_vacations')

export const getTodayVacations = async (): Promise<Vacation[]> => get(`/vacations/today_vacations`)

export const createVacation = async (creationData: VacationCreationData): Promise<Vacation> =>
	post(`/vacations/create`, creationData)

export const updateVacation = async (updatingData: VacationUpdatingData): Promise<Vacation> =>
	put('/vacations/update_vacation', updatingData)

export const rejectVacation = async (rejectingData: VacationRejectingData): Promise<Vacation> =>
	put('/vacations/reject_vacation', rejectingData)

export const deleteVacation = async (id: string): Promise<Vacation> => remove(`/vacations/${id}`)

export const getAllVacationsPeriods = async (): Promise<VacationLockedPeriods[]> =>
	get(`/vacations/periods/all`)

export const createVacationPeriod = async (
	creationData: VacationLocedPeriodCreationData
): Promise<VacationLockedPeriods> => post(`/vacations/periods/create`, creationData)

export const deleteVacationPeriod = async (id: string): Promise<VacationLockedPeriods> =>
	remove(`/vacations/periods/${id}`)

//# Sicknesses

export const getAllSicknesses = async (): Promise<Sickness[]> => get(`/sicknesses/all`)

export const getTwoMonthSicknesses = async (): Promise<Sickness[]> =>
	get('/sicknesses/two_month_sicknesses')

export const getEmployeeSicknesses = async (id: string): Promise<Sickness[]> =>
	get(`/sicknesses/${id}/by_employee`)

export const getTodaySicknesses = async (): Promise<Sickness[]> =>
	get(`/sicknesses/today_sicknesses`)

export const createSickness = async (creationData: SicknessCreationData): Promise<Sickness> =>
	post('/sicknesses/create', creationData)

export const updateSickness = async (updateData: SicknessUpdateData): Promise<Sickness> =>
	put('/sicknesses/update', updateData)

export const deleteSickness = async (id: string) => remove(`/sicknesses/delete/${id}`)

//sickness file
export const uploadSicknessFile = async (sickness_id: string, file: File): Promise<Sickness> => {
	let fd = new FormData()
	const fileName = file.name
	fd.append('file', file, fileName)
	return post(`/sicknesses/upload_file/${sickness_id}`, fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}

export const downloadSicknessFile = async (file_id: string) => {
	return getDownload(`/sicknesses/download_file/${file_id}`)
}

export const deleteSicknessFile = async (deleteInfo: SicknessFileDeleteDto): Promise<Sickness> => {
	return put('/sicknesses/delete_file', deleteInfo)
}

//# Time tracking
export const getAllCheckins = async (): Promise<WorkCheckin[]> => get(`/time_tracking/all`)

export const getMonthCheckins = async (month: Date): Promise<WorkCheckin[]> =>
	get(`/time_tracking/${month}/by_month`)

export const getEmployeeCheckins = async (id: string): Promise<WorkCheckin[]> =>
	get(`time_tracking/${id}/employee_checkins`)

export const getEmployeeCheckinsByMonth = async (
	requestInfo: MonthEmployeeCheckinInfo
): Promise<WorkCheckin[]> => get('time_tracking/employee_by_month', requestInfo)

export const addCheckin = async (): Promise<WorkCheckin> => post(`/time_tracking/checkin`)

export const addEndDayCheckin = async (id: string): Promise<WorkCheckin> =>
	put(`/time_tracking/${id}/end_day_checkin`)

export const updateCheckin = async (id: string, comment: string): Promise<WorkCheckin> =>
	put(`/time_tracking/update`, { id, comment })

//# Ideas
export const loadIdeas = async (): Promise<Idea[]> => get('/ideation/get_all_ideas')

export const loadAllArchivedIdeas = async (): Promise<Idea[]> => get('/ideation/all_archived')

export const loadEmployeeIdeas = async (id: string): Promise<Idea[]> =>
	get(`/ideation/${id}/get_employee_ideas`)

export const createIdea = async (creationData: IdeaCreationData): Promise<Idea> =>
	post('/ideation/add_new_idea', creationData)

export const useIdea = async (id: string): Promise<Idea> => put(`/ideation/${id}/use_idea`)

export const archiveIdea = async (id: string): Promise<Idea> => put(`/ideation/${id}/archive_idea`)

export const unarchiveIdea = async (id: string): Promise<Idea> =>
	put(`/ideation/${id}/unarchive_idea`)

export const deleteIdea = async (id: string): Promise<Idea> => remove(`/ideation/${id}/delete_idea`)

//# Parties
export const loadParties = async (): Promise<Party[]> => get('/parties/all_parties')

export const loadMemberParties = async (id: string): Promise<Party[]> =>
	get(`/parties/${id}/member_parties`)

export const createParty = async (creationData: PartyCreationData): Promise<Party> =>
	post('/parties/create_party', creationData)

export const confirmParty = async (id: string): Promise<Party> =>
	put(`/parties/${id}/confirm_party`)

export const doneParty = async (id: string): Promise<Party> => put(`/parties/${id}/done_party`)

export const updateParty = async (updatingData: PartyUpdatingData): Promise<Party> =>
	put(`/parties/update_party`, updatingData)

export const updatePartyMemberStatus = async (
	updatingData: UpdateMemberStatusDto
): Promise<Party> => put(`/parties/update_member_status`, updatingData)

export const deleteParty = async (id: string): Promise<Party> =>
	remove(`/parties/${id}/delete_party`)

//#Todolists
export const loadAllTodolists = async (): Promise<Todolist[]> => get('/todolists/get_all')

export const loadEmployeeTodolist = async (id: string): Promise<Todolist> =>
	get(`/todolists/${id}/get_by_employee`)

export const addTodo = async (addTodoData: AddTodoData): Promise<Todolist> =>
	post('/todolists/add_todo', addTodoData)

export const noTodos = async (id: string): Promise<Todolist> => put(`/todolists/${id}/no_todos`)

export const updateTodoStatus = async (updatingData: UpdateTodoStatusData): Promise<Todolist> =>
	put('/todolists/update_todo_status', updatingData)

//#System settings
export const loadSystemSettings = async (): Promise<SystemSettings> => get('/system_settings/all')

export const updateSystemSettings = async (updatingData): Promise<SystemSettings> =>
	put('/system_settings/update', updatingData)

//#KPI
export const loadAllKPICards = async (): Promise<KPICardType[]> => get(`/kpi/get_all_kpi`)

export const loadEmployeeKPICards = async (employee_id: string): Promise<KPICardType[]> =>
	get(`/kpi/${employee_id}/employee_kpi`)

export const loadTodayDeadlinedKPICards = async (day: string): Promise<KPICardType[]> =>
	get(`/kpi/${day}/today_dedline`)

export const createKPICard = async (creationData: KPICreationData): Promise<KPICardType> =>
	post('/kpi/create_kpi', creationData)

export const checkKPICard = async (updateData: ConditionUpdateData): Promise<KPICardType> =>
	put('/kpi/check_kpi', updateData)

export const doneKPICard = async (updateData: ConditionUpdateData): Promise<KPICardType> =>
	put('/kpi/done_kpi', updateData)

export const deleteKPICard = async (id: string): Promise<KPICardType> =>
	remove(`/kpi/${id}/delete_kpi`)

//#Recruiting
//stage
export const loadStages = async (): Promise<Stage[]> => get('/recruiting/get_all_stages')

export const createStage = async (creationData: StageCreationData): Promise<Stage> =>
	post('/recruiting/create_stage', creationData)

export const updateStagesIndexes = async (updatingData: UpdateStagesIndexesData): Promise<Stage> =>
	put('/recruiting/update_stages_indexes', updatingData)

export const archiveStage = async (id: string): Promise<Stage> =>
	put(`/recruiting/${id}/archive_stage`)

//funnel
export const loadFunnels = async (): Promise<Funnel[]> => get('/recruiting/get_all_funnels')

export const createFunnel = async (creationData: FunnelCreationData): Promise<Funnel> =>
	post('/recruiting/create_funnel', creationData)

export const editFunnel = async (updatingData: FunnelUpdatingData): Promise<Funnel> =>
	put('/recruiting/edit_funnel', updatingData)

export const archiveFunnel = async (funnel_id: string): Promise<Funnel> =>
	put(`/recruiting/${funnel_id}/archive_funnel`)

//vacancy
export const loadVacancies = async (): Promise<Vacancy[]> => get('/recruiting/get_all_vacancies')

export const createVacancy = async (creationData): Promise<Vacancy> =>
	post('/recruiting/create_vacancy', creationData)

export const archiveVacancy = async (vacancy_id: string): Promise<Vacancy> =>
	put(`/recruiting/${vacancy_id}/archive_vacancy`)

export const uploadVacancyFile = (fileData: VacancyFileData): Promise<Vacancy> => {
	let fd = new FormData()

	const fileName = fileData.file_name
	fd.append('file', fileData.file, fileName)
	return post(`/recruiting/upload_vacancy_file/${fileData.vacancy_id}`, fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}

export const downloadVacancyFile = async (file_id: string) => {
	return getDownload(`/recruiting/download_file/${file_id}`)
}

//candidate
export const loadCandidates = async (): Promise<Candidate[]> =>
	get('/recruiting/get_all_candidates')

export const createCandidate = async (creationData: CandidateCreationData): Promise<Candidate> =>
	post('/recruiting/create_candidate', creationData)

export const updateCandidate = async (creationData: CandidateUpdatingData): Promise<Candidate> =>
	put('/recruiting/update_candidate', creationData)

export const takeOnVacancyCandidate = async (
	creationData: TakeOnVacancyCreationData
): Promise<Candidate> => put('/recruiting/take_on_vacancy', creationData)

export const archiveCandidate = async (candidate_id: string): Promise<Candidate> =>
	put(`/recruiting/${candidate_id}/archive_candidate`)

//hiring item
export const addNewHiringHistoryItem = async (
	creationData: HiringHistoryItemCreationData
): Promise<HiringItem> => put(`/recruiting/add_history_item`, creationData)

export const editHiringHistoryItemComment = async (
	editData: HiringHistoryItemEditCommentData
): Promise<HiringItem> => put(`/recruiting/edit_comment`, editData)

export const deleteHiringHistoryItem = async (
	deletingData: HiringHistoryItemDeletingData
): Promise<HiringItem> => put(`/recruiting/delete_history_item`, deletingData)

export const uploadHiringHistoryItemFile = (
	fileData: HiringHistoryItemFileData
): Promise<HiringItem> => {
	let fd = new FormData()

	const fileName = fileData.file_name
	fd.append('file', fileData.file, fileName)
	return post(`/recruiting/upload_hiring_history_item_file/${fileData.hiring_item_id}`, fd, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
}

export const downloadHiringHistoryItemFileFile = async (file_id: string) => {
	return getDownload(`/recruiting/download/${file_id}`)
}

//application
export const loadApplications = async (): Promise<Application[]> =>
	get('/recruiting/get_all_applications')

export const createApplication = async (
	creationData: ApplicationCreationData
): Promise<Application> => post('/recruiting/create_application', creationData)

export const archiveApplication = async (application_id: string): Promise<Application> =>
	put(`/recruiting/${application_id}/archive_application`)

//department
export const loadDepartments = async (): Promise<Department[]> =>
	get('/recruiting/get_all_departments')

export const createDepartment = async (creationData: DepartmentCreationData): Promise<Department> =>
	post('/recruiting/create_department', creationData)

export const editDepartment = async (editData: DepartmentEditData): Promise<Department> =>
	put('/recruiting/edit_department', editData)

export const archiveDepartment = async (department_id: string): Promise<Department> =>
	put(`/recruiting/${department_id}/archive_department`)
