import { ShortEmployee } from './employee'

export enum VacationTypeEnum {
	inVacation = 'Vacations_typeInVacation'
}

export enum VacationStatusEnum {
	requested = 'requested',
	agreed = 'agreed',
	rejected = 'rejected'
}

export interface VacationCreationData {
	status: VacationStatusEnum
	employee: string
	start_date: Date
	end_date: Date
	days: number
}

export interface VacationLocedPeriodCreationData {
	start_date: Date
	end_date: Date
}

export interface VacationUpdatingData {
	id: string
	status: VacationStatusEnum
	start_date: Date
	end_date: Date
	comment?: string
}

export interface VacationRejectingData {
	id: string
	comment: string
}

export interface MonthAndEmployeeForVacations {
	employee: string
	month: Date
}

export default interface Vacation {
	_id: string
	date: Date
	status: VacationStatusEnum
	employee: ShortEmployee
	start_date: Date
	end_date: Date
	days: number
	comment?: string
}
