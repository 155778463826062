import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Stage } from 'types/recruiting/stage'

export const loadStagesRequest = createAction(types.LOAD_STAGES_REQUEST)
export const loadStagesSuccess = createAction<Stage[]>(types.LOAD_STAGES_SUCCESS)
export const loadStagesError = createAction(types.LOAD_STAGES_ERROR)

export const createStageRequest = createAction(types.CREATE_STAGE_REQUEST)
export const createStageSuccess = createAction<Stage>(types.CREATE_STAGE_SUCCESS)
export const createStageError = createAction(types.CREATE_STAGE_ERROR)

export const updateStagesIndexesRequest = createAction(types.UPDATE_STAGES_INDEXES_REQUEST)
export const updateStagesIndexesSuccess = createAction<Stage>(types.UPDATE_STAGES_INDEXES_SUCCESS)
export const updateStagesIndexesError = createAction(types.UPDATE_STAGES_INDEXES_ERROR)

export const archiveStageRequest = createAction(types.ARCHIVE_STAGE_REQUEST)
export const archiveStageSuccess = createAction<Stage>(types.ARCHIVE_STAGE_SUCCESS)
export const archiveStageError = createAction(types.ARCHIVE_STAGE_ERROR)
