export enum PositionsEnum {
	ceo = 'ceo',
	cto = 'cto',
	lead_developer = 'lead_developer',
	technical_art_director = 'technical_art_director',
	hr = 'hr',

	first_game_producer = 'first_game_producer',
	second_game_producer = 'second_game_producer',
	associate_producer = 'associate_producer',
	unity_developer = 'unity_developer',
	artist = 'artist',
	concept_artist = 'concept_artist',
	qa = 'qa',

	game_designer = 'game_designer',
	level_designer = 'level_designer',
	economy_designer = 'economy_designer',
	sound_designer = 'sound_designer',
	motion_designer = 'motion_designer',
	animator = 'animator',

	business_development_manager = 'business_development_manager',
	user_acquisition_manager = 'user_acquisition_manager',
	community_manager = 'community_manager',

	full_stack_developer = 'full_stack_developer',
	javascript_developer = 'javascript_developer',
	project_manager = 'project_manager',

	office_manager = 'office_manager',
	lead_recruiter = 'lead_recruiter',
	recruiter = 'recruiter',
	secretary = 'secretary',
	document_manager = 'document_manager',
	masseur = 'masseur',
	cleaner = 'cleaner',

	other = 'other'
}

export const positionName = (position: PositionsEnum) => {
	switch (position) {
		case PositionsEnum.ceo:
			return 'CEO'
		case PositionsEnum.cto:
			return 'CTO'
		case PositionsEnum.first_game_producer:
			return 'Game Producer'
		case PositionsEnum.second_game_producer:
			return 'Game Producer'
		case PositionsEnum.associate_producer:
			return 'Associate Producer'
		case PositionsEnum.lead_developer:
			return 'Lead Developer'
		case PositionsEnum.technical_art_director:
			return 'Technical Art Director'
		case PositionsEnum.concept_artist:
			return 'Concept Artist'
		case PositionsEnum.artist:
			return 'Artist'
		case PositionsEnum.unity_developer:
			return 'Unity Developer'
		case PositionsEnum.qa:
			return 'QA'
		case PositionsEnum.office_manager:
			return 'Office Manager'
		case PositionsEnum.hr:
			return 'HR'
		case PositionsEnum.business_development_manager:
			return 'Business Development Manager'
		case PositionsEnum.level_designer:
			return 'Level Designer'
		case PositionsEnum.economy_designer:
			return 'Game Economy Designer'
		case PositionsEnum.game_designer:
			return 'Game Designer'
		case PositionsEnum.sound_designer:
			return 'Game Sound Designer'
		case PositionsEnum.motion_designer:
			return 'Motion Designer'
		case PositionsEnum.animator:
			return 'Animator'
		case PositionsEnum.community_manager:
			return 'Community Manager'
		case PositionsEnum.lead_recruiter:
			return 'Lead recruiter'
		case PositionsEnum.recruiter:
			return 'Recruiter'
		case PositionsEnum.secretary:
			return 'Secretary'
		case PositionsEnum.document_manager:
			return 'Document manager'
		case PositionsEnum.user_acquisition_manager:
			return 'User Acquisition Manager'
		case PositionsEnum.full_stack_developer:
			return 'Full Stack Developer'
		case PositionsEnum.javascript_developer:
			return 'JavaScript Developer'
		case PositionsEnum.project_manager:
			return 'Project Manager'
		case PositionsEnum.masseur:
			return 'Masseur'
		case PositionsEnum.cleaner:
			return 'Cleaner'
		case PositionsEnum.other:
			return 'Other'
		default:
			return 'Other'
	}
}
