import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import ProjectArtInfo from 'types/project_art_info'

export const loadConceptArtRequest = createAction(types.LOAD_CONCEPT_ART_REQUEST)
export const loadConceptArtSuccess = createAction<ProjectArtInfo[]>(types.LOAD_CONCEPT_ART_SUCCESS)
export const loadConceptArtError = createAction(types.LOAD_CONCEPT_ART_ERROR)

export const setupConceptArtRequest = createAction(types.SETUP_CONCEPT_ART_REQUEST)
export const setupConceptArtSuccess = createAction<ProjectArtInfo>(types.SETUP_CONCEPT_ART_SUCCESS)
export const setupConceptArtError = createAction(types.SETUP_CONCEPT_ART_ERROR)

export const unSetConceptArtRequest = createAction(types.UNSET_CONCEPT_ART_REQUEST)
export const unSetConceptArtSuccess = createAction<ProjectArtInfo>(types.UNSET_CONCEPT_ART_SUCCESS)
export const unSetConceptArtError = createAction(types.UNSET_CONCEPT_ART_ERROR)

export const takeConceptArtRequest = createAction(types.TAKE_CONCEPT_ART_REQUEST)
export const takeConceptArtSuccess = createAction<ProjectArtInfo>(types.TAKE_CONCEPT_ART_SUCCESS)
export const takeConceptArtError = createAction(types.TAKE_CONCEPT_ART_ERROR)

export const uploadConceptArtRequest = createAction(types.UPLOAD_CONCEPT_ART_REQUEST)
export const uploadConceptArtSuccess = createAction<ProjectArtInfo>(
	types.UPLOAD_CONCEPT_ART_SUCCESS
)
export const uploadConceptArtError = createAction(types.UPLOAD_CONCEPT_ART_ERROR)

export const doneConceptArtRequest = createAction(types.DONE_CONCEPT_ART_REQUEST)
export const doneConceptArtSuccess = createAction<ProjectArtInfo>(types.DONE_CONCEPT_ART_SUCCESS)
export const doneConceptArtError = createAction(types.DONE_CONCEPT_ART_ERROR)

export const deleteConceptArtFilesRequest = createAction(types.DELETE_CONCEPT_ART_FILES_REQUEST)
export const deleteConceptArtFilesSuccess = createAction<ProjectArtInfo>(
	types.DELETE_CONCEPT_ART_FILES_SUCCESS
)
export const deleteConceptArtFilesError = createAction(types.DELETE_CONCEPT_ART_FILES_ERROR)
