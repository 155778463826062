import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import Employee from 'types/employee'

export const loadEmployeesRequest = createAction(types.LOAD_EMPLOYEES_REQUEST)
export const loadEmployeesSuccess = createAction<Employee[]>(types.LOAD_EMPLOYEES_SUCCESS)
export const loadEmployeesError = createAction(types.LOAD_EMPLOYEES_ERROR)

export const getEmployeeRequest = createAction(types.GET_EMPLOYEE_REQUEST)
export const getEmployeeSuccess = createAction<Employee>(types.GET_EMPLOYEE_SUCCESS)
export const getEmployeeError = createAction(types.GET_EMPLOYEE_ERROR)

export const loadShortEmployeesRequest = createAction(types.LOAD_SHORT_EMPLOYEES_REQUEST)
export const loadShortEmployeesSuccess = createAction<Employee[]>(
	types.LOAD_SHORT_EMPLOYEES_SUCCESS
)
export const loadShortEmployeesError = createAction(types.LOAD_SHORT_EMPLOYEES_ERROR)

export const registerEmployeeRequest = createAction(types.REGISTER_EMPLOYEE_REQUEST)
export const registerEmployeeSuccess = createAction<Employee>(types.REGISTER_EMPLOYEE_SUCCESS)
export const registerEmployeeError = createAction(types.REGISTER_EMPLOYEE_ERROR)

export const updateInfoEmployeeRequest = createAction(types.UPDATE_INFO_EMPLOYEE_REQUEST)
export const updateInfoEmployeeSuccess = createAction<Employee>(types.UPDATE_INFO_EMPLOYEE_SUCCESS)
export const updateInfoEmployeeError = createAction(types.UPDATE_INFO_EMPLOYEE_ERROR)

export const updatePromotionInfoEmployeeRequest = createAction(
	types.UPDATE_PROMOTION_INFO_EMPLOYEE_REQUEST
)
export const updatePromotionInfoEmployeeSuccess = createAction<Employee>(
	types.UPDATE_PROMOTION_INFO_EMPLOYEE_SUCCESS
)
export const updatePromotionInfoEmployeeError = createAction(
	types.UPDATE_PROMOTION_INFO_EMPLOYEE_ERROR
)

export const uploadEmployeeProfilePhotoRequest = createAction(
	types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_REQUEST
)
export const uploadEmployeeProfilePhotoSuccess = createAction<Employee>(
	types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_SUCCESS
)
export const uploadEmployeeProfilePhotoError = createAction(
	types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_ERROR
)

export const deleteEmployeeProfilePhotoRequest = createAction(
	types.DELETE_EMPLOYEE_PROFILE_PHOTO_REQUEST
)
export const deleteEmployeeProfilePhotoSuccess = createAction<Employee>(
	types.DELETE_EMPLOYEE_PROFILE_PHOTO_SUCCESS
)
export const deleteEmployeeProfilePhotoError = createAction(
	types.DELETE_EMPLOYEE_PROFILE_PHOTO_ERROR
)

export const archiveEmployeeRequest = createAction(types.ARCHIVE_EMPLOYEE_REQUEST)
export const archiveEmployeeSuccess = createAction<Employee>(types.ARCHIVE_EMPLOYEE_SUCCESS)
export const archiveEmployeeError = createAction(types.ARCHIVE_EMPLOYEE_ERROR)
