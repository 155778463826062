import { configureStore } from '@reduxjs/toolkit'
import { FilterState } from 'redux/reducers/filterReducer'
import { UserState } from 'redux/reducers/userReducer'
import rootReducer from '../reducers/index'

const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV === 'development',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false
		})
})

export default store

export interface StoreType {
	app
	interface_settings
	layout
	theme
	data
	user: UserState
	filter: FilterState
	toastr
}
