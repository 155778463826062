import { loadSystemSettings, updateSystemSettings } from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import { SystemSettings } from 'types/systemSettings'

export const loadSystemSettingsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.systemSettings.loadSystemSettingsRequest())
	try {
		const systemSettings: SystemSettings = await loadSystemSettings()

		dispatch(actions.dataActions.systemSettings.loadSystemSettingsSuccess(systemSettings))
	} catch (error: any) {
		//const message = t('reduxToastrError_loadFinanceCollection')
		toastr.error('Error', error.response.data.message)
		dispatch(actions.dataActions.systemSettings.loadSystemSettingsError())
	}
}

export const updateSystemSettingsFunc = (updatedData) => async (dispatch) => {
	dispatch(actions.dataActions.systemSettings.updateSystemSettingsRequest())
	try {
		const updatedSystemSettings: SystemSettings = await updateSystemSettings(updatedData)

		toastr.success('', t('reduxToastrSuccess_systemSettingsUpdate'))
		dispatch(
			actions.dataActions.systemSettings.updateSystemSettingsSuccess(updatedSystemSettings)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		dispatch(actions.dataActions.systemSettings.updateSystemSettingsError())
	}
}
