import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import Publisher from 'types/publisher'

export const loadPublishersRequest = createAction(types.LOAD_PUBLISHERS_REQUEST)
export const loadPublishersSuccess = createAction<Publisher[]>(types.LOAD_PUBLISHERS_SUCCESS)
export const loadPublishersError = createAction(types.LOAD_PUBLISHERS_ERROR)

export const getPublisherRequest = createAction(types.GET_PUBLISHER_REQUEST)
export const getPublisherSuccess = createAction<Publisher>(types.GET_PUBLISHER_SUCCESS)
export const getPublisherError = createAction(types.GET_PUBLISHER_ERROR)

export const loadShortPublishersRequest = createAction(types.LOAD_SHORT_PUBLISHERS_REQUEST)
export const loadShortPublishersSuccess = createAction<Publisher[]>(
	types.LOAD_SHORT_PUBLISHERS_SUCCESS
)
export const loadShortPublishersError = createAction(types.LOAD_SHORT_PUBLISHERS_ERROR)

export const addPublisherRequest = createAction(types.ADD_PUBLISHER_REQUEST)
export const addPublisherSuccess = createAction<Publisher>(types.ADD_PUBLISHER_SUCCESS)
export const addPublisherError = createAction(types.ADD_PUBLISHER_ERROR)

export const archivePublisherRequest = createAction(types.ARCHIVE_PUBLISHER_REQUEST)
export const archivePublisherSuccess = createAction<Publisher>(types.ARCHIVE_PUBLISHER_SUCCESS)
export const archivePublisherError = createAction(types.ARCHIVE_PUBLISHER_ERROR)
