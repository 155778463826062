import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Department } from 'types/recruiting/department'

export const loadDepartmentsRequest = createAction(types.LOAD_DEPARTMENTS_REQUEST)
export const loadDepartmentsSuccess = createAction<Department[]>(types.LOAD_DEPARTMENTS_SUCCESS)
export const loadDepartmentsError = createAction(types.LOAD_DEPARTMENTS_ERROR)

export const createDepartmentRequest = createAction(types.CREATE_DEPARTMENT_REQUEST)
export const createDepartmentSuccess = createAction<Department>(types.CREATE_DEPARTMENT_SUCCESS)
export const createSubdivisionSuccess = createAction<Department>(types.CREATE_SUBDIVISION_SUCCESS)
export const createDepartmentError = createAction(types.CREATE_DEPARTMENT_ERROR)

export const editDepartmentRequest = createAction(types.EDIT_DEPARTMENT_REQUEST)
export const editDepartmentSuccess = createAction<Department>(types.EDIT_DEPARTMENT_SUCCESS)
export const editSubdivisionSuccess = createAction<Department>(types.EDIT_SUBDIVISION_SUCCESS)
export const editDepartmentError = createAction(types.EDIT_DEPARTMENT_ERROR)

export const archiveDepartmentRequest = createAction(types.ARCHIVE_DEPARTMENT_REQUEST)
export const archiveDepartmentSuccess = createAction<Department>(types.ARCHIVE_DEPARTMENT_SUCCESS)
export const archiveSubdivisionSuccess = createAction<Department>(types.ARCHIVE_SUBDIVISION_SUCCESS)
export const archiveDepartmentError = createAction(types.ARCHIVE_DEPARTMENT_ERROR)
