import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	archiveDepartment,
	createDepartment,
	editDepartment,
	loadDepartments
} from 'utils/api.request'
import {
	Department,
	DepartmentCreationData,
	DepartmentDeletingData,
	DepartmentEditData
} from 'types/recruiting/department'

export const loadDepartmentsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.departments.loadDepartmentsRequest())
	try {
		const allDepartments: Department[] = await loadDepartments()

		return dispatch(actions.dataActions.departments.loadDepartmentsSuccess(allDepartments))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.departments.loadDepartmentsError())
	}
}

export const createDepartmentFunc = (creationData: DepartmentCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.departments.createDepartmentRequest())
	try {
		const newDepartment: Department = await createDepartment(creationData)

		toastr.success('', t('reduxToastrSuccess_createDepartment'))

		if (creationData.parent_department) {
			return dispatch(actions.dataActions.departments.createSubdivisionSuccess(newDepartment))
		} else {
			return dispatch(actions.dataActions.departments.createDepartmentSuccess(newDepartment))
		}
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.departments.createDepartmentError())
	}
}

export const editDepartmentFunc = (editData: DepartmentEditData) => async (dispatch) => {
	dispatch(actions.dataActions.departments.editDepartmentRequest())
	try {
		const editedDepartment: Department = await editDepartment(editData)

		toastr.success('', t('reduxToastrSuccess_editDepartment'))

		if (editData.parent_department) {
			return dispatch(
				actions.dataActions.departments.editSubdivisionSuccess(editedDepartment)
			)
		} else {
			return dispatch(actions.dataActions.departments.editDepartmentSuccess(editedDepartment))
		}
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.departments.editDepartmentError())
	}
}

export const archiveDepartmentFunc = (deletingData: DepartmentDeletingData) => async (dispatch) => {
	dispatch(actions.dataActions.departments.archiveDepartmentRequest())
	try {
		const archivedDepartment: Department = await archiveDepartment(deletingData.id)

		toastr.success('', t('reduxToastrSuccess_archiveDepartment'))

		if (deletingData.parent_department) {
			return dispatch(
				actions.dataActions.departments.archiveSubdivisionSuccess(archivedDepartment)
			)
		} else {
			return dispatch(
				actions.dataActions.departments.archiveDepartmentSuccess(archivedDepartment)
			)
		}
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.departments.archiveDepartmentError())
	}
}
