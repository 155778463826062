import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { rainbowColorNameAtPosition } from 'utils/rainbowColors'

import UserAvailabilityStatus from 'components/UserAvailabilityStatus'

import { useTranslation } from 'react-i18next'
import { transliterate } from 'transliteration'
import { FaImage, FaPalette, FaUnity, FaUser, FaUserTie } from 'react-icons/fa'
import { Badge, UncontrolledTooltip } from 'reactstrap'

import { PositionsEnum } from 'types/positions'
import { ShortEmployee } from 'types/employee'

const AuthorBadge = (props: {
	employee?: ShortEmployee
	size?: 'sm' | 'md' | 'full'
	colorOff?: boolean
	linkOff?: boolean
	showIcon?: boolean
	showAvailability?: boolean
	today?: boolean
	className?: string
}) => {
	const [color, setColor] = useState<string>('')
	const [text, setText] = useState<string>('')

	const { i18n } = useTranslation()

	useEffect(() => {
		if (!props.employee) return

		const { first_name, last_name } = props.employee

		if (!first_name || !last_name) return
		setColor(rainbowColorNameAtPosition(props.employee.position, props.employee.user_roles))

		switch (props.size) {
			case 'sm':
				setText(first_name.charAt(0))
				break
			case 'full':
				setText(`${first_name} ${last_name}`)
				break
			default:
				setText(`${first_name} ${last_name.charAt(0)}`)
		}
	}, [props.employee, props.size])

	const UserIcon = () => {
		if (!props.employee)
			return <FaUser className="mr-1" style={{ padding: '0.05rem !important' }} />

		const { position } = props.employee

		/* if (user_roles.includes(RolesEnum.mentor) || user_roles.includes(RolesEnum.art_mentor))
			return <FaUserNinja className="mr-1" style={{ padding: '0.05rem !important' }} /> */

		switch (position) {
			case PositionsEnum.unity_developer:
				return <FaUnity className="mr-1" style={{ padding: '0.05rem !important' }} />

			case PositionsEnum.artist:
				return <FaPalette className="mr-1" style={{ padding: '0.05rem !important' }} />

			case PositionsEnum.concept_artist:
				return <FaImage className="mr-1" style={{ padding: '0.05rem !important' }} />

			case PositionsEnum.first_game_producer:
			case PositionsEnum.second_game_producer:
			case PositionsEnum.associate_producer:
				return <FaUserTie className="mr-1" style={{ padding: '0.05rem !important' }} />
		}

		return <FaUser className="mr-1" style={{ padding: '0.05rem !important' }} />
	}

	if (!props.employee || typeof props.employee === 'string') return null

	const { _id } = props.employee

	return (
		<Link
			to={{
				pathname: props.linkOff ? '#/' : `/employee/${_id}`,
				state: { id: _id }
			}}
		>
			{props.size !== 'full' && (
				<UncontrolledTooltip
					placement={'right'}
					target={'TooltipComponent' + props.employee._id}
				>
					{`${props.employee.first_name} ${props.employee.last_name}`}
				</UncontrolledTooltip>
			)}

			<Badge
				id={'TooltipComponent' + props.employee._id}
				color={color && !props.colorOff ? color : 'primary'}
				className={`badge-pill mr-1 ${props.className}`}
				style={{ borderRadius: '0.3rem' }}
			>
				{props.showIcon && <UserIcon />}
				{props.showAvailability && (
					<UserAvailabilityStatus employee_id={_id} today={props.today} />
				)}
				{i18n.language === 'en'
					? transliterate(text, {
							replace: [
								['Г', 'H'],
								['г', 'h']
							]
					  })
					: text}
			</Badge>
		</Link>
	)
}

export default AuthorBadge
