import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import { SalariesTable } from 'types/salary'

export const loadSalariesTableRequest = createAction(types.LOAD_SALARIES_TABLE_REQUEST)
export const loadSalariesTableSuccess = createAction<SalariesTable>(
	types.LOAD_SALARIES_TABLE_SUCCESS
)
export const loadSalariesTableError = createAction(types.LOAD_SALARIES_TABLE_ERROR)

export const addEmployeeBonusesRequest = createAction(types.ADD_EMPLOYEE_BONUSES_REQUEST)
export const addEmployeeBonusesSuccess = createAction<SalariesTable>(
	types.ADD_EMPLOYEE_BONUSES_SUCCESS
)
export const addEmployeeBonusesError = createAction(types.ADD_EMPLOYEE_BONUSES_ERROR)

export const addEmployeeFinesRequest = createAction(types.ADD_EMPLOYEE_FINES_REQUEST)
export const addEmployeeFinesSuccess = createAction<SalariesTable>(types.ADD_EMPLOYEE_FINES_SUCCESS)
export const addEmployeeFinesError = createAction(types.ADD_EMPLOYEE_FINES_ERROR)

export const removeEmployeeBonusRequest = createAction(types.REMOVE_EMPLOYEE_BONUS_REQUEST)
export const removeEmployeeBonusSuccess = createAction<SalariesTable>(
	types.REMOVE_EMPLOYEE_BONUS_SUCCESS
)
export const removeEmployeeBonusError = createAction(types.REMOVE_EMPLOYEE_BONUS_ERROR)

export const removeEmployeeFinesRequest = createAction(types.REMOVE_EMPLOYEE_FINE_REQUEST)
export const removeEmployeeFinesSuccess = createAction<SalariesTable>(
	types.REMOVE_EMPLOYEE_FINE_SUCCESS
)
export const removeEmployeeFinesError = createAction(types.REMOVE_EMPLOYEE_FINE_ERROR)

export const updateSalaryVariablesRequest = createAction(types.UPDATE_SALARY_VARIABLES_REQUEST)
export const updateSalaryVariablesSuccess = createAction<SalariesTable>(
	types.UPDATE_SALARY_VARIABLES_SUCCESS
)
export const updateSalaryVariablesError = createAction(types.UPDATE_SALARY_VARIABLES_ERROR)

export const checkEmployeeSalaryRequest = createAction(types.CHECK_EMPLOYEE_SALARY_REQUEST)
export const checkEmployeeSalarySuccess = createAction<SalariesTable>(
	types.CHECK_EMPLOYEE_SALARY_SUCCESS
)
export const checkEmployeeSalaryError = createAction(types.CHECK_EMPLOYEE_SALARY_ERROR)

export const updateSalariesTableStatusRequest = createAction(
	types.UPDATE_SALARIES_TABLE_STATUS_REQUEST
)
export const updateSalariesTableStatusSuccess = createAction<SalariesTable>(
	types.UPDATE_SALARIES_TABLE_STATUS_SUCCESS
)
export const updateSalariesTableStatusError = createAction(types.UPDATE_SALARIES_TABLE_STATUS_ERROR)

export const setSalariesTableDollarRateRequest = createAction(
	types.SET_SALARIES_TABLE_DOLLAR_RATE_REQUEST
)
export const setSalariesTableDollarRateSuccess = createAction<SalariesTable>(
	types.SET_SALARIES_TABLE_DOLLAR_RATE_SUCCESS
)
export const setSalariesTableDollarRateError = createAction(
	types.SET_SALARIES_TABLE_DOLLAR_RATE_ERROR
)

export const withdrawSalariesTableRequest = createAction(types.WITHDRAW_SALARIES_TABLE_REQUEST)
export const withdrawSalariesTableSuccess = createAction<SalariesTable>(
	types.WITHDRAW_SALARIES_TABLE_SUCCESS
)
export const withdrawSalariesTableError = createAction(types.WITHDRAW_SALARIES_TABLE_ERROR)
