import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import WorkCheckin from 'types/workCheckin'

export const loadWorkCheckinsRequest = createAction(types.LOAD_WORK_CHECKINS_REQUEST)
export const loadWorkCheckinsSuccess = createAction<WorkCheckin[]>(types.LOAD_WORK_CHECKINS_SUCCESS)
export const loadWorkCheckinsError = createAction(types.LOAD_WORK_CHECKINS_ERROR)

export const loadMonthCheckinsRequest = createAction(types.LOAD_MONTH_CHECKINS_REQUEST)
export const loadMonthCheckinsSuccess = createAction<WorkCheckin[]>(
	types.LOAD_MONTH_CHECKINS_SUCCESS
)
export const loadMonthCheckinsError = createAction(types.LOAD_MONTH_CHECKINS_ERROR)

export const loadEmployeeWorkCheckinsRequest = createAction(
	types.LOAD_EMPLOYEE_WORK_CHECKINS_REQUEST
)
export const loadEmployeeWorkCheckinsSuccess = createAction<WorkCheckin[]>(
	types.LOAD_EMPLOYEE_WORK_CHECKINS_SUCCESS
)
export const loadEmployeeWorkCheckinsError = createAction(types.LOAD_EMPLOYEE_WORK_CHECKINS_ERROR)

export const updateWorkCheckiRequest = createAction(types.UPDATE_WORK_CHECKIN_REQUEST)
export const updateWorkCheckiSuccess = createAction<WorkCheckin>(types.UPDATE_WORK_CHECKIN_SUCCESS)
export const updateWorkCheckiError = createAction(types.UPDATE_WORK_CHECKIN_ERROR)
