import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import { SystemSettings } from 'types/systemSettings'

export const loadSystemSettingsRequest = createAction(types.LOAD_SYSTEM_SETTINGS_REQUEST)
export const loadSystemSettingsSuccess = createAction<SystemSettings>(
	types.LOAD_SYSTEM_SETTINGS_SUCCESS
)
export const loadSystemSettingsError = createAction(types.LOAD_SYSTEM_SETTINGS_ERROR)

export const updateSystemSettingsRequest = createAction(types.UPDATE_SYSTEM_SETTINGS_REQUEST)
export const updateSystemSettingsSuccess = createAction<SystemSettings>(
	types.UPDATE_SYSTEM_SETTINGS_SUCCESS
)
export const updateSystemSettingsError = createAction(types.UPDATE_SYSTEM_SETTINGS_ERROR)
