import { createAction } from '@reduxjs/toolkit'
import * as types from '../../../constants'
import { Candidate } from 'types/recruiting/candidate'

export const loadCandidatesRequest = createAction(types.LOAD_CANDIDATES_REQUEST)
export const loadCandidatesSuccess = createAction<Candidate[]>(types.LOAD_CANDIDATES_SUCCESS)
export const loadCandidatesError = createAction(types.LOAD_CANDIDATES_ERROR)

export const createCandidateRequest = createAction(types.CREATE_CANDIDATE_REQUEST)
export const createCandidateSuccess = createAction<Candidate>(types.CREATE_CANDIDATE_SUCCESS)
export const createCandidateError = createAction(types.CREATE_CANDIDATE_ERROR)

export const updateCandidateRequest = createAction(types.UPDATE_CANDIDATE_REQUEST)
export const updateCandidateSuccess = createAction<Candidate>(types.UPDATE_CANDIDATE_SUCCESS)
export const updateCandidateError = createAction(types.UPDATE_CANDIDATE_ERROR)

export const takeCandidateOnVacancyRequest = createAction(types.TAKE_CANDIDATE_ON_VACANCY_REQUEST)
export const takeCandidateOnVacancySuccess = createAction<Candidate>(
	types.TAKE_CANDIDATE_ON_VACANCY_SUCCESS
)
export const takeCandidateOnVacancyError = createAction(types.TAKE_CANDIDATE_ON_VACANCY_ERROR)

export const archiveCandidateRequest = createAction(types.ARCHIVE_CANDIDATE_REQUEST)
export const archiveCandidateSuccess = createAction<Candidate>(types.ARCHIVE_CANDIDATE_SUCCESS)
export const archiveCandidateError = createAction(types.ARCHIVE_CANDIDATE_ERROR)
