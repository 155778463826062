import { useEffect, useState } from 'react'
import { RolesEnum } from 'types/roles'
import { connect } from 'react-redux'
import { StoreType } from 'redux/store'
import { UserState } from 'redux/reducers/userReducer'

const RoleWrapper = (props: { user: UserState; children: any; requared_role?: RolesEnum[] }) => {
	const [userRoles, setUserRoles] = useState<RolesEnum[]>([RolesEnum.base])

	useEffect(() => {
		setUserRoles(props.user.employee.user_roles)
	}, [props.user])

	if (!props.user) return null

	if (
		props.requared_role?.some((r: RolesEnum) => userRoles?.includes(r)) ||
		userRoles?.includes(RolesEnum.admin)
	)
		return props.children

	return null
}

export default connect((store: StoreType) => ({
	user: store.user
}))(RoleWrapper)
