import {
	getAllSicknesses,
	createSickness,
	updateSickness,
	deleteSickness,
	getTodaySicknesses,
	getTwoMonthSicknesses,
	getEmployeeSicknesses,
	uploadSicknessFile,
	deleteSicknessFile
} from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import Sickness, {
	SicknessCreationData,
	SicknessFileDeleteDto,
	SicknessUpdateData
} from 'types/sickness'

export const loadSicknesses = () => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.loadSicknessesRequest())
	try {
		const sicknesses = await getAllSicknesses()
		dispatch(actions.dataActions.sicknesses.loadSicknessesSuccess(sicknesses))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		dispatch(actions.dataActions.sicknesses.loadSicknessesError())
	}
}

export const loadTwoMonthSicknesses = () => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.loadSicknessesRequest())
	try {
		const sicknesses = await getTwoMonthSicknesses()
		dispatch(actions.dataActions.sicknesses.loadSicknessesSuccess(sicknesses))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		dispatch(actions.dataActions.sicknesses.loadSicknessesError())
	}
}

export const loadEmployeeSicknessesFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.loadEmployeeSicknessesRequest())
	try {
		const loadedSicknesses: Sickness[] = await getEmployeeSicknesses(id)
		return dispatch(
			actions.dataActions.sicknesses.loadEmployeeSicknessesSuccess(loadedSicknesses)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.sicknesses.loadEmployeeSicknessesError())
	}
}

export const loadTodaySicknesses = () => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.loadTodaySicknessesRequest())
	try {
		const sicknesses = await getTodaySicknesses()
		dispatch(actions.dataActions.sicknesses.loadTodaySicknessesSuccess(sicknesses))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		dispatch(actions.dataActions.sicknesses.loadTodaySicknessesError())
	}
}

export const createNewSickness = (creationData: SicknessCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.createSicknessRequest())
	try {
		const newSickness = await createSickness(creationData)
		toastr.success('', t('reduxToastrSuccess_sicknessCreated'))
		dispatch(actions.dataActions.sicknesses.createSicknessSuccess(newSickness))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		dispatch(actions.dataActions.sicknesses.createSicknessError())
	}
}

export const updateSicknessDate = (updateData: SicknessUpdateData) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.updateSicknessRequest())
	try {
		const updatedSickness = await updateSickness(updateData)
		toastr.success('', t('reduxToastrSuccess_sicknessStopped'))
		dispatch(actions.dataActions.sicknesses.updateSicknessSuccess(updatedSickness))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		dispatch(actions.dataActions.sicknesses.updateSicknessesError())
	}
}

export const deleteSicknesDocument = (id) => async (dispach) => {
	dispach(actions.dataActions.sicknesses.deleteSicknessRequest())
	try {
		const deletedSickness = await deleteSickness(id)
		toastr.success('', t('reduxToastrSuccess_sicknessDeleted'))
		return dispach(actions.dataActions.sicknesses.deleteSicknessSuccess(deletedSickness))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispach(actions.dataActions.sicknesses.deleteSicknessError())
	}
}

//sickness file
export const uploadSicknessFileFunc = (sickness_id: string, file: File) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.uploadSicknessFileRequest())
	try {
		const updatedSickness: Sickness = await uploadSicknessFile(sickness_id, file)
		toastr.success('', t('reduxToastrSuccess_sicknessFileUploaded'))
		return dispatch(actions.dataActions.sicknesses.uploadSicknessFileSuccess(updatedSickness))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.sicknesses.uploadSicknessFileError())
	}
}

export const deleteSicknessFileFunc = (deleteInfo: SicknessFileDeleteDto) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.deleteSicknessFileRequest())
	try {
		const updatedSickness: Sickness = await deleteSicknessFile(deleteInfo)
		toastr.success('', t('reduxToastrSuccess_sicknessFileDeleted'))
		return dispatch(actions.dataActions.sicknesses.deleteSicknessFileSuccess(updatedSickness))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.sicknesses.deleteSicknessFileError())
	}
}
