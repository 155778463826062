import { createAction } from '@reduxjs/toolkit'
import Idea from 'types/idea'

import * as types from '../../constants'

export const loadIdeasRequest = createAction(types.LOAD_IDEAS_REQUEST)
export const loadIdeasSuccess = createAction<Idea[]>(types.LOAD_IDEAS_SUCCESS)
export const loadIdeasError = createAction(types.LOAD_IDEAS_ERROR)

export const loadArchivedIdeasRequest = createAction(types.LOAD_ARCHIVED_IDEAS_REQUEST)
export const loadArchivedIdeasSuccess = createAction<Idea[]>(types.LOAD_ARCHIVED_IDEAS_SUCCESS)
export const loadArchivedIdeasError = createAction(types.LOAD_ARCHIVED_IDEAS_ERROR)

export const loadEmployeeIdeasRequest = createAction(types.LOAD_EMPLOYEE_IDEAS_REQUEST)
export const loadEmployeeIdeasSuccess = createAction<Idea[]>(types.LOAD_EMPLOYEE_IDEAS_SUCCESS)
export const loadEmployeeIdeasError = createAction(types.LOAD_EMPLOYEE_IDEAS_ERROR)

export const createIdeaRequest = createAction(types.CREATE_IDEA_REQUEST)
export const createIdeaSuccess = createAction<Idea>(types.CREATE_IDEA_SUCCESS)
export const createIdeaError = createAction(types.CREATE_IDEA_ERROR)

export const useIdeaRequest = createAction(types.USE_IDEA_REQUEST)
export const useIdeaSuccess = createAction<Idea>(types.USE_IDEA_SUCCESS)
export const useIdeaError = createAction(types.USE_IDEA_ERROR)

export const archiveIdeaRequest = createAction(types.ARCHIVE_IDEA_REQUEST)
export const archiveIdeaSuccess = createAction<Idea>(types.ARCHIVE_IDEA_SUCCESS)
export const archiveIdeaError = createAction(types.ARCHIVE_IDEA_ERROR)

export const unarchiveIdeaRequest = createAction(types.UNARCHIVE_IDEA_REQUEST)
export const unarchiveIdeaSuccess = createAction<Idea>(types.UNARCHIVE_IDEA_SUCCESS)
export const unarchiveIdeaError = createAction(types.UNARCHIVE_IDEA_ERROR)

export const deleteIdeaRequest = createAction(types.DELETE_IDEA_REQUEST)
export const deleteIdeaSuccess = createAction<Idea>(types.DELETE_IDEA_SUCCESS)
export const deleteIdeaError = createAction(types.DELETE_IDEA_ERROR)
