import { createAction } from '@reduxjs/toolkit'
import Invoice from 'types/invoice'
import PublishersInvoicePayTime from 'types/publishersInvoicePayTime'
import * as types from '../../constants'

export const storePublishersInvoicePayTime = createAction<PublishersInvoicePayTime[]>(
	types.STORE_PUBLISHERS_INVOICE_PAY_TIME
)

export const loadInvoicesRequest = createAction(types.LOAD_INVOICES_REQUEST)
export const loadInvoicesSuccess = createAction<Invoice[]>(types.LOAD_INVOICES_SUCCESS)
export const loadInvoicesError = createAction(types.LOAD_INVOICES_ERROR)

export const addProjectsToInvoiceRequest = createAction(types.ADD_PROJECTS_TO_INVOICE_REQUEST)
export const addProjectsToInvoiceSuccess = createAction<Invoice>(
	types.ADD_PROJECTS_TO_INVOICE_SUCCESS
)
export const addProjectsToInvoiceError = createAction(types.ADD_PROJECTS_TO_INVOICE_ERROR)

export const createInvoiceRequest = createAction(types.CREATE_INVOICE_REQUEST)
export const createInvoiceSuccess = createAction<Invoice>(types.CREATE_INVOICE_SUCCESS)
export const createInvoiceError = createAction(types.CREATE_INVOICE_ERROR)

export const formatInvoiceRequest = createAction(types.FORMAT_INVOICE_REQUEST)
export const formatInvoiceSuccess = createAction<Invoice>(types.FORMAT_INVOICE_SUCCESS)
export const formatInvoiceError = createAction(types.FORMAT_INVOICE_ERROR)

export const payInvoiceRequest = createAction(types.PAY_INVOICE_REQUEST)
export const payInvoiceSuccess = createAction<Invoice>(types.PAY_INVOICE_SUCCESS)
export const payInvoiceError = createAction(types.PAY_INVOICE_ERROR)

export const sendInvoiceRequest = createAction(types.SEND_INVOICE_REQUEST)
export const sendInvoiceSuccess = createAction<Invoice>(types.SEND_INVOICE_SUCCESS)
export const sendInvoiceError = createAction(types.SEND_INVOICE_ERROR)

export const deleteInvoiceRequest = createAction(types.DELETE_INVOICE_REQUEST)
export const deleteInvoiceSuccess = createAction<string>(types.DELETE_INVOICE_SUCCESS)
export const deleteInvoiceError = createAction(types.DELETE_INVOICE_ERROR)

//invoice file
export const uploadInvoiceFileRequest = createAction(types.UPLOAD_INVOICE_FILE_REQUEST)
export const uploadInvoiceFileSuccess = createAction<Invoice>(types.UPLOAD_INVOICE_FILE_SUCCESS)
export const uploadInvoiceFileError = createAction(types.UPLOAD_INVOICE_FILE_ERROR)

export const downloadInvoiceFileRequest = createAction(types.DOWNLOAD_INVOICE_FILE_REQUEST)
export const downloadInvoiceFileSuccess = createAction(types.DOWNLOAD_INVOICE_FILE_SUCCESS)
export const downloadInvoiceFileError = createAction(types.DOWNLOAD_INVOICE_FILE_ERROR)

export const deleteInvoiceFileRequest = createAction(types.DELETE_INVOICE_FILE_REQUEST)
export const deleteInvoiceFileSuccess = createAction<Invoice>(types.DELETE_INVOICE_FILE_SUCCESS)
export const deleteInvoiceFileError = createAction(types.DELETE_INVOICE_FILE_ERROR)
