import {
	createVacation,
	deleteVacation,
	createVacationPeriod,
	deleteVacationPeriod,
	getAllVacations,
	getAllVacationsPeriods,
	updateVacation,
	rejectVacation,
	getTodayVacations,
	getEmployeeVacations,
	getTwoMonthVacations
} from 'utils/api.request'

import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import Vacation, {
	VacationCreationData,
	VacationLocedPeriodCreationData,
	VacationRejectingData,
	VacationStatusEnum,
	VacationUpdatingData
} from 'types/vacation'
import VacationLockedPeriods from 'types/vacation.locked.periods'

export const loadVacationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadVacationsRequest())
	try {
		const loadedVacations: Vacation[] = await getAllVacations()
		return dispatch(actions.dataActions.vacations.loadVacationsSuccess(loadedVacations))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadVacationsError())
	}
}

export const loadEmployeeVacationsFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadEmployeeVacationsRequest())
	try {
		const loadedVacations: Vacation[] = await getEmployeeVacations(id)
		return dispatch(actions.dataActions.vacations.loadEmployeeVacationsSuccess(loadedVacations))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadEmployeeVacationsError())
	}
}

export const loadTwoMonthVacationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadVacationsRequest())
	try {
		const loadedVacations: Vacation[] = await getTwoMonthVacations()
		return dispatch(actions.dataActions.vacations.loadVacationsSuccess(loadedVacations))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadVacationsError())
	}
}

export const loadTodayVacationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadTodayVacationsRequest())
	try {
		const loadedVacations: Vacation[] = await getTodayVacations()
		return dispatch(actions.dataActions.vacations.loadTodayVacationsSuccess(loadedVacations))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadTodayVacationsError())
	}
}

export const createVacationFunc = (creationData: VacationCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.createVacationRequest())
	try {
		const createdVacation: Vacation = await createVacation(creationData)

		if (createdVacation.status === VacationStatusEnum.requested) {
			toastr.success('', t('reduxToastrSuccess_vacationRequestCreated'))
		} else {
			toastr.success('', t('reduxToastrSuccess_vacationCreated'))
		}

		return dispatch(actions.dataActions.vacations.createVacationSuccess(createdVacation))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.createVacationError())
	}
}

export const updateVacationFunc = (updatingData: VacationUpdatingData) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.updateVacationRequest())
	try {
		const updatedVacation: Vacation = await updateVacation(updatingData)

		if (updatedVacation.status === VacationStatusEnum.agreed) {
			toastr.success('', t('reduxToastrSuccess_vacationAgreed'))
		} else if (updatedVacation.status === VacationStatusEnum.rejected) {
			toastr.success('', t('reduxToastrSuccess_vacationRejected'))
		} else {
			toastr.success('', t('reduxToastrSuccess_vacationRequestCreated'))
		}

		return dispatch(actions.dataActions.vacations.updateVacationSuccess(updatedVacation))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.updateVacationError())
	}
}

export const rejectVacationFunc = (rejectingData: VacationRejectingData) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.updateVacationRequest())
	try {
		const updatedVacation: Vacation = await rejectVacation(rejectingData)

		toastr.success('', t('reduxToastrSuccess_vacationRequestRejected'))

		return dispatch(actions.dataActions.vacations.updateVacationSuccess(updatedVacation))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.updateVacationError())
	}
}

export const deleteVacationFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.deleteVacationRequest())
	try {
		const deletedVacation: Vacation = await deleteVacation(id)
		toastr.success('', t('reduxToastrSuccess_vacationDeleted'))
		return dispatch(actions.dataActions.vacations.deleteVacationSuccess(deletedVacation))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.deleteVacationError())
	}
}

export const loadVacationPeriodsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadVacationPeriodsRequest())
	try {
		const loadedVacationPeriod: VacationLockedPeriods[] = await getAllVacationsPeriods()
		return dispatch(
			actions.dataActions.vacations.loadVacationPeriodsSuccess(loadedVacationPeriod)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadVacationPeriodsError())
	}
}

export const createVacationPeriodFunc =
	(creationData: VacationLocedPeriodCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.vacations.createVacationPeriodRequest())
		try {
			const createdVacationPeriod: VacationLockedPeriods = await createVacationPeriod(
				creationData
			)

			toastr.success('', t('reduxToastrSuccess_vacationLockPeriodCreated'))
			return dispatch(
				actions.dataActions.vacations.createVacationPeriodSuccess(createdVacationPeriod)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.vacations.createVacationPeriodError())
		}
	}

export const deleteVacationPeriodFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.deleteVacationPeriodRequest())
	try {
		const deletedVacationPeriod: VacationLockedPeriods = await deleteVacationPeriod(id)
		toastr.success('', t('reduxToastrSuccess_vacationLockPeriodDeleted'))
		return dispatch(
			actions.dataActions.vacations.deleteVacationPeriodSuccess(deletedVacationPeriod)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.vacations.deleteVacationPeriodError())
	}
}
