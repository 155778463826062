import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Suspense } from 'react'
import { Provider } from 'react-redux'
import store from './redux/store/index'
import Loader from 'components/Loader'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './i18n'

ReactDOM.render(
	<React.Fragment>
		<Provider store={store}>
			<Suspense fallback={<Loader />}>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</Suspense>
		</Provider>
	</React.Fragment>,
	document.getElementById('root')
)
