import { toastr } from 'react-redux-toastr'
import actions from 'redux/actions'
import { t } from 'i18next'
import { archiveApplication, createApplication, loadApplications } from 'utils/api.request'
import { Application, ApplicationCreationData } from 'types/recruiting/application'

export const loadApplicationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.applications.loadApplicationsRequest())
	try {
		const allApplications: Application[] = await loadApplications()

		return dispatch(actions.dataActions.applications.loadApplicationsSuccess(allApplications))
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.applications.loadApplicationsError())
	}
}

export const createApplicationFunc =
	(creationData: ApplicationCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.applications.createApplicationRequest())
		try {
			const newApplication: Application = await createApplication(creationData)

			toastr.success('', t('reduxToastrSuccess_createApplication'))
			return dispatch(
				actions.dataActions.applications.createApplicationSuccess(newApplication)
			)
		} catch (error: any) {
			toastr.error('Error', error.response.data.message)
			return dispatch(actions.dataActions.applications.createApplicationError())
		}
	}

export const archiveApplicationFunc = (application_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.applications.archiveApplicationRequest())
	try {
		const deletedApplication: Application = await archiveApplication(application_id)

		toastr.success('', t('reduxToastrSuccess_archiveApplication'))
		return dispatch(
			actions.dataActions.applications.archiveApplicationSuccess(deletedApplication)
		)
	} catch (error: any) {
		toastr.error('Error', error.response.data.message)
		return dispatch(actions.dataActions.applications.archiveApplicationError())
	}
}
